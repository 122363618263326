import {DOUBLE_V3_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {IDoubleV3Bet, IDoubleV3Result} from '../types';

export async function Bet(bet: IDoubleV3Bet) {
    let result: IDoubleV3Result = {
        amount: 0,
        colorResult: "",
        isBet: false,
        rolling: 0,
        rolled: 0,
        isValid: true
    };
    await api
        .post(
            DOUBLE_V3_CONFIG.bet.path + DOUBLE_V3_CONFIG.bet.endpoint,
            bet
        )
        .then((response) => {
            result = response.data;
        })
        .catch()
    return result
}
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../../hooks/useSessionInfo';
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {ISessionInfos} from '../../../types';
import {
    Action,
    BetButton, Blurn,
    BoxValue, ButtonMusic,
    Container,
    ErrorMensageData, ErrorMessageData,
    ExitButton,
    ExitImg,
    Machine,
    MachineActions,
    MachineActionsBet,
    MachineActionsInformations,
    MachineBetContent,
    MachineBetOptions,
    MachineCell,
    MachineCellAux,
    MachineColumn,
    MachineContent,
    MachineNumber,
    MachineOption,
    MachineOptionContent,
    MachineResults,
    MachineResultsAux,
    MachineResultsCenter,
    PlayImg,
    ResultImg,
    Select,
    SelectOption,
    SelectValue, SoundImage,
    Text
} from './styles';
import Lion from '../../../assets/games/slotMachineV2/lion.png'
import Alligator from '../../../assets/games/slotMachineV2/alligator.png'
import Monkey from '../../../assets/games/slotMachineV2/monkey.png'
import Panda from '../../../assets/games/slotMachineV2/panda.png'
import Tiger from '../../../assets/games/slotMachineV2/tiger.png'
import Wolf from '../../../assets/games/slotMachineV2/wolf.png'
import Fox from '../../../assets/games/slotMachineV2/fox.png'
import Play from '../../../assets/games/slotMachineV2/play.png'
import Exit from '../../../assets/games/slotMachineV2/exit.png'

import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import {useTranslation} from "react-i18next";
import {GetCasinoAccess} from "../../../hooks/useAuth";
import {URLs} from "../../../services/service.config";

const SlotMachineV2Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [betValue, setBetValue] = useState(0);
    const [betMultiplicator, setBetMultiplicator] = useState(10);
    const [bet, setBet] = useState(0.2);
    const [canBet, setCanBet] = useState(true);
    const [showBetConteiner, setShowBetConteiner] = useState(true);
    const [winValue, setWinValue] = useState(0);
    const [cell0, setCell0] = useState("");
    const [cell1, setCell1] = useState("");
    const [cell2, setCell2] = useState("");
    const [cell3, setCell3] = useState("");
    const [cell4, setCell4] = useState("");
    const [cell5, setCell5] = useState("");
    const [cell6, setCell6] = useState("");
    const [cell7, setCell7] = useState("");
    const [cell8, setCell8] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const [casinoAccess, setCasinoAccess] = useState(false);
    let audioElement: HTMLAudioElement | null = null;

    useEffect(() => {
        FindCasinoAccess();
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Slot Machine Zoo", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsSlotMachineV2)));
        FindSessionInfos();
    }

    async function FindSessionInfos() {
        await setSessionInfos(await GetSessionInfos(session))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    useEffect(() => {
        setBetValue(bet * betMultiplicator * 5);
    }, [bet, betMultiplicator])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = async () => {
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/slotMachineV2SessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/blockSlotMachineV2Bet', onBlockBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-slot-machine-v2', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/flipSlotMachineV2Cell', onFlipSlotMachineCellMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/slotMachineV2WinValue', onSlotMachineWinValueMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidSlotMachineV2', onIsValidMessage);
        setCanBet(false);
    }

    const onError = (err: any) => {
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onBlockBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanBet(payloadData);
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onFlipSlotMachineCellMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        const id = payloadData.cellId;
        const result = payloadData.result;
        if (id == 0) {
            setCell0(result);
        }
        if (id == 1) {
            setCell1(result);
        }
        if (id == 2) {
            setCell2(result);
        }
        if (id == 3) {
            setCell3(result);
        }
        if (id == 4) {
            setCell4(result);
        }
        if (id == 5) {
            setCell5(result);
        }
        if (id == 6) {
            setCell6(result);
        }
        if (id == 7) {
            setCell7(result);
        }
        if (id == 8) {
            setCell8(result);
        }
    }

    const onSlotMachineWinValueMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setWinValue(payloadData);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({...sessionInfos, "isValid": payloadData});
    }

    const handleBet = (event: any) => {
        const bet = event.target.value;
        setBet(bet);
    }

    const handleBetMultiplicator = (event: any) => {
        const multiplicator = event.target.value;
        setBetMultiplicator(multiplicator);
    }

    const handleShowBetConteiner = (event: any) => {
        setShowBetConteiner(!showBetConteiner);
    }

    async function sendBet() {
        setPrivateChat("");
        var betDto = {
            amount: sessionInfos.amount,
            isBet: false,
            user: casino,
            sessionId: session,
            playerId: sessionInfos.playerId!,
            bet: bet,
            betMultiplicator: betMultiplicator,
            lines: 5,
            amountBet: betValue,
        };
        stompClient.send("/app/betSlotMachineV2", {}, JSON.stringify(betDto));
        setWinValue(0);
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Container>
            ) : (
                <Container>
                    <Blurn style={{display: showBetConteiner ? 'none' : 'flex'}}/>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <Machine>
                        <MachineContent>
                            <MachineResults>
                                <MachineResultsAux>
                                    <MachineCellAux>
                                        <MachineNumber>4</MachineNumber>
                                        <MachineNumber>2</MachineNumber>
                                    </MachineCellAux>
                                    <MachineCellAux>
                                        <MachineNumber>1</MachineNumber>
                                    </MachineCellAux>
                                    <MachineCellAux>
                                        <MachineNumber>3</MachineNumber>
                                        <MachineNumber>5</MachineNumber>
                                    </MachineCellAux>
                                </MachineResultsAux>
                                <MachineResultsCenter>
                                    <MachineColumn>
                                        <MachineCell id='0'>
                                            <ResultImg alt={cell0} src={cell0 == "FOX" ? Fox :
                                                cell0 == "WOLF" ? Wolf :
                                                    cell0 == "PANDA" ? Panda :
                                                        cell0 == "LION" ? Lion :
                                                            cell0 == "MONKEY" ? Monkey :
                                                                cell0 == "ALLIGATOR" ? Alligator :
                                                                    cell0 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='1'>
                                            <ResultImg alt={cell1} src={cell1 == "FOX" ? Fox :
                                                cell1 == "WOLF" ? Wolf :
                                                    cell1 == "PANDA" ? Panda :
                                                        cell1 == "LION" ? Lion :
                                                            cell1 == "MONKEY" ? Monkey :
                                                                cell1 == "ALLIGATOR" ? Alligator :
                                                                    cell1 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='2'>
                                            <ResultImg alt={cell2} src={cell2 == "FOX" ? Fox :
                                                cell2 == "WOLF" ? Wolf :
                                                    cell2 == "PANDA" ? Panda :
                                                        cell2 == "LION" ? Lion :
                                                            cell2 == "MONKEY" ? Monkey :
                                                                cell2 == "ALLIGATOR" ? Alligator :
                                                                    cell2 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                    </MachineColumn>
                                    <MachineColumn>
                                        <MachineCell id='3'>
                                            <ResultImg alt={cell3} src={cell3 == "FOX" ? Fox :
                                                cell3 == "WOLF" ? Wolf :
                                                    cell3 == "PANDA" ? Panda :
                                                        cell3 == "LION" ? Lion :
                                                            cell3 == "MONKEY" ? Monkey :
                                                                cell3 == "ALLIGATOR" ? Alligator :
                                                                    cell3 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='4'>
                                            <ResultImg alt={cell4} src={cell4 == "FOX" ? Fox :
                                                cell4 == "WOLF" ? Wolf :
                                                    cell4 == "PANDA" ? Panda :
                                                        cell4 == "LION" ? Lion :
                                                            cell4 == "MONKEY" ? Monkey :
                                                                cell4 == "ALLIGATOR" ? Alligator :
                                                                    cell4 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='5'>
                                            <ResultImg alt={cell5} src={cell5 == "FOX" ? Fox :
                                                cell5 == "WOLF" ? Wolf :
                                                    cell5 == "PANDA" ? Panda :
                                                        cell5 == "LION" ? Lion :
                                                            cell5 == "MONKEY" ? Monkey :
                                                                cell5 == "ALLIGATOR" ? Alligator :
                                                                    cell5 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                    </MachineColumn>
                                    <MachineColumn>
                                        <MachineCell id='6'>
                                            <ResultImg alt={cell6} src={cell6 == "FOX" ? Fox :
                                                cell6 == "WOLF" ? Wolf :
                                                    cell6 == "PANDA" ? Panda :
                                                        cell6 == "LION" ? Lion :
                                                            cell6 == "MONKEY" ? Monkey :
                                                                cell6 == "ALLIGATOR" ? Alligator :
                                                                    cell6 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='7'>
                                            <ResultImg alt={cell7} src={cell7 == "FOX" ? Fox :
                                                cell7 == "WOLF" ? Wolf :
                                                    cell7 == "PANDA" ? Panda :
                                                        cell7 == "LION" ? Lion :
                                                            cell7 == "MONKEY" ? Monkey :
                                                                cell7 == "ALLIGATOR" ? Alligator :
                                                                    cell7 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                        <MachineCell id='8'>
                                            <ResultImg alt={cell8} src={cell8 == "FOX" ? Fox :
                                                cell8 == "WOLF" ? Wolf :
                                                    cell8 == "PANDA" ? Panda :
                                                        cell8 == "LION" ? Lion :
                                                            cell8 == "MONKEY" ? Monkey :
                                                                cell8 == "ALLIGATOR" ? Alligator :
                                                                    cell8 == "TIGER" ? Tiger : ""}/>
                                        </MachineCell>
                                    </MachineColumn>
                                </MachineResultsCenter>
                                <MachineResultsAux>
                                    <MachineCellAux>
                                        <MachineNumber>5</MachineNumber>
                                        <MachineNumber>2</MachineNumber>
                                    </MachineCellAux>
                                    <MachineCellAux>
                                        <MachineNumber>1</MachineNumber>
                                    </MachineCellAux>
                                    <MachineCellAux>
                                        <MachineNumber>3</MachineNumber>
                                        <MachineNumber>4</MachineNumber>
                                    </MachineCellAux>
                                </MachineResultsAux>
                            </MachineResults>
                            <MachineActions>
                                <MachineActionsInformations>
                                    <Action>
                                        <Text>
                                            {t("amount")}
                                        </Text>
                                        <BoxValue>{sessionInfos.amount.toFixed(2)}</BoxValue>
                                    </Action>
                                    <Action>
                                        <Text>
                                            {t("betAmount")}
                                        </Text>
                                        <BoxValue onClick={handleShowBetConteiner}>{betValue}</BoxValue>
                                        <MachineBetOptions style={{display: showBetConteiner ? 'none' : 'flex'}}>
                                            <ExitButton onClick={handleShowBetConteiner}>
                                                <Text>
                                                    {t("betOptions")}
                                                </Text>
                                                <ExitImg alt='Exit' src={Exit}></ExitImg>
                                            </ExitButton>
                                            <MachineBetContent>
                                                <MachineOption>
                                                    <Text>
                                                        {t("betOptions")}
                                                    </Text>
                                                    <MachineOptionContent>
                                                        <Select value={bet} onChange={handleBet}>
                                                            <SelectOption value="0.2">
                                                                $0.20
                                                            </SelectOption>
                                                            <SelectOption value="1.00">
                                                                $1.00
                                                            </SelectOption>
                                                            <SelectOption value="3.00">
                                                                $3.00
                                                            </SelectOption>
                                                            <SelectOption value="10.00">
                                                                $10.00
                                                            </SelectOption>
                                                            <SelectOption value="15.00">
                                                                $15.00
                                                            </SelectOption>
                                                            <SelectOption value="20.00">
                                                                $20.00
                                                            </SelectOption>
                                                            <SelectOption value="25.00">
                                                                $25.00
                                                            </SelectOption>
                                                            <SelectOption value="30.00">
                                                                $30.00
                                                            </SelectOption>
                                                        </Select>
                                                    </MachineOptionContent>
                                                </MachineOption>
                                                <MachineOption>
                                                    <Text>
                                                        {t("multiplier")}
                                                    </Text>
                                                    <MachineOptionContent>
                                                        <Select value={betMultiplicator}
                                                                onChange={handleBetMultiplicator}>
                                                            <SelectOption value="1">
                                                                1
                                                            </SelectOption>
                                                            <SelectOption value="2">
                                                                2
                                                            </SelectOption>
                                                            <SelectOption value="3">
                                                                3
                                                            </SelectOption>
                                                            <SelectOption value="4">
                                                                4
                                                            </SelectOption>
                                                            <SelectOption value="5">
                                                                5
                                                            </SelectOption>
                                                            <SelectOption value="6">
                                                                6
                                                            </SelectOption>
                                                            <SelectOption value="7">
                                                                7
                                                            </SelectOption>
                                                            <SelectOption value="8">
                                                                8
                                                            </SelectOption>
                                                            <SelectOption value="9">
                                                                9
                                                            </SelectOption>
                                                            <SelectOption value="10">
                                                                10
                                                            </SelectOption>
                                                        </Select>
                                                    </MachineOptionContent>
                                                </MachineOption>
                                                <MachineOption>
                                                    <Text>
                                                        {t("lines")}
                                                    </Text>
                                                    <SelectValue>
                                                        5
                                                    </SelectValue>
                                                </MachineOption>
                                                <MachineOption>
                                                    <Text>
                                                        {t("betAmount")}
                                                    </Text>
                                                    <SelectValue>
                                                        {betValue.toFixed(2)}
                                                    </SelectValue>
                                                </MachineOption>
                                            </MachineBetContent>
                                        </MachineBetOptions>
                                    </Action>
                                    <Action>
                                        <Text>
                                            {t("win")}
                                        </Text>
                                        <BoxValue>{winValue}</BoxValue>
                                    </Action>
                                </MachineActionsInformations>
                                <MachineActionsBet>
                                    <BetButton disabled={canBet} onClick={sendBet}>
                                        <PlayImg alt='Play' src={Play}></PlayImg>
                                    </BetButton>
                                    <ErrorMensageData hidden={privateChat == ""}>{t(privateChat)}</ErrorMensageData>
                                </MachineActionsBet>
                            </MachineActions>
                        </MachineContent>
                    </Machine>
                </Container>
            )}
        </>
    )
}

export default SlotMachineV2Room
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../../hooks/useSessionInfo';
import {IDoubleV3Bet, IDoubleV3Result, ISessionInfos} from '../../../types';

import {
    BetBox,
    ResultBox,
    Container,
    BetMesage,
    CollorButton,
    BetColorsContent,
    BetValueContent,
    BetValueInputContent,
    ErrorMensageData,
    BetValueInput,
    BetValueChangeButton,
    BetButton,
    InfoMessage,
    LastResultsContent,
    LastResult,
    LastResultData,
    ResultsRoulette,
    Roulette,
    RouletteCard,
    RouletteNumbers,
    RouletteResult,
    LastResultsComponent,
    ButtonCardCircle,
    Selector,
    BetMesageDiv,
    SelectColorsContent,
    ButtonMusic,
    SoundImage, ErrorMessageData
} from './styles';
import {Bet} from '../../../hooks/useDoubleV3';
import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {GetCasinoAccess} from "../../../hooks/useAuth";

const DoubleV3Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [blockBet, setBlockBet] = useState(false);
    const [rolling, setRolling] = useState(0);
    const [focusing, setFocusing] = useState(true);
    const [lastResults, setLastResults] = useState<String[]>([]);
    const [lastResult, setLastResult] = useState<String>("");
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [userData, setUserData] = useState({
        result: '',
        betValue: 0,
        colorSelected: ''
    });
    const sleep = (ms: any) => new Promise(r => setTimeout(r, ms));
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess()
    }, []);

    useEffect(() => {
        casinoAccess && FindSessionInfos();
    }, [casinoAccess]);

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Color Roulette", session!));
    }

    async function FindSessionInfos() {
        setSessionInfos(await GetSessionInfos(session))
    }

    const handleBetValue = (event: { target: { value: any; }; }) => {
        const {value} = event.target;
        setUserData({...userData, "betValue": value});
    }

    const rouletteNumbers = [
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
        {color: 'ORANGE'},
        {color: 'YELLOW'},
        {color: 'GREEN'},
        {color: 'BLUE'},
        {color: 'INDIGO'},
        {color: 'VIOLET'},
        {color: 'RED'},
    ]

    function selectRed() {
        setUserData({...userData, "colorSelected": "RED"});
    }

    function selectOrange() {
        setUserData({...userData, "colorSelected": "ORANGE"});
    }

    function selectYellow() {
        setUserData({...userData, "colorSelected": "YELLOW"});
    }

    function selectGreen() {
        setUserData({...userData, "colorSelected": "GREEN"});
    }

    function selectBlue() {
        setUserData({...userData, "colorSelected": "BLUE"});
    }

    function selectIndigo() {
        setUserData({...userData, "colorSelected": "INDIGO"});
    }

    function selectViolet() {
        setUserData({...userData, "colorSelected": "VIOLET"});
    }

    function divideBetValue() {
        setUserData({...userData, "betValue": userData.betValue / 2});
    }

    function multiplyBetValue() {
        setUserData({...userData, "betValue": userData.betValue * 2});
    }

    async function sendBet() {
        setBlockBet(true);
        var doubleV3Bet: IDoubleV3Bet = {
            amount: sessionInfos.amount,
            amountBet: userData.betValue,
            color: userData.colorSelected,
            sessionId: session,
            playerId: sessionInfos.playerId!,
            ln: i18n.language
        };
        const response: IDoubleV3Result = await Bet(doubleV3Bet);
        setSessionInfos({...sessionInfos, "isValid": response.isValid, "amount": sessionInfos.amount - userData.betValue});
        if (response.isBet) {
            setErrorMessage("");
            setRolling(response.rolling);
            setFocusing(false);
            await sleep(5000);
            setRolling(response.rolled);
            await sleep(3000);
            setLastResult(response.colorResult);
            lastResults.push(response.colorResult);
            setLastResults([...lastResults]);
            setSessionInfos({...sessionInfos, "amount": response.amount, "isValid": response.isValid});
            setFocusing(true);
            setBlockBet(false);
        } else {
            setSessionInfos({...sessionInfos, "amount": sessionInfos.amount + userData.betValue});
            setErrorMessage(t("betError"));
            setBlockBet(false);
        }
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Container>
            ) : (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ResultBox>
                        <ResultsRoulette>
                            <BetMesage>{t("colorRoulette")}</BetMesage>
                            <Roulette>
                                <RouletteNumbers style={{
                                    transform: focusing === false ? 'translateX(-'.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(-0rem)',
                                    transitionDuration: focusing === false ? '4000ms' : '1000ms',
                                    transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                }}>
                                    <Selector style={{
                                        transform: focusing === false ? 'translateX('.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(0rem)',
                                        transitionDuration: focusing === false ? '4000ms' : '1000ms',
                                        transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                    }}></Selector>
                                    {rouletteNumbers.map((r, index) => (
                                        <RouletteCard key={index}>
                                            <RouletteResult style={{
                                                background: r.color == "RED" ? '#FF0000' :
                                                    r.color == "ORANGE" ? '#FFA500' :
                                                        r.color == "YELLOW" ? '#FFFF00' :
                                                            r.color == "GREEN" ? '#008000' :
                                                                r.color == "BLUE" ? '#0000FF' :
                                                                    r.color == "INDIGO" ? '#4B0082' : '#EE82EE',
                                            }}>
                                            </RouletteResult>
                                        </RouletteCard>
                                    ))}
                                </RouletteNumbers>
                            </Roulette>
                        </ResultsRoulette>
                        <LastResultsComponent>
                            <LastResultsContent>
                                {lastResults.map((r, index) => (
                                    <LastResult key={index}>
                                        <LastResultData style={{
                                            background: r == "RED" ? '#FF0000' :
                                                r == "ORANGE" ? '#FFA500' :
                                                    r == "YELLOW" ? '#FFFF00' :
                                                        r == "GREEN" ? '#008000' :
                                                            r == "BLUE" ? '#0000FF' :
                                                                r == "INDIGO" ? '#4B0082' : '#EE82EE',
                                        }}>
                                        </LastResultData>
                                    </LastResult>
                                ))}
                            </LastResultsContent>
                        </LastResultsComponent>
                    </ResultBox>
                    <BetBox>
                        <SelectColorsContent>
                            <BetMesageDiv>
                                <BetMesage>{t("selectColor")}</BetMesage>
                            </BetMesageDiv>
                            <BetColorsContent>
                                <CollorButton onClick={selectRed} style={{
                                    backgroundColor: "#FF0000",
                                    border: userData.colorSelected === "RED" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectOrange} style={{
                                    backgroundColor: "#FFA500",
                                    border: userData.colorSelected === "ORANGE" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectYellow} style={{
                                    backgroundColor: "#FFFF00",
                                    border: userData.colorSelected === "YELLOW" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectGreen} style={{
                                    backgroundColor: "#008000",
                                    border: userData.colorSelected === "GREEN" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectBlue} style={{
                                    backgroundColor: "#0000FF",
                                    border: userData.colorSelected === "BLUE" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectIndigo} style={{
                                    backgroundColor: "#4B0082",
                                    border: userData.colorSelected === "INDIGO" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                                <CollorButton onClick={selectViolet} style={{
                                    backgroundColor: "#EE82EE",
                                    border: userData.colorSelected === "VIOLET" ? '2px solid #fff' : ''
                                }}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </CollorButton>
                            </BetColorsContent>
                        </SelectColorsContent>

                        <BetValueContent>
                            <BetValueInputContent>
                                <BetValueInput type={"number"} placeholder={t("betAmount")} value={userData.betValue}
                                               onChange={handleBetValue}/>
                                <BetValueChangeButton onClick={divideBetValue}>1/2</BetValueChangeButton>
                                <BetValueChangeButton onClick={multiplyBetValue}>X2</BetValueChangeButton>
                                <BetButton onClick={sendBet} disabled={blockBet}>{t("bet")}</BetButton>
                            </BetValueInputContent>
                        </BetValueContent>
                        <InfoMessage>
                            {t("wallet")} {sessionInfos.amount.toFixed(2)}
                        </InfoMessage>
                        <ErrorMensageData
                            style={{display: errorMessage ? "flex" : "none"}}>{errorMessage}</ErrorMensageData>

                    </BetBox>
                </Container>
            )}
        </>
    )
}

export default DoubleV3Room

import {DOUBLE_V2_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {IDoubleV2Bets, IDoubleV2Result, IDoubleV2Results} from '../types';

export async function GetLastResult(session: string) {
    let lastResult: IDoubleV2Result = {
        result: 0,
        color: "",
        isValid: false
    };
    await api
        .get(
            DOUBLE_V2_CONFIG.getLastResult.path + DOUBLE_V2_CONFIG.getLastResult.endpoint + "?session=" + session
        )
        .then((response) => {
            lastResult = response.data;
        })
        .catch()
    return lastResult
}

export async function GetLastResults(session: string) {
    let lastResults: IDoubleV2Results = {
        results: [],
        isValid: false
    };
    await api
        .get(
            DOUBLE_V2_CONFIG.getLastResults.path + DOUBLE_V2_CONFIG.getLastResults.endpoint + "?session=" + session
        )
        .then((response) => {
            lastResults = response.data;
        })
        .catch()
    return lastResults
}

export async function GetRoundSilverBets(session: string) {
    let roundGreenBets: IDoubleV2Bets = {
        amount: 0,
        roundDoubleV2Bets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_V2_CONFIG.getRoundSilverBets.path + DOUBLE_V2_CONFIG.getRoundSilverBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleV2Bets; }) => {
            roundGreenBets = response.data;
        })
        .catch()
    return roundGreenBets
}

export async function GetRoundGoldBets(session: string) {
    let roundPurpleBets: IDoubleV2Bets = {
        amount: 0,
        roundDoubleV2Bets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_V2_CONFIG.getRoundGoldBets.path + DOUBLE_V2_CONFIG.getRoundGoldBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleV2Bets; }) => {
            roundPurpleBets = response.data;
        })
        .catch()
    return roundPurpleBets
}

export async function GetRoundDiamondBets(session: string) {
    let roundJokerBets: IDoubleV2Bets = {
        amount: 0,
        roundDoubleV2Bets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_V2_CONFIG.getRoundDiamondBets.path + DOUBLE_V2_CONFIG.getRoundDiamondBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleV2Bets; }) => {
            roundJokerBets = response.data;
        })
        .catch()
    return roundJokerBets
}

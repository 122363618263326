import {SESSION_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {ISessionInfos} from '../types';

export async function GetSessionInfos(session: string | undefined) {
    let sessionInfos: ISessionInfos = {
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    };
    await api
        .get(
            SESSION_CONFIG.getSessionInfos.path + SESSION_CONFIG.getSessionInfos.endpoint + "?session=" + session
        )
        .then((response: { data: ISessionInfos; }) => {
            sessionInfos = response.data;
        })
        .catch()
    return sessionInfos
}
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.MINING_ROULETTE.background};
    height: 100vh;
    gap: 1rem;

    @media (max-width: 1250px) {
        padding: 0.2rem;
    }

    @media (max-width: 800px) {
        height: auto;
    }
`

export const ContainerInvalid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.MINING_ROULETTE.background};
    height: 100vh;
    gap: 1rem;

    @media (max-width: 1250px) {
        padding: 0.2rem;
    }
`

export const SessionInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const InfoMessage = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 2.175rem */
`

export const GameContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 65%;

    @media (max-width: 1250px) {
        align-content: space-around;
        flex-direction: column-reverse;
        height: 60%;
    }

    @media (max-width: 800px) {
        justify-content: flex-end;
        gap: 1rem;
        height: auto;
    }
`

export const BetsContent = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.content_background};
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 1.25rem;
    width: 95%;
    height: 30%;
    
    @media (max-width: 1250px) {
        height: 40%;
    }
    @media (max-width: 800px) {
        height: 120rem;
        flex-direction: column;
        background: transparent;
        box-shadow: none;
        gap: 1rem;
    }
`

export const BetBox = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.content_background};
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    justify-content: center;
    
    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        height: 29%;
        font-size: 1.3rem;
        justify-content: space-around;
        width: 90%;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 800px) {
        height: auto;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const ResultBox = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.content_background};
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 1250px) {
        height: 69%;
        width: 95%;
    }

    @media (max-width: 800px) {
        height: 30rem;
    }
`

export const BetColorsContent = styled.div`
    margin: 1rem 2rem;
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.7rem;
    width: 100%;

    @media (max-width: 1250px) {
        height: 45%;
        margin: 0;
    }
    @media (max-width: 800px) {
        height: auto;
        margin: 0;
        padding: 1rem;
    }
`

export const BetMesage = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        height: 10%;
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const ButtonGold = styled.button`
    width: 30%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.625rem;
    background: ${(props) => props.theme.MINING_ROULETTE.gold};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 5rem;
        height: 5rem;
    }
`

export const ButtonSilver = styled.button`
    width: 30%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.625rem;
    background: ${(props) => props.theme.MINING_ROULETTE.silver};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 5rem;
        height: 5rem;
    }
`

export const ButtonDiamond = styled.button`
    width: 30%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.625rem;
    background: ${(props) => props.theme.MINING_ROULETTE.diamond};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 5rem;
        height: 5rem;
    }
`

export const CardCircle = styled.div`
    border: 1px solid ${(props) => props.theme.MINING_ROULETTE.white};;
    border-radius: 10rem;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.MINING_ROULETTE.blur};
    font-size: 1.6rem;
    color: ${(props) => props.theme.MINING_ROULETTE.white};

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const ButtonCardCircle = styled.div`
    border: 1px solid ${(props) => props.theme.MINING_ROULETTE.white};;
    border-radius: 10rem;
    cursor: pointer;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.MINING_ROULETTE.blur};
    font-size: 1.6rem;
    color: ${(props) => props.theme.MINING_ROULETTE.white};

    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1250px) {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const BetValueContent = styled.div`
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 95%;
    gap: 1rem;

    @media (max-width: 1250px) {
        height: 40%;
        margin: 0;
    }

    @media (max-width: 700px) {
        width: 100%;
        margin: 0;
    }
`

export const BetValueInputContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2rem
`

export const ErrorMensageData = styled.div`
    padding: 5px;
    color: ${(props) => props.theme.MINING_ROULETTE.error_text_color};
`

export const BetValueInput = styled.input`
    border-radius: 0.7rem;
    height: 100%;
    width: 58%;
    background: ${(props) => props.theme.MINING_ROULETTE.input_background};

    @media (max-width: 1250px) {
        width: 40%;
    }
    @media (max-width: 700px) {
        width: 35%;
    }
    @media (max-width: 550px) {
        width: 45%;
    }
`

export const BetValueChangeButton = styled.button`
    width: 19%;
    height: 100%;
    border-radius: 0.7rem;
    background: ${(props) => props.theme.MINING_ROULETTE.input_background};
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
        width: 10%;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
    @media (max-width: 550px) {
        display: none;
    }
`

export const BetButton = styled.button`
    background: ${(props) => props.theme.MINING_ROULETTE.diamond};
    display: inline-flex;
    height: 4rem;
    width: 15rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.625rem;
    color: ${(props) => props.theme.MINING_ROULETTE.white};
    font-family: 'Lobster', cursive;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        height: 100%;
        width: 40%;
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
    @media (max-width: 700px) {
        width: 35%;
    }
    @media (max-width: 550px) {
        width: 45%;
    }
`

export const LastResultsContent = styled.ul`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow: hidden;
`

export const LastResultsComponent = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    flex-direction: column;
`

export const LastResult = styled.li`
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 5px 10px;
`

export const RouletteResult = styled.div`
    width: 6rem;
    height: 8rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
`

export const LastResultData = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
`

export const RouletteResultCircle = styled.div`
    border: 1px solid ${(props) => props.theme.MINING_ROULETTE.white};;
    border-radius: 10rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.MINING_ROULETTE.blur};
    font-size: 1.6rem;
    color: ${(props) => props.theme.MINING_ROULETTE.white};

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const ResultsRoulette = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 1.25rem;
    width: 98%;
    height: 60%;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.MINING_ROULETTE.content_background};;
`

export const LastResultsMesage = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};
    font-family: 'Lobster', cursive;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 2.175rem */

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const RouletteMesage = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 2.175rem */

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const GoldResult = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.gold};
    width: 6rem;
    height: 6rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1250px) {
        width: 30%;
        height: 30%;
    }
    @media (max-width: 750px) {
        width: 6rem;
        height: 6rem;
    }
`

export const SilverResult = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.silver};
    width: 6rem;
    height: 6rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1250px) {
        width: 30%;
        height: 30%;
    }
    @media (max-width: 750px) {
        width: 6rem;
        height: 6rem;
    }
`

export const DiamondResult = styled.div`
    background: ${(props) => props.theme.MINING_ROULETTE.diamond};
    width: 6rem;
    height: 6rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1250px) {
        width: 30%;
        height: 30%;
    }
    @media (max-width: 750px) {
        width: 6rem;
        height: 6rem;
    }
`

export const Bets = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;

    @media (max-width: 800px) {
        width: 100%;
        height: 40rem;
        background: ${(props) => props.theme.MINING_ROULETTE.content_background};
        box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
        border-radius: 1.25rem;
        padding: 1rem;
    }
`

export const BetsAmount = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`

export const BetsAmountText = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.725rem */

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const BetsAmountResult = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};;
    font-family: 'Lobster', cursive;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 1.725rem */

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const PlayerBetsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    overflow: scroll;

    ::-webkit-scrollbar {
        width: 5px; /* Largura da barra de rolagem */
    }

    ::-webkit-scrollbar-track {
        background: black; /* Cor de fundo da track */
    }

    ::-webkit-scrollbar-thumb {
        background: white; /* Cor do "thumb" (o que você move) */
    }

    ::-webkit-scrollbar-thumb:hover {
        background: white; /* Cor do "thumb" ao passar o mouse por cima */
    }
`

export const PlayerBets = styled.li`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const PlayerBetsResult = styled.text`
    color: ${(props) => props.theme.MINING_ROULETTE.white};
    font-family: 'Lobster', cursive;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.725rem */

    @media (max-width: 1400px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const ProgressBar = styled.progress`
    appearance: none;
    width: 80%;

    /* Chrome and Safari */

    &::-webkit-progress-bar {
        background: ${(props) => props.theme.MINING_ROULETTE.silver};
        border-radius: 2rem;
    }

    &::-webkit-progress-value {
        background-color: ${(props) => props.theme.MINING_ROULETTE.diamond};
        border-radius: 2rem;
        transition: width 0.1s;
    }

    /* Firefox */

    &::-moz-progress-bar {
        background-color: ${(props) => props.theme.MINING_ROULETTE.diamond};
        border-radius: 2rem;
    }
`

export const SleepProgress = styled.div`
    color: ${(props) => props.theme.MINING_ROULETTE.white};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    gap: 0.3rem;
`

export const Roulette = styled.div`
    display: flex;
    height: 60%;
`

export const RouletteNumbers = styled.div`
    transition-duration: 1000ms;
    transition-delay: 0ms;
    display: flex;
    position: relative;
    align-items: center;
`

export const RouletteCard = styled.li`
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
`

export const RouletteTimer = styled.div`
    display: flex;
    min-height: 20%;
    justify-content: center;
    align-items: center;
    width: 95%;
    border-radius: 0.5rem;
    height: 30%;
`

export const Selector = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    width: 4px;
    height: 80%;
    background-color: ${(props) => props.theme.MINING_ROULETTE.white};
    z-index: 1;
    
    @media (max-width: 800px) {
        height: 95%;
    }
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
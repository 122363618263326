import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        margin-top: 5rem;

        video {
            width: 95%;
        }
    }
`

export const Tittle = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    text-align: center;
    font-family: Roboto Mono;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin: 2rem;
`

export const ContainerDescription = styled.div`
    display: flex;
    gap: 4rem;
    width: 70%;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;

    @media (max-width: 650px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }
`
export const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 50%;

    @media (max-width: 750px) {
        width: 80%;
        gap: 1rem;

    }
`
export const TitleDescription = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 750px) {
        font-size: 18px;
    }
`
export const Text = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;

    @media (max-width: 750px) {
        font-size: 16px;
    }
`
export const ButtonContainer = styled.button`
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.blackBlue};
    background-color: transparent;
    border: none;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 750px) {
        img {
            width: 22px;
        }
    }
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`
export const ConteinerSeparation = styled.div`
    display: flex;
    gap: 3rem;


`

export const Separation = styled.div`
    display: flex;
    flex-direction: column;
`

export const Questions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Poppins;
    font-size: 38px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.darkBlue};
    margin-top: 4rem;
    margin-bottom: 2rem;

    @media (max-width: 750px) {
        font-size: 28px;
        margin-top: 3rem;
        margin-bottom: 0.5rem;
    }

`

export const ContainerQuestions = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;

    @media (max-width: 750px) {
        padding-top: 2rem;
        gap: 1rem;
    }
`
import {ThemeProvider} from 'styled-components';
import GlobalStyle from './styles/globalStyles';
import {theme} from './styles/themes';
import {AuthProvider} from './hooks/useAuth';
import {Routes} from './routes';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Routes/>
            </AuthProvider>
            <GlobalStyle/>
        </ThemeProvider>
    )
}

export default App;

import React from 'react'
import {useAuth} from '../hooks/useAuth'
import {LoggedRouter} from './LoggedRouter'
import {UnloggedRouter} from './UnloggedRouter'


export const Routes: React.FC = () => {
    const {signed} = useAuth()
    return signed ? <LoggedRouter/> : <UnloggedRouter/>
}

import styled, {keyframes} from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-image: url('/backgroundCrashTrade.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.5rem;

    @media (max-width: 1400px) {
        flex-direction: column-reverse;
    }

    @media (max-width: 1400px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.2rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const LastResults = styled.div`
    display: flex;
    align-items: center;
    height: 10%;
    width: 90%;
    gap: 0.5rem;
    overflow: hidden;
    flex-direction: initial;
    justify-content: flex-end;
`

export const BetsContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 25%;
    justify-content: center;

    ::-webkit-scrollbar {
        width: 5px; /* Largura da barra de rolagem */
    }

    ::-webkit-scrollbar-track {
        background: black; /* Cor de fundo da track */
    }

    ::-webkit-scrollbar-thumb {
        background: white; /* Cor do "thumb" (o que você move) */
    }

    ::-webkit-scrollbar-thumb:hover {
        background: white; /* Cor do "thumb" ao passar o mouse por cima */
    }

    @media (max-width: 1400px) {
        width: 70%;
        height: 30%;
    }

    @media (max-width: 1000px) {
        width: 80%;
    }

    @media (max-width: 650px) {
        width: 95%;
    }
`

export const GraphicContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 75%;
    gap: 1rem;

    @media (max-width: 1400px) {
        width: 85%;
        height: 70%;
    }

    @media (max-width: 1000px) {
        width: 90%;
    }
`

export const ResultBox = styled.div`
    padding: 5px;
    background-color: ${(props) => props.theme.CRASH_TRADE.black};
    color: ${(props) => props.theme.CRASH_TRADE.white};
    border-radius: 0.5rem;
    font-size: 1.4rem;

    @media (max-width: 1400px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.2rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const Result = styled.div`
    padding: 5px;
`

export const TradeGraphic = styled.div`
    display: flex;
    justify-content: center;
    height: 60%;
    width: 95%;
    background-color: ${(props) => props.theme.CRASH_TRADE.black};
    border-radius: 0.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    color: ${(props) => props.theme.CRASH_TRADE.white};

    @media (max-width: 1400px) {
        height: 80%;
    }
    @media (max-width: 750px) {
        width: 100%;
    }
`

export const GraphicLeft = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 10%;
`

export const GraphicRight = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 90%;
`

export const RightStopedContent = styled.div`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const RightRunningContent = styled.div`
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    color: white;
    overflow: hidden;
`

export const GraphicLeftUp = styled.div`
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 90%;
    width: 100%;
`

export const GraphicLeftDown = styled.div`
    display: flex;
    justify-content: center;
    height: 10%;
    width: 100%;
`

export const GraphicRightUp = styled.div`
    display: flex;
    justify-content: center;
    height: 90%;
    width: 100%;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    position: relative;
`

export const GraphicRightDown = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;
`

export const GraphicLeftX = styled.div`
    display: flex;
    justify-content: center;
    height: 10%;
    width: 100%;
`
export const TableBets = styled.table`
    width: 80%;
    max-height: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 0.5rem;
    padding-top: 1rem;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.5rem;
    border-collapse: collapse;
    background-color: ${(props) => props.theme.CRASH_TRADE.black};
    color: ${(props) => props.theme.CRASH_TRADE.white};
    overflow: scroll;
    
    @media (max-width: 1400px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.2rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
        width: 90%;
    }
`


export const ThTable = styled.th`
    border: 1px solid white;
    text-align: center;
    padding: 0.5rem;
`

export const TdTable = styled.td`
    border: 1px solid white;
    text-align: center;
    padding: 0.5rem;
    justify-content: left;
`

export const BetButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: auto;
    background-color: ${(props) => props.theme.CRASH_TRADE.black};
    border-radius: 0.5rem;
    gap: 1rem;
    padding: 1rem;

    @media (max-width: 1400px) {
        height: 20%;
    }

    @media (max-width: 750px) {
        gap: 0.5rem;
        padding: 0.5rem;
    }
`

export const Text = styled.text`
    font-family: 'Exo 2', sans-serif;
    font-size: 1.5rem;
    flex-wrap: nowrap;
    text-align: center;

    @media (max-width: 1400px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.2rem;
    }
    @media (max-width: 750px) {
        font-size: 1rem;
    }
`

export const Action = styled.div`
    color: ${(props) => props.theme.CRASH_TRADE.white};
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
`

export const InputValue = styled.input`
    all: unset;
    width: 8rem;
    height: 30%;
    border-radius: 1rem;
    background: ${(props) => props.theme.CRASH_TRADE.gray};
    color: ${(props) => props.theme.CRASH_TRADE.white};
    text-align: center;
`

export const BoxValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    width: 8rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.CRASH_TRADE.gray};
    padding: 1rem;
`

export const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-radius: 1rem;
    cursor: pointer;
    flex-direction: column;
    color: ${(props) => props.theme.CRASH_TRADE.button};
    gap: 1rem;
    background-color: black;
    flex-wrap: nowrap;
`

export const ActionImg = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`

export const ErrorMensageData = styled.div`
    padding: 5px;
    color: ${(props) => props.theme.CRASH_TRADE.error_text_color};
`

export const AmountBets = styled.div`
`

export const Trade = styled.div`
`

export const TradeFloat = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 70%;
    border-radius: 1rem;
`

export const TradeLine = styled.div`
    height: 120%;
    width: 2px;
    border-radius: 1rem;
`

export const TradeResult = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`


export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
import {Container} from "./styles";
import {IoClose} from "react-icons/io5";
import {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface PropsMenu {
    menuIsVisible: boolean;
    setMenuIsVisible(arg0: boolean): void;
}

const goTo = (url: string) => {
    window.location.href = url;
};

const exit = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    window.location.href = "/";
};

const token = localStorage.getItem("token");

export function MenuMobile({menuIsVisible, setMenuIsVisible}: PropsMenu) {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.style.overflowY = menuIsVisible ? "hidden" : "auto";
    }, [menuIsVisible]);

    if (token) {
        return (
            <Container isVisible={menuIsVisible}>
                <IoClose size={45} onClick={() => setMenuIsVisible(false)}/>
                <nav>
                    <NavLink to="/" onClick={() => setMenuIsVisible(false)}>
                        {t("homePage")}
                    </NavLink>
                    <NavLink className="navLink" to="/games" onClick={() => setMenuIsVisible(false)}>
                        {t("ourGames")}
                    </NavLink>
                    <NavLink to="/powergaming" onClick={() => setMenuIsVisible(false)}>
                        {t("companyName")}
                    </NavLink>
                </nav>
            </Container>
        );
    } else {
        return (
            <Container isVisible={menuIsVisible}>
                <IoClose size={45} onClick={() => setMenuIsVisible(false)}/>
                <nav>
                    <NavLink to="/" onClick={() => setMenuIsVisible(false)}>
                        {t("homePage")}
                    </NavLink>
                    <NavLink to="/games" onClick={() => setMenuIsVisible(false)}>
                        {t("ourGames")}
                    </NavLink>
                    <NavLink to="/powergaming" onClick={() => setMenuIsVisible(false)}>
                        {t("companyName")}
                    </NavLink>
                    <NavLink className="login" to="/login" onClick={() => setMenuIsVisible(false)}>
                        {t("login")}
                    </NavLink>
                    <NavLink className="register" to="/register" onClick={() => setMenuIsVisible(false)}>
                        {t("register")}
                    </NavLink>
                </nav>
            </Container>
        );
    }
}

import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    z-index: 9999;
    opacity: 0.5;
    cursor: pointer;
    background: ${(props) => props.theme.COLORS.shadow};
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ModalContainer = styled.div`
    z-index: 999999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const PopupContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50rem;
    height: 20rem;

    border-radius: 25px;
    background: ${(props) => props.theme.COLORS.linearLightBue};

    @media (max-width: 750px) {
        width: 30rem;
        height: 20rem;
    }
`

export const Text = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    @media (max-width: 750px) {
        gap: 3rem;
        padding: 1.5rem;
    }
`

export const BaseContainerText = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'Merriweather';
    font-size: 20px;
    color: ${(props) => props.theme.COLORS.blackBlue};

    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const ContainerText = styled(BaseContainerText)`
    font-size: 25px;

    @media (max-width: 750px) {
        font-size: 1.5rem;
    }
`
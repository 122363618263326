import styled, {css} from 'styled-components';

interface IContainer {
    isVisible: boolean;
}

const getConditionalStyles = ({isVisible}: IContainer) => css`
    pointer-events: ${isVisible ? 'auto' : 'none'};
    transform: translateY(${isVisible ? '0px' : '50px'});

    > svg {
        transform: rotate(${isVisible ? '0deg' : '45deg'});
    }

    nav {
        transform: scale(${isVisible ? '1.2' : '0.5'});
    }
`;

export const Container = styled.section<IContainer>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.COLORS.linearLightBue};
    opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.5s;

    > svg {
        position: absolute;
        flex-direction: column;
        align-items: flex-end;
        top: 1rem;
        right: 1rem;
        transform: rotate(45deg);
        transition: 0.7s;
        cursor: pointer;
        color: ${(props) => props.theme.COLORS.darkBlue};
    }

    nav {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        gap: 2rem;
        transform: scale(0.5);
        transition: 0.7s;


        h4 {
            color: ${(props) => props.theme.COLORS.darkBlue};
        }

        a {
            all: unset;
            cursor: pointer;
            color: ${(props) => props.theme.COLORS.darkBlue};
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.8rem;
            align-self: center;

            &:hover {
                text-decoration: underline;
            }

            &.active {
                color: ${(props) => props.theme.COLORS.darkBlue};
                border: none;
                text-decoration: underline;
            }
        }

    }

    ${getConditionalStyles};
`;

import {api} from "../services/api";
import {ECOMMERCE_CONFIG} from "../services/service.config";
import {IProductPDP, IProductPLP} from "../types";

export async function GetAllProducts() {
    let allProducts: IProductPLP[] = [{
        id: 0,
        name: '',
        description: '',
    }];
    await api
        .get(
            ECOMMERCE_CONFIG.getProductsAll.path + ECOMMERCE_CONFIG.getProductsAll.endpoint
        )
        .then((response: { data: { products: IProductPLP[]; }; }) => {
            allProducts = response.data.products;
        })
        .catch()
    return allProducts
}

export async function GetProduct(id: number) {
    let productsPdp: IProductPDP = {
        name: '',
        description: '',
        rtd: '',
        gameType: '',
        release: '',
        license: '',
    };
    await api
        .get(
            ECOMMERCE_CONFIG.getProducts.path + ECOMMERCE_CONFIG.getProducts.endpoint + "?id=" + id
        )
        .then((response: { data: IProductPDP; }) => {
            productsPdp = response.data;
        })
        .catch()
    return productsPdp
}
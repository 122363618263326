import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('/Mapa.png');
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    padding: 2rem;
    height: 100vh;

    @media (max-width: 900px) {
        padding: 1rem;
    }

    @media (max-width: 750px) {
        padding: 0.5rem;
    }
`

export const GameTable = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${(props) => props.theme.SKULL.brown};
    border-radius: 1rem;
    height: 70%;
    width: 40%;

    @media (max-width: 1450px) {
        width: 50%;
    }

    @media (max-width: 1050px) {
        width: 70%;
    }

    @media (max-width: 900px) {
        width: 80%;
    }

    @media (max-width: 750px) {
        width: 90%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`

export const Board = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    height: 90%;
    justify-content: center;

`

export const Column = styled.div`
    width: 25%;
    background-color: ${(props) => props.theme.SKULL.cell};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Cell = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.SKULL.cell};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
`

export const MenuContent = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.SKULL.white};
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
        font-size: 18px;
    }
`

export const Actions = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const Action = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ValeuIfWin = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.SKULL.white};
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    align-items: center;
    height: 25%;

    @media (max-width: 750px) {
        font-size: 18px;
    }
`

export const RestartButton = styled.button`
    border-radius: 20px;
    cursor: pointer;
    background: ${(props) => props.theme.SKULL.red};
    color: ${(props) => props.theme.SKULL.white};
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: center;
    width: 30%;

    @media (max-width: 750px) {
        font-size: 20px;
    }
`

export const BetButton = styled.button`
    border-radius: 20px;
    cursor: pointer;
    background: ${(props) => props.theme.SKULL.yellow};
    color: ${(props) => props.theme.SKULL.white};
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: center;
    width: 30%;

    @media (max-width: 750px) {
        font-size: 20px;
    }
`

export const CashOutButton = styled.button`
    border-radius: 20px;
    cursor: pointer;
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: center;
    color: ${(props) => props.theme.SKULL.white};
    width: 30%;

    @media (max-width: 750px) {
        font-size: 20px;
    }
`

export const Select = styled.select`
    font-family: "Pirata One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 50%;
    align-self: center;
    background: ${(props) => props.theme.SKULL.brown};
    height: 40%;
    border-radius: 1rem;
    text-align: center;
`

export const ErrorMensageData = styled.div`
`

export const InputMensage = styled.input`
    width: 50%;
    height: 40%;
    border-radius: 1rem;
    background: ${(props) => props.theme.SKULL.gray};
    color: ${(props) => props.theme.SKULL.white};
`

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.SKULL.brown};
    width: 30%;
    border-radius: 0px 0px 20px 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    height: 30%;

    @media (max-width: 1450px) {
        width: 40%;
    }

    @media (max-width: 1050px) {
        width: 50%;
    }

    @media (max-width: 900px) {
        width: 60%;
    }

    @media (max-width: 750px) {
        width: 70%;
    }

    @media (max-width: 500px) {
        width: 80%;
    }
`

export const Buttons = styled.div`
    display: flex;
    height: 30%;
    width: 100%;
    justify-content: space-around;

    @media (max-width: 750px) {
    }
`

export const Imagens = styled.img`
    width: 75px;
    height: 75px;

    @media (max-width: 750px) {
        width: 40px;
        height: 40px;
    }
`;

export const Infos = styled.div`
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.theme.SKULL.white};
    padding: 5px;
    height: 10%;


    @media (max-width: 750px) {
        font-size: 18px;

    }
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
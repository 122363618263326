import {useEffect, useState} from 'react'
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {useParams} from 'react-router-dom';
import {GetCanBet, GetLastTwentyResults, GetPlayerBet, GetRoundAllBets} from '../../hooks/useCrash';
import {GetSessionInfos} from '../../hooks/useSessionInfo';
import {ICrashBetResponse, ICrashBets, ICrashLastResults, ICrashTrade, ISessionInfos} from '../../types';

import {
    Action,
    ActionButton,
    ActionImg,
    AmountBets,
    BetButtons, BetsContainer,
    BoxValue, ButtonMusic,
    Container,
    ErrorMensageData, ErrorMessageData, GraphicContainer,
    GraphicLeft,
    GraphicLeftDown,
    GraphicLeftUp,
    GraphicLeftX,
    GraphicRight,
    GraphicRightDown,
    GraphicRightUp,
    InputValue,
    LastResults,
    Result,
    ResultBox,
    RightRunningContent,
    RightStopedContent, SoundImage,
    TableBets,
    TdTable,
    Text,
    ThTable,
    Trade,
    TradeFloat,
    TradeGraphic,
    TradeLine,
    TradeResult
} from './styles';
import Play from '../../assets/games/crash/bet.png'
import CashOut from '../../assets/games/crash/cashOut.png'
import dice from "../../assets/audio/dice.mp3";
import playMusic from "../../assets/audio/images/playMusicWhite.png";
import pauseMusic from "../../assets/audio/images/pauseMusicWhite.png";
import {useTranslation} from "react-i18next";
import {GetCasinoAccess} from "../../hooks/useAuth";
import {URLs} from "../../services/service.config";

const CrashV1Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [blockBet, setBlockBet] = useState(false);
    const [canCashOut, setCanCashOut] = useState(false);
    const [lastTwentyResults, setLastTwentyResults] = useState<ICrashLastResults>({
        results: [],
        isValid: true
    });
    const [crashSleep, setCrashSleep] = useState(0);
    const [crashSleepResult, setCrashSleepResult] = useState(0);
    const [crashResult, setCrashResult] = useState(0);
    const [timeRunning, setTimeRunning] = useState(0);
    const [data, setData] = useState<ICrashTrade[]>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    const [allBets, setAllBets] = useState<ICrashBets>({
        amount: 0,
        roundCrashBets: [],
        isValid: true
    });
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [userData, setUserData] = useState({
        result: '',
        betValue: 0
    });
    const [playerBet, setPlayerBet] = useState<ICrashBetResponse>({
        amountBet: 0,
        isBet: false,
        cashOut: false,
        betId: 0
    })

    useEffect(() => {
        FindCasinoAccess();
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Crash Trade", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsCrashV1)));
        FindSessionInfos();
        CanBet();
        FindTwentyResults();
        FindRoundAllBets();
    }

    async function FindSessionInfos() {
        setSessionInfos(await GetSessionInfos(session));
    }

    async function FindTwentyResults() {
        setLastTwentyResults(await GetLastTwentyResults(session!))
    }

    async function FindRoundAllBets() {
        setAllBets(await GetRoundAllBets(session!))
    }

    async function CanBet() {
        const canBetResponse = await GetCanBet(session!);
        setBlockBet(!canBetResponse.canBet);
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    useEffect(() => {
        if (Number.isInteger(timeRunning)) {
            data.push({
                width: "2%",
                height: 5 * crashResult,
                color: "green",
                heightTrade: (Math.random() * (10 - 1) + 1) + "rem"
            })
            setData([...data]);
        }
    }, [timeRunning])

    useEffect(() => {
        if (crashResult === 0) {
            setData([]);
        }
    }, [crashResult])

    useEffect(() => {

        if (crashSleepResult === 3) {
            const auxData = data[data.length - 1];
            data.push({
                width: "2%",
                height: auxData.height,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            data.push({
                width: "2%",
                height: (auxData.height / 6) * 5,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            data.push({
                width: "2%",
                height: (auxData.height / 6) * 4,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            setData([...data]);
        }
        if (crashSleepResult === 2) {
            const auxData = data[data.length - 1];
            data.push({
                width: "2%",
                height: (auxData.height / 6) * 3,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            data.push({
                width: "2%",
                height: (auxData.height / 6) * 2,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            data.push({
                width: "2%",
                height: auxData.height / 6,
                color: "red",
                heightTrade: (Math.random() * (10 - 5) + 5) + "rem"
            })
            setData([...data]);
        }
    }, [crashSleepResult])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = () => {
        stompClient.subscribe('/user/' + casino + '/crashResults/public', onLastResultReceived);
        stompClient.subscribe('/user/' + casino + '/lastTwentyCrashResults/public', onLastTwentyResultsReceived);
        stompClient.subscribe('/user/' + casino + '/allBets/public', onAllBetsReceived);
        stompClient.subscribe('/user/' + casino + '/blockCrashBet/public', onBlockBetReceived);
        stompClient.subscribe('/user/' + casino + '/canCashOut/public', onCanCashOutReceived);
        stompClient.subscribe('/user/' + casino + '/crashSleep/public', onCrashSleepReceived);
        stompClient.subscribe('/user/' + casino + '/crashSleepResult/public', onCrashSleepResultReceived);
        stompClient.subscribe('/user/' + casino + '/crashResult/public', onCrashResultReceived);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-crash', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/crashSessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/bet', onBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidCrash', onIsValidMessage);
        FindPlayerBet()
    }

    const onLastResultReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPlayerBet({
            amountBet: 0,
            isBet: false,
            cashOut: false,
            betId: 0
        })
    }

    const onLastTwentyResultsReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setLastTwentyResults(payloadData);
    }

    const onAllBetsReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setAllBets(payloadData);
    }

    const onBlockBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBlockBet(payloadData);
    }

    const onCanCashOutReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanCashOut(payloadData);
    }

    const onCrashSleepReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCrashSleep(payloadData);
    }

    const onCrashSleepResultReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCrashSleepResult(payloadData);
    }

    const onCrashResultReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCrashResult(payloadData.counter);
        setTimeRunning(payloadData.timeRunning);
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPlayerBet(payloadData);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({...sessionInfos, "isValid": payloadData});
    }

    async function FindPlayerBet() {
        setPlayerBet(await GetPlayerBet(session!, sessionInfos.playerId!))
    }

    const onError = (err: any) => {

    }

    const handleBetValue = (event: { target: { value: any; }; }) => {
        const {value} = event.target;
        setUserData({...userData, "betValue": value});
    }

    function sendBet() {
        if (stompClient) {
            var bet = {
                amount: sessionInfos.amount,
                amountBet: userData.betValue,
                playerId: sessionInfos.playerId!,
                color: "BLACK",
                isBet: false,
                user: casino,
                sessionId: session,
                userName: sessionInfos.playerName
            };
            stompClient.send("/app/betCrash", {}, JSON.stringify(bet));
            setPrivateChat("");
        }
    }

    function sendCashOut() {
        if (stompClient) {
            var cashOut = {
                id: playerBet.betId,
                counter: crashResult,
                user: casino
            };
            stompClient.send("/app/cashOut", {}, JSON.stringify(cashOut));
        }
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Container>
            ) : (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <BetsContainer>
                        <TableBets>
                            <AmountBets>{t("bettingAmount")} {allBets.amount}</AmountBets>
                            <thead>
                            <tr>
                                <ThTable>{t("username")}</ThTable>
                                <ThTable>{t("bettingAmount")}</ThTable>
                                <ThTable>{t("moneyEarned")}</ThTable>
                            </tr>
                            </thead>
                            <tbody>
                            {allBets.roundCrashBets!.map((bet, index) => (
                                <tr key={index}>
                                    <TdTable>{bet.userName}</TdTable>
                                    <TdTable>{t("currency")}{bet.amountBet.toFixed(2)}</TdTable>
                                    <TdTable>{t("currency")}{(bet.cashedOutValue * bet.amountBet).toFixed(2)}</TdTable>
                                </tr>
                            ))}
                            </tbody>
                        </TableBets>
                    </BetsContainer>
                    <GraphicContainer>
                        <LastResults>
                            {lastTwentyResults.results!.map((result, index) => (
                                <ResultBox key={index}>
                                    <Result>{result}</Result>
                                </ResultBox>
                            ))}
                        </LastResults>
                        <TradeGraphic>
                            <GraphicLeft>
                                <GraphicLeftUp>
                                    <GraphicLeftX>20x</GraphicLeftX>
                                    <GraphicLeftX>18x</GraphicLeftX>
                                    <GraphicLeftX>16x</GraphicLeftX>
                                    <GraphicLeftX>14x</GraphicLeftX>
                                    <GraphicLeftX>12x</GraphicLeftX>
                                    <GraphicLeftX>10x</GraphicLeftX>
                                    <GraphicLeftX>8x</GraphicLeftX>
                                    <GraphicLeftX>6x</GraphicLeftX>
                                    <GraphicLeftX>4x</GraphicLeftX>
                                    <GraphicLeftX>2x</GraphicLeftX>
                                </GraphicLeftUp>
                                <GraphicLeftDown/>
                            </GraphicLeft>
                            <GraphicRight>
                                <GraphicRightUp>
                                    <RightStopedContent style={{display: crashResult == 0 ? "flex" : "none"}}>
                                        {crashSleep}s
                                    </RightStopedContent>
                                    <RightRunningContent
                                        style={{display: (crashResult != 0 || crashSleepResult > 0) ? "flex" : "none"}}>
                                        {data.map((dt, index) => (
                                            <Trade style={{height: dt.height + "%", width: dt.width}}>
                                                <TradeFloat style={{height: dt.heightTrade, backgroundColor: dt.color}}>
                                                    <TradeLine style={{backgroundColor: dt.color}}></TradeLine>
                                                </TradeFloat>
                                            </Trade>
                                        ))}
                                        <TradeResult>
                                            x{crashResult.toFixed(2)}
                                        </TradeResult>
                                    </RightRunningContent>
                                </GraphicRightUp>
                                <GraphicRightDown>
                                    {timeRunning}s
                                </GraphicRightDown>
                            </GraphicRight>
                        </TradeGraphic>
                        <BetButtons>
                            <Action>
                                <Text>
                                    {t("balance")}
                                </Text>
                                <BoxValue>{sessionInfos.amount.toFixed(2)}</BoxValue>
                            </Action>
                            <Action>
                                <Text>
                                    {t("betAmount")}
                                </Text>
                                <InputValue type="number" value={userData.betValue} onChange={handleBetValue}/>
                            </Action>
                            <Action
                                style={{display: (playerBet.isBet === true || blockBet === true) && ((playerBet.isBet === true && canCashOut === true) || playerBet.isBet === false) ? "none" : "flex"}}>
                                <ActionButton disabled={playerBet.isBet === true || blockBet === true}
                                              style={{display: playerBet.isBet === true || blockBet === true ? "none" : "flex"}}
                                              onClick={sendBet}>
                                    <Text>
                                        {t("bet")}
                                    </Text>
                                    <ActionImg alt='Play' src={Play}></ActionImg>
                                </ActionButton>
                                <ActionButton
                                    disabled={(playerBet.isBet === true && canCashOut === true) || playerBet.isBet === false}
                                    style={{display: (playerBet.isBet === true && canCashOut === true) || playerBet.isBet === false ? "none" : "flex"}}
                                    onClick={sendCashOut}>
                                    <Text>
                                        {t("cashOut")}
                                    </Text>
                                    <ActionImg alt='Cash Out' src={CashOut}></ActionImg>
                                </ActionButton>
                            </Action>
                            <ErrorMensageData hidden={privateChat == ""}>{t(privateChat)}</ErrorMensageData>
                        </BetButtons>
                    </GraphicContainer>
                </Container>
            )}
        </>
    )
}

export default CrashV1Room
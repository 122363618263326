import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GetSessionInfos } from '../../../hooks/useSessionInfo';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { ISessionInfos } from '../../../types';
import {
    Action,
    BetButton,
    BetImg,
    BlueBall,
    Blurn,
    BoxValue,
    ButtonMusic,
    ButtonsContent,
    Cell,
    CellValidumn,
    Column,
    Container,
    Content,
    ContentSeven,
    ErrorMensageData, ErrorMessageData,
    FlyingtContent,
    FlyingtImg7,
    GrennBall,
    InputValue,
    MachineResults,
    PinkBall,
    PurpleBall,
    ResultImg,
    SevenImg,
    SlotMachine,
    SlotMachineButtons,
    SlotMachineTop,
    SoundImage,
    Text,
    TextWallet,
    TextWin,
    YellowBall
} from './styles';
import Apple from '../../../assets/games/slotmachine/apple.png'
import Bell from '../../../assets/games/slotmachine/bell.png'
import Cherry from '../../../assets/games/slotmachine/cherry.png'
import Grape from '../../../assets/games/slotmachine/grape.png'
import Lemon from '../../../assets/games/slotmachine/lemon.png'
import Seven from '../../../assets/games/slotmachine/seven.png'
import Watermelon from '../../../assets/games/slotmachine/watermelon.png'
import Play from '../../../assets/games/slotmachine/play.png'
import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import { useTranslation } from "react-i18next";
import { GetCasinoAccess } from "../../../hooks/useAuth";
import { URLs } from "../../../services/service.config";

const SlotMachineV1Room = () => {
    const { t } = useTranslation();
    const { casino, session } = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [betValue, setBetValue] = useState(0);
    const [winValue, setWinValue] = useState(0);
    const [canBet, setCanBet] = useState(true);
    const [cell0, setCell0] = useState("");
    const [cell1, setCell1] = useState("");
    const [cell2, setCell2] = useState("");
    const [cell3, setCell3] = useState("");
    const [cell4, setCell4] = useState("");
    const [cell5, setCell5] = useState("");
    const [cell6, setCell6] = useState("");
    const [cell7, setCell7] = useState("");
    const [cell8, setCell8] = useState("");
    const [isMobile, setIsMobile] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess();
        checkMobile()
        window.addEventListener('resize', checkMobile)
        return () => {
            window.removeEventListener('resize', checkMobile)
        }
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Slot Machine 777", session!));
    }

    const checkMobile = () => {
        const isMobileSize = window.innerWidth <= 850
        setIsMobile(isMobileSize)
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsSlotMachineV1)));
        FindSessionInfos();
    }

    async function FindSessionInfos() {
        await setSessionInfos(await GetSessionInfos(session))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = async () => {
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/slotMachineSessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/blockSlotMachineBet', onBlockBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-slot-machine', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/flipSlotMachineCell', onFlipSlotMachineCellMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/slotMachineWinValue', onSlotMachineWinValueMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidSlotMachine', onIsValidMessage);
        setCanBet(false);
    }

    const onError = (err: any) => {
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onBlockBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanBet(payloadData);
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onFlipSlotMachineCellMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        const id = payloadData.cellId;
        const result = payloadData.result;
        if (id === 0) {
            setCell0(result);
        } else if (id === 1) {
            setCell1(result);
        } else if (id === 2) {
            setCell2(result);
        } else if (id === 3) {
            setCell3(result);
        } else if (id === 4) {
            setCell4(result);
        } else if (id === 5) {
            setCell5(result);
        } else if (id === 6) {
            setCell6(result);
        } else if (id === 7) {
            setCell7(result);
        } else if (id === 8) {
            setCell8(result);
        }
    }

    const onSlotMachineWinValueMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setWinValue(payloadData);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({ ...sessionInfos, "isValid": payloadData });
    }

    const handleBetValue = (event: any) => {
        const { value } = event.target;
        setBetValue(value);
    }

    async function sendBet() {
        setPrivateChat("");
        var bet = {
            amount: sessionInfos.amount,
            amountBet: betValue,
            isBet: false,
            user: casino,
            sessionId: session,
            playerId: sessionInfos.playerId!,
        };
        stompClient.send("/app/betSlotMachine", {}, JSON.stringify(bet));
        setWinValue(0);
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <Container>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Blurn>
                    <audio style={{ display: "none" }} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg" />
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                            alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Blurn>
            ) : (
                <Blurn>
                    {!isMobile && (
                        <FlyingtContent>
                            <FlyingtImg7 alt="" src={Seven}/>
                        </FlyingtContent>
                    )}
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <Content>
                        <SlotMachineTop>
                            <PurpleBall></PurpleBall>
                            <YellowBall></YellowBall>
                            <GrennBall></GrennBall>
                            <BlueBall></BlueBall>
                            <PinkBall></PinkBall>
                        </SlotMachineTop>
                        <SlotMachine>
                            <ContentSeven>
                                <SevenImg alt={t("seven")} src={Seven}></SevenImg>
                                <SevenImg alt={t("seven")} src={Seven}></SevenImg>
                                <SevenImg alt={t("seven")} src={Seven}></SevenImg>
                            </ContentSeven>
                            <MachineResults>
                                <Column>
                                    <Cell id='0'>
                                        <ResultImg alt={cell0} src={cell0 == "SEVEN" ? Seven :
                                            cell0 == "BELL" ? Bell :
                                                cell0 == "CHERRY" ? Cherry :
                                                    cell0 == "APPLE" ? Apple :
                                                        cell0 == "GRAPE" ? Grape :
                                                            cell0 == "WATERMELON" ? Watermelon :
                                                                cell0 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                    <CellValidumn id='1'>
                                        <ResultImg alt={cell1} src={cell1 == "SEVEN" ? Seven :
                                            cell1 == "BELL" ? Bell :
                                                cell1 == "CHERRY" ? Cherry :
                                                    cell1 == "APPLE" ? Apple :
                                                        cell1 == "GRAPE" ? Grape :
                                                            cell1 == "WATERMELON" ? Watermelon :
                                                                cell1 == "LEMON" ? Lemon : ""}/>
                                    </CellValidumn>
                                    <Cell id='2'>
                                        <ResultImg alt={cell2} src={cell2 == "SEVEN" ? Seven :
                                            cell2 == "BELL" ? Bell :
                                                cell2 == "CHERRY" ? Cherry :
                                                    cell2 == "APPLE" ? Apple :
                                                        cell2 == "GRAPE" ? Grape :
                                                            cell2 == "WATERMELON" ? Watermelon :
                                                                cell2 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                </Column>
                                <Column>
                                    <Cell id='3'>
                                        <ResultImg alt={cell3} src={cell3 == "SEVEN" ? Seven :
                                            cell3 == "BELL" ? Bell :
                                                cell3 == "CHERRY" ? Cherry :
                                                    cell3 == "APPLE" ? Apple :
                                                        cell3 == "GRAPE" ? Grape :
                                                            cell3 == "WATERMELON" ? Watermelon :
                                                                cell3 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                    <CellValidumn id='4'>
                                        <ResultImg alt={cell4} src={cell4 == "SEVEN" ? Seven :
                                            cell4 == "BELL" ? Bell :
                                                cell4 == "CHERRY" ? Cherry :
                                                    cell4 == "APPLE" ? Apple :
                                                        cell4 == "GRAPE" ? Grape :
                                                            cell4 == "WATERMELON" ? Watermelon :
                                                                cell4 == "LEMON" ? Lemon : ""}/>
                                    </CellValidumn>
                                    <Cell id='5'>
                                        <ResultImg alt={cell5} src={cell5 == "SEVEN" ? Seven :
                                            cell5 == "BELL" ? Bell :
                                                cell5 == "CHERRY" ? Cherry :
                                                    cell5 == "APPLE" ? Apple :
                                                        cell5 == "GRAPE" ? Grape :
                                                            cell5 == "WATERMELON" ? Watermelon :
                                                                cell5 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                </Column>
                                <Column>
                                    <Cell id='6'>
                                        <ResultImg alt={cell6} src={cell6 == "SEVEN" ? Seven :
                                            cell6 == "BELL" ? Bell :
                                                cell6 == "CHERRY" ? Cherry :
                                                    cell6 == "APPLE" ? Apple :
                                                        cell6 == "GRAPE" ? Grape :
                                                            cell6 == "WATERMELON" ? Watermelon :
                                                                cell6 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                    <CellValidumn id='7'>
                                        <ResultImg alt={cell7} src={cell7 == "SEVEN" ? Seven :
                                            cell7 == "BELL" ? Bell :
                                                cell7 == "CHERRY" ? Cherry :
                                                    cell7 == "APPLE" ? Apple :
                                                        cell7 == "GRAPE" ? Grape :
                                                            cell7 == "WATERMELON" ? Watermelon :
                                                                cell7 == "LEMON" ? Lemon : ""}/>
                                    </CellValidumn>
                                    <Cell id='8'>
                                        <ResultImg alt={cell8} src={cell8 == "SEVEN" ? Seven :
                                            cell8 == "BELL" ? Bell :
                                                cell8 == "CHERRY" ? Cherry :
                                                    cell8 == "APPLE" ? Apple :
                                                        cell8 == "GRAPE" ? Grape :
                                                            cell8 == "WATERMELON" ? Watermelon :
                                                                cell8 == "LEMON" ? Lemon : ""}/>
                                    </Cell>
                                </Column>
                            </MachineResults>
                        </SlotMachine>
                        <SlotMachineButtons>
                            <ButtonsContent>
                                <Action>
                                    <TextWallet>
                                        {t("wallet")}
                                    </TextWallet>
                                    <BoxValue>{sessionInfos.amount.toFixed(2)}</BoxValue>
                                </Action>
                                <Action>
                                    <Text>
                                        {t("betAmount")}
                                    </Text>

                                    <InputValue type={"number"} value={betValue} onChange={handleBetValue}/>
                                </Action>
                                <Action>
                                    <TextWin>
                                        {t("win")}
                                    </TextWin>
                                    <BoxValue>{winValue}</BoxValue>
                                </Action>
                            </ButtonsContent>
                            <BetButton disabled={canBet} onClick={sendBet}>
                                <BetImg alt='Play' src={Play}></BetImg>
                            </BetButton>
                            <ErrorMensageData hidden={privateChat == ""}>{t(privateChat)}</ErrorMensageData>
                        </SlotMachineButtons>
                    </Content>
                    {!isMobile && (
                        <FlyingtContent>
                            <FlyingtImg7 alt="" src={Seven}/>
                        </FlyingtContent>
                    )}
                </Blurn>
            )}
        </Container>
    )
}

export default SlotMachineV1Room
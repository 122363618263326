import styled from 'styled-components'

export const ContainerBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    @media (max-width: 750px) {
        gap: 0rem;
    }
`

export const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-family: 'Poppins';
    justify-content: space-between;
    border: 1px solid #ffffff;
    border-radius: 50px;
    color: ${(props) => props.theme.white};
    padding: 3rem;
    background: ${(props) => props.theme.shadow};
    width: 45rem;

    @media (max-width: 750px) {
        width: 25rem;
        padding: 2rem;
    }
`

export const H1Container = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    font-family: 'Poppins';
    font-style: italic;
    line-height: 4rem;
    font-size: 2.3rem;
    color: ${(props) => props.theme.white};

    @media (max-width: 750px) {
        line-height: 3rem;
        font-size: 2rem;
    }
`

export const Wrapinput = styled.div`
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 2rem;

    @media (max-width: 750px) {
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.6rem;
    }

    input {
        all: unset;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;

        @media (max-width: 750px) {
            font-size: 1rem;
            line-height: 2rem;
        }
    }
`

interface MatchPasswordProps {
    match: boolean
}

export const ContainerAlert = styled.div<MatchPasswordProps>`
    display: ${(props) => (props.match ? 'none' : 'flex')};
    text-align: center;
`

export const Containerbtn = styled.button<MatchPasswordProps>`
    display: ${(props) => (props.match ? 'flex' : 'none')};
    font-size: 1.2rem;
    height: 3rem;
    transition: filter 0.2s;
    font-style: italic;
    border: 1px solid;
    border-radius: 0.5rem;
    line-height: 1.2;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    width: 65%;
    color: ${(props) => props.theme.COLORS.white};

    background: ${(props) => props.theme.COLORS.darkBlue};

    @media (max-width: 750px) {
        width: 280px;
        height: 50px;
    }

    &:hover {
        filter: brightness(0.7);
    }

    &.active {
        text-decoration: underline;
    }
`

export const Container = styled.div``
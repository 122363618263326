import {Container} from './styles'
import {useTranslation} from "react-i18next";
import {LanguageSwitcher} from "../LanguageSwitcher";

export function Footer() {
    const { t } = useTranslation();

    return (
        <Container>
            <LanguageSwitcher></LanguageSwitcher>
            <h4>{t("footerCopyright")}</h4>
        </Container>
    )
}
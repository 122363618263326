import logo from "../../assets/Logo.png";
import menu from "../../assets/menu.svg";

import {
    Container,
    ContainerButtons,
    Content,
    UnLogged,
    MenuContentStyled,
    LogoContainer,
    MenuIconStyled,
    Separation,
} from "./styles";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface PropsMenu {
    setMenuIsVisible(arg0: boolean): void;
}

export function Header({setMenuIsVisible}: PropsMenu) {
    const { t } = useTranslation();

    return (
        <>
            <Container>
                <UnLogged>
                    <Content className="unLogged">
                        <img src={logo} alt={t("companyName")}/>
                        <ContainerButtons>
                            <NavLink to="/">
                                {t("homePage")}
                            </NavLink>
                            <NavLink className="navLink" to="/games">
                                {t("ourGames")}
                            </NavLink>
                            <NavLink to="/powergaming">
                                {t("companyName")}
                            </NavLink>
                            <NavLink className="login" to="/login">
                                {t("login")}
                            </NavLink>
                            <NavLink className="register" to="/register">
                                {t("register")}
                            </NavLink>
                        </ContainerButtons>
                    </Content>
                </UnLogged>
            </Container>
            <MenuContentStyled>
                <LogoContainer>
                    <NavLink to="/">
                        <img src={logo} alt={t("companyName")}/>
                    </NavLink>
                </LogoContainer>
                <Separation>
                    <MenuIconStyled
                        onClick={() => setMenuIsVisible(true)}
                        src={menu}
                        width="24px"
                    />
                </Separation>
            </MenuContentStyled>
        </>
    );
}

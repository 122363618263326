import {ChangeEventHandler, FormEvent, useState} from 'react'
import {RegisterUser} from '../../hooks/useAuth'
import InputMask from 'react-input-mask'

import {
    RegisterContainer,
    H1Container,
    Wrapinput,
    Container,
    ContainerBorder,
    ContainerMargen,
    Button,
    ErrorMensageData,
} from './styles'
import {User} from '../../types'
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

function PhoneInput(props: {
    value: string | number | readonly string[] | undefined
    onChange: ChangeEventHandler<HTMLInputElement> | undefined
}) {
    const { t } = useTranslation();

    return (
        <InputMask
            className="inputMask"
            value={props.value}
            onChange={props.onChange}
            mask="(99) 99999-9999"
            placeholder={t("phone")}
        ></InputMask>
    )
}

export function Register() {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    async function signup(event: FormEvent) {
        event.preventDefault()

        const user: User = {
            firstName,
            lastName,
            email,
            username,
            phone,
            password,
            ln: i18n.language
        }

        setErrorMessage(await RegisterUser(user));
    }

    return (
        <ContainerMargen>
            <RegisterContainer>
                <ContainerBorder>
                    <H1Container>
                        {t("register")}
                    </H1Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={firstName !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("name")}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={lastName !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("lastName")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={email !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("email")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={username !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("username")}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <PhoneInput
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            ></PhoneInput>
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={password !== '' ? 'has-val input' : 'input'}
                                type="password"
                                placeholder={t("newPassword")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={confirmPassword !== '' ? 'has-val input' : 'input'}
                                type="password"
                                placeholder={t("confirmPassword")}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <Button disabled={password != confirmPassword} onClick={signup}>{t("register")}</Button>
                    <ErrorMensageData hidden={errorMessage === undefined}>{errorMessage}</ErrorMensageData>

                </ContainerBorder>
            </RegisterContainer>
        </ContainerMargen>
    )
}
export const theme = {
    COLORS: {
        primary: '#72B2CC',
        dourado: '#CC730A',
        lightBlue: '#8AC2F8',
        darkBlue: '#054B7B',
        blackBlue: '#032237',
        white: '#FFFFFF',
        linearLightBue: 'linear-gradient(129deg, #72B2CC 10.42%, #D1E9EF 21.85%, #66A9CC 55.84%, #B1ECFC 71.42%, #A3EFFE 80.89%, #1D8DC4 88.54%)',
        linearBackground: 'linear-gradient(129deg, #72B2CC 5.42%, #D1E9EF 10.85%, #66A9CC 40.84%, #B1ECFC 80.42%, #A3EFFE 80.89%, #1D8DC4 88.54%)',
        douradoLinear: 'linear-gradient(90deg, #CC730A 0%, #FBF4A1 50%, #C36F0C 100%)',
        shadow: 'rgba(30, 30, 30, 0.13)',
        red: 'rgb(255, 0, 0)',
        black: 'rgb(0, 0, 0)',
        green: 'rgb(7, 189, 77)',
        greenClear: 'rgb(134, 255, 160)',
        gray: '#858585',
        transparent: '#00800000',
        blur: '#000000a8',
    },

    SKULL: {
        brown: '#2C1A15',
        yellow: '#FFB800',
        gray: '#414141',
        red: 'rgb(255, 0, 0)',
        black: 'rgb(0, 0, 0)',
        white: '#FFFFFF',
        cell: '#35211c'
    },

    DOUBLE_JOKER: {
        background: 'linear-gradient(158deg, #670496 14.44%, #367B00 30.95%, #5E0A86 42.23%, #3C8800 66.93%, #000 79.42%)',
        purple: '#5E0A86',
        green: '#3C8800',
        blur: '#0000004a',
        content_background: '#312F2F',
        input_background: '#585858',
        error_text_color: '#ff0000',
        roulette_background: '#585858',
        white: '#FFFFFF',
        black: 'rgb(0, 0, 0)',
    },

    MINING_ROULETTE: {
        background: 'linear-gradient(158deg, #c0c0c0 14.44%, #ffd700 30.95%, #b9f2ff 42.23%, #b9f2ff 66.93%, #000 79.42%)',
        blur: '#0000004a',
        content_background: '#00000094',
        input_background: '#5b5b5bab',
        error_text_color: '#ff0000',
        roulette_background: '#5858589e',
        white: '#FFFFFF',
        black: 'rgb(0, 0, 0)',
        gold: '#ffd700',
        silver: '#c0c0c0',
        diamond: '#b9f2ff',
    },

    COLOR_ROULETTE: {
        background: 'linear-gradient(158deg, #c0c0c0 14.44%, #ffd700 30.95%, #b9f2ff 42.23%, #b9f2ff 66.93%, #000 79.42%)',
        blur: '#0000004a',
        content_background: '#00000094',
        input_background: '#5b5b5bab',
        error_text_color: '#ff0000',
        white: '#FFFFFF',
    },

    BLACKJACK_V1: {
        blur: '#000000a8',
        table_background: 'linear-gradient(148.17deg, #327001 26.69%, #4EA608 48.42%, #327001 66.57%)',
        table_tissue_border: 'rgba(112, 255, 0, 1)',
        black: 'rgb(0, 0, 0)',
        gray: 'rgba(65, 65, 65, 1)',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
    },

    BLACKJACK_V2: {
        blur: '#00000080',
        table_background: 'linear-gradient(148.17deg, rgba(112, 1, 1, 1) 26.69%, rgba(166, 8, 8, 1) 48.42%, rgba(112, 1, 1, 1) 66.57%)',
        table_tissue_border: 'rgba(68, 0, 0, 1)',
        black: 'rgb(0, 0, 0)',
        gray: 'rgba(65, 65, 65, 1)',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
    },

    DICE_V1: {
        red: '#A13027',
        green: '#70B936',
        black: 'rgb(0, 0, 0)',
        gray: '#414141',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
        blur: '#000000a8',
    },

    SLOT_MACHINE_777: {
        background: 'linear-gradient(180deg, #000 0%, #4B0082 10%, #FFD700 20%, #0B610B 30%, #1E90FF 40%, #FF1493 50%, #1E90FF 60%, #0B610B 70%, #FFD700 80%, #4B0082 90%, #000 100%)',
        machine_background: 'linear-gradient(180deg, rgb(6 20 7) 12.69%, rgba(13 67 0) 48.42%, rgba(6 20 7) 88.57%)',
        machine_aux_background: 'rgb(6 20 7)',
        grennBall: 'radial-gradient(circle, #39FF14, #0B610B)',
        purpleBall: 'radial-gradient(circle, #8A2BE2, #4B0082)',
        yellowBall: 'radial-gradient(circle, #FFFF00, #FFD700)',
        blueBall: 'radial-gradient(circle, #00BFFF, #1E90FF)',
        pinkBall: 'radial-gradient(circle, #FF69B4, #FF1493)',
        greenNeon: '#39FF14',
        gray: '#312F2F',
        beige: '#F5F5DC',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
        blur: '#000000a8',
    },

    SLOT_MACHINE_ZOO: {
        background: 'linear-gradient(158deg, #83BD2E 14.44%, #426D1D 30.95%, #142621 42.23%, #426D1D 66.93%, #83BD2E 79.42%)',
        backgroundMachine: '#142621b3',
        backgroundNumber: '#142621',
        colorNumber: '#83BD2E',
        backgroundLeft: '#83BD2E',
        backgroundRight: '#142621',
        backgroundMAchineResult: '#E0FEB4',
        gray: '#312F2F',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
        blur: '#000000a8',
    },

    CRASH_TRADE: {
        black: "#000000",
        gray: '#312F2F',
        white: '#FFFFFF',
        error_text_color: '#ff0000',
        blur: '#000000a8',
        button: '#04DC00'
    }

}




import {useState} from 'react'

import {Modal} from '../../components/Modal'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera, faEnvelope, faMapMarker} from '@fortawesome/free-solid-svg-icons';

import {
    Form,
    H1Container,
    ContactContainer,
    SendContainer,
    DataContContainer,
    Cont,
    Container,
    Description,
    FraseContainer,
    DescriptionFrase,
    Title,
    H2Container,
    DivisionContainer,
    Data,
    Wrapinput,
} from './styles'
import {useTranslation} from "react-i18next";
import {IEmail} from "../../types";
import i18n from "i18next";
import {sendMail} from "../../hooks/useEmail";


export const AboutUs = ({...props}) => {
    const { t } = useTranslation();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [tel, setTel] = useState('')
    const [lastName, setLastName] = useState('')
    const [loading, setLoading] = useState(false)


    async function sendEmail(e: { preventDefault: () => void }) {
        setLoading(true)
        if (name === '' || email === '' || message === '') {
            setLoading(false)
            alert(t("fillAllFields"))
            return
        }

        const emailReq: IEmail = {
            name,
            lastName,
            email,
            phone: tel,
            message,
            ln: i18n.language
        }

        const responseStatus = await sendMail(emailReq);

        if (responseStatus == "200") {
            setIsModalVisible(true);
            setLoading(false)
        } else {
            alert(t("emailNotSendInternalError"))
            setLoading(false)
            return
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false)

    return (
        <Container style={{ cursor: loading ? "wait" : "default"}}>
            <ContactContainer>
                <FraseContainer>
                    {t("companyName")}
                </FraseContainer>
                <Description>
                    {t("aboutUsDescription")}
                </Description>
                <Title>
                    {t("aboutUsWhoIsTheCompany")}
                </Title>
                <DescriptionFrase>
                    {t("aboutUsDescription1")}
                </DescriptionFrase>
                <Description>
                    {t("aboutUsAnInnovativePartner")}
                </Description>
                <H1Container>
                    {t("aboutUsPersonalizedServiceOrDevelopment")}
                </H1Container>
                <H2Container>
                    {t("aboutUsContactUs")}
                </H2Container>
                <DivisionContainer>
                    <Form>
                        <Wrapinput>
                            <input
                                className={name !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("name")}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Wrapinput>
                        <Wrapinput>
                            <input
                                className={lastName !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("lastName")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Wrapinput>
                        <Wrapinput>
                            <input
                                className={email !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("email")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Wrapinput>
                        <Wrapinput>
                            <input
                                className={lastName !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("phone")}
                                value={tel}
                                onChange={(e) => setTel(e.target.value)}
                            />
                        </Wrapinput>
                        <Wrapinput>
                            <textarea
                                className={lastName !== '' ? 'has-val input' : 'input'}
                                placeholder={t("message")}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Wrapinput>
                        <SendContainer>
                            <button onClick={sendEmail} type="submit">
                                Entre em contato
                            </button>
                        </SendContainer>
                    </Form>
                    <Cont>
                        <DataContContainer>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <Data>
                                {t("companyEmail")}
                            </Data>
                        </DataContContainer>
                        <DataContContainer>
                            <FontAwesomeIcon icon={faCamera}/>
                            <Data>
                                {t("companyInstagram")}
                            </Data>
                        </DataContContainer>

                        <DataContContainer>
                            <FontAwesomeIcon icon={faMapMarker}/>
                            <Data>
                                {t("companyAddress")}
                            </Data>
                        </DataContContainer>
                    </Cont>
                </DivisionContainer>
            </ContactContainer>
            {isModalVisible && (
                <Modal onClose={() => setIsModalVisible(false)}></Modal>
            )}
        </Container>
    )
}
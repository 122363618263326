import {AUTH_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {setAuthToken} from '../services/setAuthToken'
import {createContext, ReactNode, useContext} from 'react'
import {AuthContextData, AuthProviderProps, LoginRequest, User} from '../types'

const token = localStorage.getItem('token')

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)
import i18n from "../i18n"

export function AuthProvider({children}: AuthProviderProps) {
    async function AuthenticateUser(user: String, pass: String) {
        event?.preventDefault()
        const loginRequest: LoginRequest = {
            username: user,
            password: pass,
            ln: i18n.language
        }
        let msg = ''

        await api
            .post(
                AUTH_CONFIG.authenticateUser.path +
                AUTH_CONFIG.authenticateUser.endpoint,
                loginRequest,
            )
            .then((response) => {
                const token = response.data.token
                const username = response.data.username
                localStorage.setItem('token', token)
                localStorage.setItem('username', username)
                setAuthToken(token)
            })
            .catch((err) => (msg = err.response.data.message))

        if (msg != "") {
            return msg;
        } else {
            window.location.href = '/'
        }
    }

    return (
        <AuthContext.Provider value={{signed: Boolean(token), AuthenticateUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext)
    return context
}

export async function RegisterUser(user: User) {
    event?.preventDefault()
    let msg = ''
    let msgStatus = ''

    await api
        .post(
            AUTH_CONFIG.registerUser.path + AUTH_CONFIG.registerUser.endpoint,
            user,
        )
        .then((response) => {
            msg = response.data.message;
            msgStatus = response.status.toString()
        })
        .catch((err) => {
            msg = err.response.data.message;
            msgStatus = err.response.status
        })

    if (msgStatus == "200") {
        window.location.href = '/login'
    } else {
        return msg;
    }
}

export async function ForgotPassword(email: string, ln: string) {
    let msg = ''
    await api
        .post(
            AUTH_CONFIG.forgotPassword.path + AUTH_CONFIG.forgotPassword.endpoint,
            {email, ln},
        )
        .then((response) => (msg = response.data))
        .catch((err) => (msg = err.response.data))

    return msg
}

export async function GetResetPassword(token: string) {
    let msg = ''
    let msgStatus = ''

    await api
        .get(
            AUTH_CONFIG.getResetPassword.path +
            AUTH_CONFIG.getResetPassword.endpoint +
            '?token=' + token +
            "&ln=" + i18n.language,
        )
        .then((response) => {
            const token = response.data.token
            const username = response.data.username
            localStorage.setItem('resetPasswordToken', token)
            msg = username
            msgStatus = response.status.toString()
        })
        .catch((err) => {
            msg = err.response.data
            msgStatus = err.response.status
        })

    return {msg, msgStatus}
}

export async function PostResetPassword(password: string) {
    let msg = ''
    await api
        .post(
            AUTH_CONFIG.postResetPassword.path + AUTH_CONFIG.postResetPassword.endpoint,
            {token: localStorage.getItem('resetPasswordToken'), password, ln: i18n.language},
        )
        .then((response) => {
            localStorage.removeItem('resetPasswordToken')
            msg = response.data
        })
        .catch((err) => {
            msg = err.response.data
        })
    return msg;
}

export async function GetCasinoAccess(casino: string, game: string, session: string) {
    let access = false;
    await api
        .get(
            AUTH_CONFIG.getCasinoAccess.path + AUTH_CONFIG.getCasinoAccess.endpoint + "?casino=" + casino + "&game=" + game + "&session=" + session
        )
        .then((response) => {
            access = response.data
        })
        .catch()
    return access;
}
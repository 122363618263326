import {CRASH_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {ICrashBetResponse, ICrashBets, ICrashCanBet, ICrashLastResults} from '../types'

export async function GetLastTwentyResults(session: string) {
    let lastTwentyResults: ICrashLastResults = {
        results: [],
        isValid: false
    };
    await api
        .get(
            CRASH_CONFIG.getLastTwentyResults.path + CRASH_CONFIG.getLastTwentyResults.endpoint + "?session=" + session
        )
        .then((response) => {
            lastTwentyResults = response.data;
        })
        .catch()
    return lastTwentyResults
}

export async function GetRoundAllBets(session: string) {
    let roundBlackBets: ICrashBets = {
        amount: 0,
        roundCrashBets: [],
        isValid: false
    };
    await api
        .get(
            CRASH_CONFIG.getRoundBets.path + CRASH_CONFIG.getRoundBets.endpoint + "?session=" + session
        )
        .then((response) => {
            roundBlackBets = response.data;
        })
        .catch()
    return roundBlackBets
}


export async function GetPlayerBet(session: string, playerId: string) {
    let bet: ICrashBetResponse = {
        amountBet: 0,
        isBet: false,
        cashOut: false,
        betId: 0
    };
    await api
        .get(
            CRASH_CONFIG.getRoundBet.path + CRASH_CONFIG.getRoundBet.endpoint + "?session=" + session + "&playerId=" + playerId
        )
        .then((response) => {
            bet = response.data;
        })
        .catch()
    return bet
}

export async function GetCanBet(session: string) {
    let canBetResponse: ICrashCanBet = {
        canBet: false,
        isValid: false
    };
    await api
        .get(
            CRASH_CONFIG.getCanBet.path + CRASH_CONFIG.getCanBet.endpoint + "?session=" + session
        )
        .then((response) => {
            canBetResponse = response.data;
        })
        .catch()
    return canBetResponse
}
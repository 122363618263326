import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url('/backgroundColorRoulette.jpg');
    background-size: cover;
    padding: 3rem 0rem 4rem 0rem;
    height: 100vh;
    gap: 1rem;
    @media (max-width: 850px) {
        padding: 1.5rem 0rem 2rem 0rem;
    }

    @media (max-width: 750px) {
        padding: 1rem 0rem 1.5rem 0rem;
    }

    @media (max-width: 550px) {
        padding: 0;
    }
`

export const InfoMessage = styled.text`
    color: ${(props) => props.theme.COLOR_ROULETTE.white};;
    font-family: Orbitron;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    height: 5%;
`

export const BetBox = styled.div`
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    width: 90%;
    height: 40%;
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.COLOR_ROULETTE.content_background};
    border-radius: 1.25rem;
    justify-content: center;
    border: 4px solid ${(props) => props.theme.COLOR_ROULETTE.black};
    
    @media (max-width: 800px) {
        height: auto;
        padding: 2rem 0 2rem 0;
        gap: 1rem;
        width: 98%;
    }
`

export const ResultBox = styled.div`
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    width: 95%;
    height: 60%;
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.COLOR_ROULETTE.content_background};
    border-radius: 1.25rem;
    justify-content: center;
    gap: 1rem;
    border: 4px solid ${(props) => props.theme.COLOR_ROULETTE.black};
    @media (max-width: 800px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 2rem 0 2rem 0;
        width: 98%;
    }
`

export const SelectColorsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    @media (max-width: 800px) {
        height: auto;
        gap: 2rem;
    }
`

export const BetColorsContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.7rem;
    height: 80%;
    @media (max-width: 800px) {
        height: auto;
        gap: 0.5rem;
    }
`

export const BetMesageDiv = styled.div`
    height: 20%;
`

export const BetMesage = styled.text`
    color: ${(props) => props.theme.COLOR_ROULETTE.white};;
    font-family: Orbitron;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 800px) {
        font-size: 1.5rem;
    }
`

export const CollorButton = styled.button`
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 800px) {
        width: 3rem;
        height: 3rem;
    }
`

export const ButtonCardCircle = styled.div`
    border-radius: 10rem;
    cursor: pointer;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.COLOR_ROULETTE.blur};
    font-size: 1.6rem;
    color: ${(props) => props.theme.COLOR_ROULETTE.white};
    
    @media (max-width: 800px) {
        font-size: 0.8rem;
        width: 2rem;
        height: 2rem;
    }

`

export const BetValueContent = styled.div`
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 40%;

    @media (max-width: 800px) {
        height: 30%;
    }

`

export const BetValueInputContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    height: 80%;
`

export const ErrorMensageData = styled.div`
    padding: 5px;
    color: ${(props) => props.theme.COLOR_ROULETTE.error_text_color};
    height: 5%;
`

export const BetValueInput = styled.input`
    border-radius: 0.7rem;
    height: 100%;
    background: ${(props) => props.theme.COLOR_ROULETTE.input_background};
    color: ${(props) => props.theme.COLOR_ROULETTE.white};
    text-align: center;
    font-family: Orbitron;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;
    width: 70%;
    @media (max-width: 800px) {
        font-size: 1rem;
        width: 50%;
        height: 3rem;
    }
    @media (max-width: 500px) {
        width: 40%;
    }
`

export const BetValueChangeButton = styled.button`
    width: 20%;
    height: 100%;
    border-radius: 0.7rem;
    background: ${(props) => props.theme.COLOR_ROULETTE.input_background};
    color: ${(props) => props.theme.COLOR_ROULETTE.white};;
    font-family: Orbitron;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 800px) {
        width: auto;
        font-size: 1.2rem;
        height: 3rem;
    }
`

export const BetButton = styled.button`
    background: ${(props) => props.theme.COLOR_ROULETTE.input_background};
    display: inline-flex;
    height: 100%;
    width: 20%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.625rem;
    color: ${(props) => props.theme.COLOR_ROULETTE.white};
    font-family: Orbitron;
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 800px) {
        width: auto;
        font-size: 1rem;
        height: 3rem;
    }
`

export const LastResultsContent = styled.ul`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow: hidden;
`

export const LastResultsComponent = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 1rem;
    height: 30%;

    @media (max-width: 800px) {
        height: auto;
        padding: 1rem 0 1rem 0;
    }
`

export const LastResult = styled.li`
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 5px 10px;

    @media (max-width: 800px) {
        margin: 5px;
    }
`

export const RouletteResult = styled.div`
    width: 6rem;
    height: 8rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
`

export const LastResultData = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;

    @media (max-width: 800px) {
        width: 3rem;
        height: 3rem;
    }
`

export const ResultsRoulette = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 1.25rem;
    width: 100%;
    height: 45%;
    overflow: hidden;
    border: 3px solid ${(props) => props.theme.COLOR_ROULETTE.black};

    @media (max-width: 800px) {
        height: auto;
        padding: 2rem;
        gap: 1rem;
    }
`

export const Roulette = styled.div`
    display: flex;
`

export const RouletteNumbers = styled.div`
    transition-duration: 1000ms;
    transition-delay: 0ms;
    display: flex;
    position: relative;
`

export const RouletteCard = styled.li`
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 0.5rem;
`

export const Selector = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    width: 4px;
    height: 100%;
    background-color: ${(props) => props.theme.COLOR_ROULETTE.white};
    z-index: 1;
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
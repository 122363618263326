// Double
export const DOUBLE_CONFIG = {
    getLastResult: {
        endpoint: '/getLastResult',
        method: 'get',
        path: 'double',
    },
    getLastResults: {
        endpoint: '/getLastResults',
        method: 'get',
        path: 'double',
    },
    getRoundGreenBets: {
        endpoint: '/getRoundGreenBets',
        method: 'get',
        path: 'double',
    },
    getRoundPurpleBets: {
        endpoint: '/getRoundPurpleBets',
        method: 'get',
        path: 'double',
    },
    getRoundJokerBets: {
        endpoint: '/getRoundJokerBets',
        method: 'get',
        path: 'double',
    }
}

export const DOUBLE_V2_CONFIG = {
    getLastResult: {
        endpoint: '/getLastV2Result',
        method: 'get',
        path: 'doubleV2',
    },
    getLastResults: {
        endpoint: '/getLastV2Results',
        method: 'get',
        path: 'doubleV2',
    },
    getRoundSilverBets: {
        endpoint: '/getRoundSilverBets',
        method: 'get',
        path: 'doubleV2',
    },
    getRoundGoldBets: {
        endpoint: '/getRoundGoldBets',
        method: 'get',
        path: 'doubleV2',
    },
    getRoundDiamondBets: {
        endpoint: '/getRoundDiamondBets',
        method: 'get',
        path: 'doubleV2',
    }
}

export const DOUBLE_V3_CONFIG = {
    bet: {
        endpoint: '/bet',
        method: 'post',
        path: 'doubleV3',
    }
}

// Crash
export const CRASH_CONFIG = {
    getLastTwentyResults: {
        endpoint: '/getLastTwentyResults',
        method: 'get',
        path: 'crash',
    },
    getRoundBets: {
        endpoint: '/getRoundBets',
        method: 'get',
        path: 'crash',
    },
    getRoundBet: {
        endpoint: '/getRoundBet',
        method: 'get',
        path: 'crash',
    },
    getCanBet: {
        endpoint: '/getCanBet',
        method: 'get',
        path: 'crash',
    }
}

// Dice
export const DICE_CONFIG = {
    bet: {
        endpoint: '/bet',
        method: 'post',
        path: 'dice',
    },
}

// Session
export const SESSION_CONFIG = {
    getSessionInfos: {
        endpoint: '/getSessionInfos',
        method: 'get',
        path: 'session',
    },
    getGameEcommerce: {
        endpoint: '/getGameEcommerce',
        method: 'get',
        path: 'session',
    },
}


// Auth
export const AUTH_CONFIG = {
    registerUser: {
        endpoint: '/signup',
        method: 'post',
        path: 'auth',
    },
    authenticateUser: {
        endpoint: '/signin',
        method: 'post',
        path: 'auth',
    },
    forgotPassword: {
        endpoint: '/forgot_password',
        method: 'post',
        path: 'auth',
    },
    getResetPassword: {
        endpoint: '/reset_password',
        method: 'get',
        path: 'auth',
    },
    postResetPassword: {
        endpoint: '/reset_password',
        method: 'post',
        path: 'auth',
    },
    getCasinoAccess: {
        endpoint: '/getCasinoAccess',
        method: 'post',
        path: 'session',
    },
}


//Ecommerce
export const ECOMMERCE_CONFIG = {
    getProductsAll: {
        endpoint: '/product/all',
        method: 'get',
        path: '/ecommerce',
    },
    getProducts: {
        endpoint: '/product/get',
        method: 'get',
        path: '/ecommerce',
    },
}

//Email
export const EMAIL_CONFIG = {
    sendMail: {
        endpoint: '/sendEmailAboutUs',
        method: 'post',
        path: '/email',
    },
}

//WebSocket
export const URLs = {
    backUrl: "https://infinitypowergames.com",
    wsDoubleV1: "/double-ws",
    wsDoubleV2: "/doubleV2-ws",
    wsSlotMachineV1: "/slotmachine-ws",
    wsSlotMachineV2: "/slotmachineV2-ws",
    wsBlackjackV1: "/blackjack-ws",
    wsBlackjackV2: "/blackjackV2-ws",
    wsMinesV1: "/mines-ws",
    wsCrashV1: "/crash-ws",
}

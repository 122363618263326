import {DOUBLE_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {IDoubleBets, IDoubleResult, IDoubleResults, IDoubleV2Result, IDoubleV2Results} from '../types';

export async function GetLastResult(session: string) {
    let lastResult: IDoubleResult = {
        result: 0,
        color: "",
        isValid: false
    };
    await api
        .get(
            DOUBLE_CONFIG.getLastResult.path + DOUBLE_CONFIG.getLastResult.endpoint + "?session=" + session
        )
        .then((response) => {
            lastResult = response.data;
        })
        .catch()
    return lastResult
}

export async function GetLastResults(session: string) {
    let lastResults: IDoubleResults = {
        results: [],
        isValid: false
    };
    await api
        .get(
            DOUBLE_CONFIG.getLastResults.path + DOUBLE_CONFIG.getLastResults.endpoint + "?session=" + session
        )
        .then((response) => {
            lastResults = response.data;
        })
        .catch()
    return lastResults
}

export async function GetRoundGreenBets(session: string) {
    let roundGreenBets: IDoubleBets = {
        amount: 0,
        roundDoubleBets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_CONFIG.getRoundGreenBets.path + DOUBLE_CONFIG.getRoundGreenBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleBets; }) => {
            roundGreenBets = response.data;
        })
        .catch()
    return roundGreenBets
}

export async function GetRoundPurpleBets(session: string) {
    let roundPurpleBets: IDoubleBets = {
        amount: 0,
        roundDoubleBets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_CONFIG.getRoundPurpleBets.path + DOUBLE_CONFIG.getRoundPurpleBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleBets; }) => {
            roundPurpleBets = response.data;
        })
        .catch()
    return roundPurpleBets
}

export async function GetRoundJokerBets(session: string) {
    let roundJokerBets: IDoubleBets = {
        amount: 0,
        roundDoubleBets: [{
            user: "",
            amountBet: 0,
            playerName: ""
        }],
        isValid: false
    };
    await api
        .get(
            DOUBLE_CONFIG.getRoundJokerBets.path + DOUBLE_CONFIG.getRoundJokerBets.endpoint + "?session=" + session
        )
        .then((response: { data: IDoubleBets; }) => {
            roundJokerBets = response.data;
        })
        .catch()
    return roundJokerBets
}

import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    height: 100%;

    @media (max-width: 750px) {
        margin: 1rem;
        margin-top: 7rem;
    }
`

export const Containerlogin = styled.div`
    display: flex;
    font-family: 'Poppins';
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    border: 1px solid ${(props) => props.theme.COLORS.blackBlue};
    border-radius: 50px;
    padding: 3rem;
    background: ${(props) => props.theme.COLORS.shadow};

    @media (max-width: 750px) {
        border-radius: 25px;
        padding: 1rem;
        gap: 1rem;
    }
`

export const Wraplogin = styled.div``

export const Loginform = styled.form`
    width: 30rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 750px) {
        width: 22rem;
    }
`

export const Logintitle = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.blackBlue};
    margin-bottom: 2rem;

    @media (max-width: 750px) {
        line-height: 3rem;
        font-size: 2rem;
    }
`

export const Wrapinput = styled.div`
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;

    input {
        all: unset;
        width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;

        @media (max-width: 750px) {
            line-height: 2rem;
            font-size: 1.2rem;
        }
    }
`

export const Containerbtn = styled.button`
    font-size: 1rem;
    border: none;
    border-radius: 15px;
    line-height: 1.2;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 64px;
    background: ${(props) => props.theme.COLORS.blackBlue};
    border: 1px solid ${(props) => props.theme.COLORS.white};
    text-align: center;
    color: ${(props) => props.theme.COLORS.white};
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 750px) {
        width: 260px;
        height: 50px;
    }
`

export const Textcenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .navLink {
        all: unset;
        font-style: italic;
        border: 0;
        padding: 0 2rem;
        border-radius: 0.25rem;
        height: 3rem;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.7);
        }

        &.active {
            text-decoration: underline;
        }
    }
`

export const ErrorMensageData = styled.div`
    font-size: 23px;
    padding: 0.5rem;
    color: ${(props) => props.theme.COLORS.red};
`
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: 750px) {
        margin-top: 3rem;
    }

`

export const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.COLORS.blackBlue};
    border-radius: 50px;
    padding: 3rem;
    background: ${(props) => props.theme.COLORS.shadow};
    width: 50%;

    @media (max-width: 950px) {
        padding: 1rem;
        width: 95%;
        height: 450px;
        margin-top: 2rem;

    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    @media (max-width: 750px) {
        gap: 0rem;
    }

    h3 {
        color: white;
        text-align: center;
    }

    button {
        font-size: 1rem;
        border: 1px solid;
        border-radius: 25px;
        line-height: 1.2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 450px;
        height: 64px;
        color: ${(props) => props.theme.COLORS.white};
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: ${(props) => props.theme.COLORS.blackBlue};

        &:hover {
            filter: brightness(0.8);
        }

        &.active {
            text-decoration: underline;
        }

        @media (max-width: 750px) {
            width: 280px;
            height: 50px;
            font-size: 20px;
            margin-top: 2rem;
        }
    }
`


export const H1Container = styled.div`
    text-align: center;
    font-family: 'Poppins';
    font-style: italic;
    line-height: 4rem;
    font-size: 2.3rem;
    color: ${(props) => props.theme.COLORS.blackBlue};

    @media (max-width: 750px) {
        line-height: 3rem;
        font-size: 2rem;
    }
`

export const Wrapinput = styled.div`
    width: 90%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 2rem;

    @media (max-width: 750px) {
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.6rem;
    }

    input {
        all: unset;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;
        width: 80%;
        padding-left: 1rem;

        @media (max-width: 750px) {
            font-size: 1rem;
            line-height: 1.8rem;
            padding-left: 0.5rem;
        }
    }

    img {
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
    }
`
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;

    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
    }
`

export const Tittle = styled.div`
    color: ${(props) => props.theme.COLORS.darkBlue};
    text-align: center;
    font-family: Roboto Mono;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin-top: 50px;

    @media (max-width: 750px) {
        font-size: 28px;
        margin-top: 20px;
    }
`
export const ContainerButton = styled.button`
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.blackBlue};
    background-color: transparent;
    border: none;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 750px) {
        font-size: 16px;

        img {
            width: 22px;
        }

    }

`

export const ContainerDetails = styled.div`
    display: flex;
    gap: 5rem;
    align-items: center;
    flex-direction: row;
    width: 80%;

    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        gap: 0.3rem;
    }

    @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
    }
`

export const ContainerDetailsMobile = styled.div`
    display: flex;
    gap: 5rem;
    align-items: center;
    flex-direction: column;
    width: 80%;
    border-bottom: solid 1px ${(props) => props.theme.COLORS.blackBlue};
    
    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
    }
`

export const Description = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
`


export const ContainerTitle = styled.nav`
    color: ${(props) => props.theme.COLORS.blackBlue};
    font-family: 'Poppins';
    font-style: italic;
    font-size: 24px;
    line-height: 36px;

    cursor: pointer;

    img {
        margin-left: 1rem;
    }

    @media (max-width: 750px) {
        font-size: 1.3rem;
        line-height: 2rem;
    }
`

export const ContainerText = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    font-family: 'Poppins';
    font-style: italic;
    font-size: 20px;
    line-height: 30px;
    padding-left: 1rem;

    @media (max-width: 750px) {
        font-size: 1rem;
        line-height: 2rem;
        padding-left: 0.5rem;
    }
`

export const ContainerImg = styled.img`
    width: 500px;
    border-radius: 10px;

    @media (max-width: 950px) {
        width: 400px;
    }

    @media (max-width: 850px) {
        width: 300px;
    }

    //@media (max-width: 640px) {
    //    width: 250px;
    //}
    //
    //@media (max-width: 530px) {
    //    width: 200px;
    //}
    //
    //@media (max-width: 450px) {
    //    width: 150px;
    //}
`
import {EMAIL_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {IEmail} from '../types';

export async function sendMail(email: IEmail) {
    let msgStatus = ""
    await api
        .post(
            EMAIL_CONFIG.sendMail.path + EMAIL_CONFIG.sendMail.endpoint,
            email
        )
        .then((response) => {
            msgStatus = response.status.toString()
        })
        .catch((err) => {
            msgStatus = err.response.status
        })
    return msgStatus
}
import styled from 'styled-components'

interface ProgressBarProps {
    progress: number;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-image: url('/backgroundDice.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: ${(props) => props.theme.DICE_V1.black};
    height: 100vh;
    flex-direction: column;
    align-items: center;
`

export const GameContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 65%;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    @media (max-width: 800px) {
        height: auto;
    }
`

export const Blurn = styled.div`
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.SLOT_MACHINE_777.blur};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
`

export const Infos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.DICE_V1.white};
`

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    color: ${(props) => props.theme.DICE_V1.white};
    justify-content: center;
    height: 35%;
    gap: 1rem;
    @media (max-width: 800px) {
        height: auto;
    }
`

export const MensageData = styled.text`
`

export const PercentageValue = styled.text`
    position: relative;
    top: -1.3rem;
`

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 5rem;
    flex-wrap: wrap;
    justify-content: center;
`

export const ButtonDice = styled.input`
    border-radius: 15px;
    width: 8rem;
    height: 2.4rem;
    background: ${(props) => props.theme.DICE_V1.gray};
    color: ${(props) => props.theme.DICE_V1.white};
`

export const ButtonContainer = styled.div`
    width: 90%;
    border-radius: 5px;
    height: 2.4rem;
    background: ${(props) => props.theme.DICE_V1.gray};
    color: ${(props) => props.theme.DICE_V1.white};
    align-items: center;
    display: flex;
    justify-content: center;
`


export const ButtonBet = styled.button`
    border-radius: 10px;
    width: 8rem;
    height: 2.5rem;
    background: ${(props) => props.theme.DICE_V1.green};
    color: ${(props) => props.theme.DICE_V1.white};
    align-items: center;
    display: flex;
`


export const ButtonMusic = styled.button`
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`

export const Mensage = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 2.4rem;
    background: ${(props) => props.theme.DICE_V1.gray};
    color: ${(props) => props.theme.DICE_V1.white};
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
`

export const PercentageDiv = styled.div`
    width: 1px;
    display: flex;
    flex-direction: row;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 0.9rem;
    background: ${(props) => props.theme.DICE_V1.white};
    color: ${(props) => props.theme.DICE_V1.white};
    border-radius: 10px;
    justify-content: center;
    align-items: center;
`

export const LastResults = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`

export const Percentages = styled.ul`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

export const Separation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`

export const Imagens = styled.img`
    width: 50px;
    height: 50px;

    @media (max-width: 750px) {
        width: 30px;
        height: 30px;
    }
`

export const ProgressBarContainer = styled.div`
    align-items: center;
    justify-content: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

export const LastResultsContainer = styled.div`
    width: 7rem;
    height: 5rem;
    color: ${(props) => props.theme.DICE_V1.white};
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 800px) {
        height: auto;
        width: auto;
        gap: 0.5rem;
    }
`

export const ProgressBarSelector = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${(props) => props.theme.DICE_V1.white};
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: calc(100% - 20px);
    transition: left 0.2s ease-in-out;
    z-index: 1;
    overflow: hidden;
    border: 1px solid black;
`

export const ProgressBarLeft = styled.div<ProgressBarProps>`
    width: ${(props) => props.progress}%;
    height: 100%;
    background-color: ${(props) => props.theme.DICE_V1.green};
    position: relative;
    border-radius: 0.3rem;
`

export const ProgressBarRight = styled.div`
    width: 100%;
    height: 20px;
    background-color: ${(props) => props.theme.DICE_V1.red};
    position: relative;
    cursor: pointer;
    border-radius: 0.3rem;
`

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
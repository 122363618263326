import {
    ModalContainer,
    PopupContainer,
    BaseContainerText,
    ContainerText,
    Text,
    Container,
} from './styles'
import {useTranslation} from "react-i18next";

export const Modal = ({ onClose = () => {}}) => {
    const { t } = useTranslation();

    return (
        <>
            <Container onClick={onClose}></Container>
            <ModalContainer>
                <PopupContainer>
                    <Text>
                        <BaseContainerText>
                            {t("modalFirstParagraph")}
                        </BaseContainerText>
                        <ContainerText>
                            {t("modalSecondParagraph")}
                        </ContainerText>
                        <BaseContainerText>
                            {t("modalThirdParagraph")}
                        </BaseContainerText>
                    </Text>
                </PopupContainer>
            </ModalContainer>
        </>
    )
}

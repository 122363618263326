import styled from 'styled-components'

export const Container = styled.header`
    max-height: 15rem;

    @media (max-width: 750px) {
        display: none;
        .mobile {
            display: initial;
        }
    }
`

export const Logged = styled.div`
    background: ${(props) => props.theme.COLORS.linearLightBue};
`

export const UnLogged = styled.div`
    background: ${(props) => props.theme.COLORS.linearLightBue};
`

export const Content = styled.div`
    max-width: 100rem;
    max-height: 8rem;
    margin: 0 auto;
    padding: 2rem 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 8%;
    }

    @media (max-width: 1160px) {
        img {
            width: 12%;

        }
    }

    @media (max-width: 760px) {
        img {
            width: 16%;

        }
    }
`

export const ContainerButtons = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    a {
        all: unset;
        align-items: center;
        display: flex;
        font-size: 1.5rem;
        color: ${(props) => props.theme.COLORS.darkBlue};
        border: 0;
        padding: 0 2rem;
        height: 3rem;
        cursor: pointer;
        transition: filter 0.2s;
        font-family: Roboto Mono;

        &:hover {
            filter: brightness(0.7);
        }

        &.active {
            text-decoration: underline;
        }
    }

    .login {
        box-sizing: border-box;
        position: relative;
        border: 2px solid ${(props) => props.theme.COLORS.white};
        border-radius: 20px;
    }

    .register {
        box-sizing: border-box;
        position: relative;
        border: 2px solid ${(props) => props.theme.COLORS.white};
        border-radius: 20px;
        color: ${(props) => props.theme.COLORS.white};
        background-color: ${(props) => props.theme.COLORS.darkBlue};
        margin-left: 1rem;
    }
`

export const MenuContentStyled = styled.div`
    display: none;
    @media (max-width: 750px) {
        background-color: ${(props) => props.theme.COLORS.linearLightBue};
        z-index: 999;
        width: 100%;
        padding: 0.8rem;
        overflow: hidden;
        height: 6rem;
        display: flex;
        align-items: center;
        position: absolute;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
`

export const MenuIconStyled = styled.img`
    all: unset;
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
`

export const Separation = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
`

export const LogoContainer = styled.div`
    cursor: pointer;
    width: 270px;
    display: flex;
    align-self: center;
    margin-left: 1rem;
    height: 80%;

    @media (max-width: 750px) {
        margin-left: 3rem;
        img {
            width: 23%;
        }
    }

    @media (max-width: 680px) {
        margin-left: 3rem;
        img {
            width: 25%;
        }
    }

    @media (max-width: 580px) {
        img {
            width: 28%;
        }
    }

    @media (max-width: 450px) {
        img {
            width: 33%;
        }
    }

    @media (max-width: 380px) {
        img {
            width: 35%;
        }
    }
`

import {useEffect, useState} from 'react'
import {
    GetLastResult,
    GetLastResults,
    GetRoundGreenBets,
    GetRoundPurpleBets,
    GetRoundJokerBets
} from '../../../hooks/useDouble';
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../../hooks/useSessionInfo';
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {IDoubleBets, IDoubleResult, IDoubleResults, ISessionInfos} from '../../../types';


import {
    Bets,
    BetBox,
    ResultBox,
    Container,
    BetMesage,
    ButtonPurple,
    CardCircle,
    ButtonGreen,
    BetColorsContent,
    ButtonJoker,
    BetValueContent,
    BetValueInputContent,
    ErrorMensageData,
    BetValueInput,
    BetValueChangeButton,
    BetButton,
    GameContent,
    BetsContent,
    SessionInfos,
    InfoMessage,
    LastResultsContent,
    LastResult,
    LastResultData,
    ResultsRoulette,
    LastResultsMesage,
    RouletteMesage,
    PurpleResult,
    JokerResult,
    GreenResult,
    BetsAmount,
    BetsAmountText,
    BetsAmountResult,
    PlayerBets,
    PlayerBetsResult,
    PlayerBetsContent,
    SleepProgress,
    Roulette,
    RouletteCard,
    RouletteTimer,
    RouletteNumbers,
    ProgressBar,
    RouletteResult,
    RouletteResultCircle,
    LastResultsComponent,
    ButtonCardCircle,
    Selector,
    ButtonMusic, SoundImage, ErrorMessageData, ContainerInvalid
} from './styles';
import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import {useTranslation} from "react-i18next";
import {GetCasinoAccess} from "../../../hooks/useAuth";
import {URLs} from "../../../services/service.config";

const DoubleV1Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [blockBet, setBlockBet] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [lastResult, setLastResult] = useState<IDoubleResult>({
        result: 0,
        color: "",
        isValid: true
    });
    const [sleep, setSleep] = useState(0);
    const [rolling, setRolling] = useState(0);
    const [focusing, setFocusing] = useState(true);
    const [lastResults, setLastResults] = useState<IDoubleResults>({
        results: [],
        isValid: true
    });
    const [greenBets, setGreenBets] = useState<IDoubleBets>({
        amount: 0,
        roundDoubleBets: [],
        isValid: true
    });
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [purpleBets, setPurpleBets] = useState<IDoubleBets>({
        amount: 0,
        roundDoubleBets: [],
        isValid: true
    });
    const [jokerBets, setJokerBets] = useState<IDoubleBets>({
        amount: 0,
        roundDoubleBets: [],
        isValid: true
    });
    const [userData, setUserData] = useState({
        result: '',
        betValue: 0,
        colorSelected: ''
    });
    const [isResponsive, setIsResponsive] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess();
        checkResponsive()
        window.addEventListener('resize', checkResponsive)
        return () => {
            window.removeEventListener('resize', checkResponsive)
        }
    }, [])

    const checkResponsive = () => {
        const isResponsiveSize = window.innerWidth <= 1250
        setIsResponsive(isResponsiveSize)
    }

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Double Joker", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsDoubleV1)))
        FindSessionInfos();
        FindLastResult();
        FindTwentyResults();
        FindRoundGreenBets();
        FindRoundPurpleBets();
        FindRoundJokerBets();
    }

    async function FindLastResult() {
        setLastResult(await GetLastResult(session!))
    }

    async function FindTwentyResults() {
        setLastResults(await GetLastResults(session!))
    }

    async function FindRoundGreenBets() {
        setGreenBets(await GetRoundGreenBets(session!))
    }

    async function FindRoundPurpleBets() {
        setPurpleBets(await GetRoundPurpleBets(session!))
    }

    async function FindRoundJokerBets() {
        setJokerBets(await GetRoundJokerBets(session!))
    }

    async function FindSessionInfos() {
        setSessionInfos(await GetSessionInfos(session!))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError)
    }

    const onConnected = () => {
        stompClient.subscribe('/user/' + casino + '/doubleResults/public', onLastResultReceived);
        stompClient.subscribe('/user/' + casino + '/lastTwentyDoubleResults/public', onLastResultsReceived);
        stompClient.subscribe('/user/' + casino + '/greenBets/public', onGreenBetReceived);
        stompClient.subscribe('/user/' + casino + '/purpleBets/public', onPurpleBetReceived);
        stompClient.subscribe('/user/' + casino + '/jokerBets/public', onJokerBetReceived);
        stompClient.subscribe('/user/' + casino + '/blockDoubleBet/public', onBlockBetReceived);
        stompClient.subscribe('/user/' + casino + '/doubleSleep/public', onSleepReceived);
        stompClient.subscribe('/user/' + casino + '/doubleRolling/public', onRollingReceived);
        stompClient.subscribe('/user/' + casino + '/focusingDouble/public', onFocusingReceived);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-double', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/doubleSessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidDouble', onIsValidMessage);
    }

    const onError = (err: any) => {
    }

    const onLastResultReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setLastResult(payloadData);
        setShowResult(true);
    }

    const onLastResultsReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setLastResults(payloadData);
    }

    const onGreenBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setGreenBets(payloadData);
    }

    const onPurpleBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPurpleBets(payloadData);
    }

    const onJokerBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setJokerBets(payloadData);
    }

    const onBlockBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBlockBet(payloadData);
    }

    const onSleepReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSleep(payloadData);
        setShowTimer(true);
    }

    const onRollingReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setRolling(payloadData);
    }

    const onFocusingReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setFocusing(payloadData);
        setShowTimer(payloadData);
        setShowResult(false);
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setErrorMessage(payloadData.message);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({...sessionInfos, "isValid": payloadData});
    }

    const handleBetValue = (event: { target: { value: any; }; }) => {
        const {value} = event.target;
        setUserData({...userData, "betValue": value});
    }

    const rouletteNumbers = [
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
        {result: 1, color: 'PURPLE'},
        {result: 8, color: 'GREEN'},
        {result: 2, color: 'PURPLE'},
        {result: 9, color: 'GREEN'},
        {result: 3, color: 'PURPLE'},
        {result: 10, color: 'GREEN'},
        {result: 4, color: 'PURPLE'},
        {result: 11, color: 'GREEN'},
        {result: 5, color: 'PURPLE'},
        {result: 12, color: 'GREEN'},
        {result: 6, color: 'PURPLE'},
        {result: 13, color: 'GREEN'},
        {result: 7, color: 'PURPLE'},
        {result: 14, color: 'GREEN'},
        {result: 0, color: 'JOKER'},
    ]

    function selectPurple() {
        setUserData({...userData, "colorSelected": "PURPLE"});
    }

    function selectJoker() {
        setUserData({...userData, "colorSelected": "JOKER"});
    }

    function selectGreen() {
        setUserData({...userData, "colorSelected": "GREEN"});
    }

    function divideBetValue() {
        setUserData({...userData, "betValue": userData.betValue / 2});
    }

    function multiplyBetValue() {
        setUserData({...userData, "betValue": userData.betValue * 2});
    }

    function sendBet() {
        if (stompClient) {
            var doubleBets = {
                amount: sessionInfos.amount,
                amountBet: userData.betValue,
                playerId: sessionInfos.playerId!,
                color: userData.colorSelected,
                isBet: false,
                user: casino,
                sessionId: session,
                playerName: sessionInfos.playerName
            };
            stompClient.send("/app/betDouble", {}, JSON.stringify(doubleBets));
            setErrorMessage("");
        }
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <ContainerInvalid>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </ContainerInvalid>
            ) : (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                        <SessionInfos>
                            <InfoMessage>
                                {t("username")}: {sessionInfos.playerName}
                            </InfoMessage>
                            <InfoMessage>
                                {t("wallet")}: {sessionInfos.amount.toFixed(2)}
                            </InfoMessage>
                        </SessionInfos>
                    <GameContent>
                        <BetBox>
                            <BetMesage>{t("selectColor")}</BetMesage>
                            <BetColorsContent>
                                <ButtonGreen onClick={selectGreen}
                                             style={{border: userData.colorSelected === "GREEN" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </ButtonGreen>

                                <ButtonJoker onClick={selectJoker}
                                             style={{border: userData.colorSelected === "JOKER" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x14</ButtonCardCircle>
                                </ButtonJoker>

                                <ButtonPurple onClick={selectPurple}
                                              style={{border: userData.colorSelected === "PURPLE" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </ButtonPurple>
                            </BetColorsContent>
                            {isResponsive ? (
                                <BetValueContent>
                                    <BetValueInputContent>
                                        <BetValueInput type={"number"} placeholder={t("bettingAmount")} value={userData.betValue}
                                                       onChange={handleBetValue}/>
                                        <BetValueChangeButton onClick={divideBetValue}>1/2</BetValueChangeButton>
                                        <BetValueChangeButton onClick={multiplyBetValue}>X2</BetValueChangeButton>
                                        <BetButton onClick={sendBet} disabled={blockBet}>Bet</BetButton>
                                    </BetValueInputContent>
                                    <ErrorMensageData>{t(errorMessage)}</ErrorMensageData>
                                </BetValueContent>

                            ) : (
                                <BetValueContent>
                                    <BetValueInputContent>
                                        <BetValueInput type={"number"} placeholder={t("bettingAmount")} value={userData.betValue}
                                                       onChange={handleBetValue}/>
                                        <BetValueChangeButton onClick={divideBetValue}>1/2</BetValueChangeButton>
                                        <BetValueChangeButton onClick={multiplyBetValue}>X2</BetValueChangeButton>
                                    </BetValueInputContent>
                                    <BetButton onClick={sendBet} disabled={blockBet}>Bet</BetButton>
                                    <ErrorMensageData>{t(errorMessage)}</ErrorMensageData>
                                </BetValueContent>
                            )}
                        </BetBox>
                        <ResultBox>
                            <ResultsRoulette>
                                <RouletteTimer>
                                    <RouletteMesage style={{display: showResult === true ? '' : 'none'}}>
                                        {t("jokerSpin")}{lastResult.result}!
                                    </RouletteMesage>

                                    <RouletteMesage
                                        style={{display: showResult === false && showTimer === false ? '' : 'none'}}>
                                        {t("rolling")}
                                    </RouletteMesage>

                                    <SleepProgress style={{display: showTimer === true ? '' : 'none'}}>
                                        <RouletteMesage>
                                            {t("timeToNextRound")}
                                        </RouletteMesage>
                                        <ProgressBar value={sleep} max={100}></ProgressBar>
                                    </SleepProgress>
                                </RouletteTimer>
                                <Roulette>
                                    <RouletteNumbers style={{
                                        transform: focusing === false ? 'translateX(-'.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(-0rem)',
                                        transitionDuration: focusing === false ? '3000ms' : '1000ms',
                                        transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                    }}>
                                        <Selector style={{
                                            transform: focusing === false ? 'translateX('.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(-0rem)',
                                            transitionDuration: focusing === false ? '3000ms' : '1000ms',
                                            transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                        }}></Selector>
                                        {rouletteNumbers.map((r, index) => (
                                            <RouletteCard key={index}>
                                                <RouletteResult style={{
                                                    background: r.color == "GREEN" ? '#3C8800' :
                                                        r.color == "PURPLE" ? '#5E0A86' : '',
                                                    backgroundImage: r.color == "JOKER" ? 'url(/joker.png)' : ''
                                                }}>
                                                    <CardCircle style={{background: 'none', color: '#fff'}}>
                                                        {r.result}
                                                    </CardCircle>
                                                </RouletteResult>
                                            </RouletteCard>
                                        ))}
                                    </RouletteNumbers>
                                </Roulette>
                            </ResultsRoulette>
                            <LastResultsComponent>
                                <LastResultsContent>
                                    {lastResults.results!.map((r, index) => (
                                        <LastResult key={index}>
                                            <LastResultData style={{
                                                background: r.color == "GREEN" ? '#3C8800' :
                                                    r.color == "PURPLE" ? '#5E0A86' : '',
                                                backgroundImage: r.color == "JOKER" ? 'url(/joker.png)' : ''
                                            }}>
                                                <RouletteResultCircle style={{background: 'none', color: '#fff'}}>
                                                    {r.result}
                                                </RouletteResultCircle>
                                            </LastResultData>
                                        </LastResult>
                                    ))}
                                </LastResultsContent>
                                <LastResultsMesage>
                                    {t("previousSpins")}
                                </LastResultsMesage>
                            </LastResultsComponent>
                        </ResultBox>
                    </GameContent>

                    <BetsContent>
                        <Bets>
                            <GreenResult>
                                <CardCircle>x2</CardCircle>
                            </GreenResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{greenBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {greenBets.roundDoubleBets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                        <Bets>
                            <JokerResult>
                                <CardCircle>x14</CardCircle>
                            </JokerResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{jokerBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {jokerBets.roundDoubleBets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                        <Bets>
                            <PurpleResult>
                                <CardCircle>x2</CardCircle>
                            </PurpleResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{purpleBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {purpleBets.roundDoubleBets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                    </BetsContent>
                </Container>
            )}
        </>
    )
}

export default DoubleV1Room

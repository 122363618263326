import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GetSessionInfos } from '../../../hooks/useSessionInfo';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { IBlackjackAsCards, IBlackjackCard, ISessionInfos } from '../../../types';
import {
    ActionButton,
    BetButton,
    BetInfo,
    BetInfoMessage,
    Blurn,
    BorderTable, ButtonMusic,
    Card,
    Cards,
    CardsContainer,
    CheapImg,
    Container,
    ErrorMensageData, ErrorMessageData,
    Hand,
    HandButtons,
    HomeCards,
    InfoMessage,
    PlayerActionButtons,
    PlayerActionInfos,
    PlayerActions,
    PlayerCards,
    PlayerOptions,
    PokerChipButton,
    PokerChips,
    RestartButton,
    ResultMessage,
    SessionInfos, SoundImage,
    Table,
    TableTexture
} from './styles';

import BackCard from '../../../assets/games/blackjack/v2/backCard.png'
import Cheap from '../../../assets/games/blackjack/v2/cheap.png'
import PokerChip1 from '../../../assets/games/blackjack/pokerChip/pokerChip1.svg'
import PokerChip2 from '../../../assets/games/blackjack/pokerChip/pokerChip2.svg'
import PokerChip3 from '../../../assets/games/blackjack/pokerChip/pokerChip3.svg'
import PokerChip4 from '../../../assets/games/blackjack/pokerChip/pokerChip4.svg'
import PokerChip5 from '../../../assets/games/blackjack/pokerChip/pokerChip5.svg'
import PokerChip6 from '../../../assets/games/blackjack/pokerChip/pokerChip6.svg'
import PokerChip7 from '../../../assets/games/blackjack/pokerChip/pokerChip7.svg'
import PokerChip8 from '../../../assets/games/blackjack/pokerChip/pokerChip8.svg'
import PokerChip9 from '../../../assets/games/blackjack/pokerChip/pokerChip9.svg'
import Club1 from '../../../assets/games/blackjack/cards/club/club1.svg'
import Club2 from '../../../assets/games/blackjack/cards/club/club2.svg'
import Club3 from '../../../assets/games/blackjack/cards/club/club3.svg'
import Club4 from '../../../assets/games/blackjack/cards/club/club4.svg'
import Club5 from '../../../assets/games/blackjack/cards/club/club5.svg'
import Club6 from '../../../assets/games/blackjack/cards/club/club6.svg'
import Club7 from '../../../assets/games/blackjack/cards/club/club7.svg'
import Club8 from '../../../assets/games/blackjack/cards/club/club8.svg'
import Club9 from '../../../assets/games/blackjack/cards/club/club9.svg'
import Club10 from '../../../assets/games/blackjack/cards/club/club10.svg'
import Club11 from '../../../assets/games/blackjack/cards/club/club11.svg'
import Club12 from '../../../assets/games/blackjack/cards/club/club12.svg'
import Club13 from '../../../assets/games/blackjack/cards/club/club13.svg'
import Diamond1 from '../../../assets/games/blackjack/cards/diamond/diamond1.svg'
import Diamond2 from '../../../assets/games/blackjack/cards/diamond/diamond2.svg'
import Diamond3 from '../../../assets/games/blackjack/cards/diamond/diamond3.svg'
import Diamond4 from '../../../assets/games/blackjack/cards/diamond/diamond4.svg'
import Diamond5 from '../../../assets/games/blackjack/cards/diamond/diamond5.svg'
import Diamond6 from '../../../assets/games/blackjack/cards/diamond/diamond6.svg'
import Diamond7 from '../../../assets/games/blackjack/cards/diamond/diamond7.svg'
import Diamond8 from '../../../assets/games/blackjack/cards/diamond/diamond8.svg'
import Diamond9 from '../../../assets/games/blackjack/cards/diamond/diamond9.svg'
import Diamond10 from '../../../assets/games/blackjack/cards/diamond/diamond10.svg'
import Diamond11 from '../../../assets/games/blackjack/cards/diamond/diamond11.svg'
import Diamond12 from '../../../assets/games/blackjack/cards/diamond/diamond12.svg'
import Diamond13 from '../../../assets/games/blackjack/cards/diamond/diamond13.svg'
import Heart1 from '../../../assets/games/blackjack/cards/heart/heart1.svg'
import Heart2 from '../../../assets/games/blackjack/cards/heart/heart2.svg'
import Heart3 from '../../../assets/games/blackjack/cards/heart/heart3.svg'
import Heart4 from '../../../assets/games/blackjack/cards/heart/heart4.svg'
import Heart5 from '../../../assets/games/blackjack/cards/heart/heart5.svg'
import Heart6 from '../../../assets/games/blackjack/cards/heart/heart6.svg'
import Heart7 from '../../../assets/games/blackjack/cards/heart/heart7.svg'
import Heart8 from '../../../assets/games/blackjack/cards/heart/heart8.svg'
import Heart9 from '../../../assets/games/blackjack/cards/heart/heart9.svg'
import Heart10 from '../../../assets/games/blackjack/cards/heart/heart10.svg'
import Heart11 from '../../../assets/games/blackjack/cards/heart/heart11.svg'
import Heart12 from '../../../assets/games/blackjack/cards/heart/heart12.svg'
import Heart13 from '../../../assets/games/blackjack/cards/heart/heart13.svg'
import Spade1 from '../../../assets/games/blackjack/cards/spade/spade1.svg'
import Spade2 from '../../../assets/games/blackjack/cards/spade/spade2.svg'
import Spade3 from '../../../assets/games/blackjack/cards/spade/spade3.svg'
import Spade4 from '../../../assets/games/blackjack/cards/spade/spade4.svg'
import Spade5 from '../../../assets/games/blackjack/cards/spade/spade5.svg'
import Spade6 from '../../../assets/games/blackjack/cards/spade/spade6.svg'
import Spade7 from '../../../assets/games/blackjack/cards/spade/spade7.svg'
import Spade8 from '../../../assets/games/blackjack/cards/spade/spade8.svg'
import Spade9 from '../../../assets/games/blackjack/cards/spade/spade9.svg'
import Spade10 from '../../../assets/games/blackjack/cards/spade/spade10.svg'
import Spade11 from '../../../assets/games/blackjack/cards/spade/spade11.svg'
import Spade12 from '../../../assets/games/blackjack/cards/spade/spade12.svg'
import Spade13 from '../../../assets/games/blackjack/cards/spade/spade13.svg'
import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import { useTranslation } from "react-i18next";
import { GetCasinoAccess } from "../../../hooks/useAuth";
import { URLs } from "../../../services/service.config";

const BlackjackV2Room = () => {
    const { t } = useTranslation();
    const { casino, session } = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [privateResultFirstRandMessage, setPrivateResultFirstRandMessage] = useState("");
    const [privateResultSecondRandMessage, setPrivateResultSecondRandMessage] = useState("");
    const [canStayFirstHand, setCanStayFirstHand] = useState(true);
    const [canStaySecondHand, setCanStaySecondHand] = useState(true);
    const [canFlipCardFirstHand, setCanFlipCardFirstHand] = useState(true);
    const [canFlipCardSecondHand, setCanFlipCardSecondHand] = useState(true);
    const [canDivideCards, setCanDivideCards] = useState(true);
    const [canRestart, setCanRestart] = useState(true);
    const [canGiveUp, setCanGiveUp] = useState(true);
    const [canDuplicateBet, setCanDuplicateBet] = useState(true);
    const [canBet, setCanBet] = useState(true);
    const [casinoAccess, setCasinoAccess] = useState(false);
    const [homeCards, setHomeCards] = useState<[IBlackjackCard] | undefined>(undefined);
    const [playerCards, setPlayerCards] = useState<[IBlackjackCard] | undefined>(undefined);
    const [playerCardsSecondHand, setPlayerCardsSecondHand] = useState<[IBlackjackCard] | undefined>(undefined);
    const [asCard, setAsCard] = useState<IBlackjackAsCards | undefined>(undefined)
    const [betValue, setBetValue] = useState(0);
    const [betIdFirstHand, setBetIdFirstHand] = useState(0);
    const [betIdSecondHand, setBetIdSecondHand] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });

    useEffect(() => {
        FindCasinoAccess();
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Blackjack Vegas", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsBlackjackV2)));
        FindSessionInfos();
    }

    async function FindSessionInfos() {
        await setSessionInfos(await GetSessionInfos(session))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = async () => {
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-blackjack-v2', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canStayBlackjackFirstHandV2', onCanStayFirstHandMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canStayBlackjackSecondHandV2', onCanStaySecondHandMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFlipBlackjackCardFirstHandV2', onCanFlipFirstHandBlackjackMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFlipBlackjackCardSecondHandV2', onCanFlipBlackSecondHandjackMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canDivideBlackjackCardsV2', onCanDivideBlackjackMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canGiveUpCardsV2', onCanGiveUpMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canDuplicateBetV2', onCanDuplicateBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/blackjackSessionInfosV2', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canBlackjackRestartV2', onCanRestartMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/betBlackjackV2', onBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/homeCardsV2', onHomeCardsMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/playerCardsV2', onPlayerCardsMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/selectAsValueV2', onSelectAsValueMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-result-first-blackjack-v2', onPrivateResultFirstHandMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-result-second-blackjack-v2', onPrivateResultSecondHandMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidBlackjackV2', onIsValidMessage);
        setCanBet(false);
    }

    const onError = (err: any) => {
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onCanStayFirstHandMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanStayFirstHand(payloadData);
    }

    const onCanStaySecondHandMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanStaySecondHand(payloadData);
    }

    const onCanFlipFirstHandBlackjackMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanFlipCardFirstHand(payloadData);
    }

    const onCanFlipBlackSecondHandjackMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanFlipCardSecondHand(payloadData);
    }

    const onCanDivideBlackjackMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanDivideCards(payloadData);
    }

    const onCanGiveUpMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanGiveUp(payloadData);
    }

    const onCanDuplicateBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanDuplicateBet(payloadData);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onCanRestartMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanRestart(payloadData);
    }

    const onBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBetIdFirstHand(payloadData.betIdFirstHand);
        setBetIdSecondHand(payloadData.betIdSecondHand);
        setBetValue(payloadData.amountBet);
        setCanBet(payloadData.isBet);
        setPrivateChat("")
    }

    const onHomeCardsMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setHomeCards(payloadData.blackjackCardV2ListFirstHand);
    }

    const onPlayerCardsMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPlayerCards(payloadData.blackjackCardV2ListFirstHand);
        if (payloadData.blackjackCardV2ListSecondHand !== null) {
            setPlayerCardsSecondHand(payloadData.blackjackCardV2ListSecondHand);
        }
    }

    const onSelectAsValueMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setAsCard(payloadData)
    }

    const onPrivateResultFirstHandMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateResultFirstRandMessage(payloadData.message);
    }

    const onPrivateResultSecondHandMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateResultSecondRandMessage(payloadData.message);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({ ...sessionInfos, "isValid": payloadData });
    }

    const handleBetValue = (event: any) => {
        const id = event.target.id;
        if (id == 0) {
            setBetValue(0);
        }
        if (id == 1) {
            setBetValue(betValue + 0.25);
        }
        if (id == 2) {
            setBetValue(betValue + 0.5);
        }
        if (id == 3) {
            setBetValue(betValue + 1);
        }
        if (id == 4) {
            setBetValue(betValue + 2.5);
        }
        if (id == 5) {
            setBetValue(betValue + 5);
        }
        if (id == 6) {
            setBetValue(betValue + 10);
        }
        if (id == 7) {
            setBetValue(betValue + 50);
        }
        if (id == 8) {
            setBetValue(betValue + 100);
        }
        if (id == 9) {
            setBetValue(betValue + 200);
        }
    }

    async function sendBet() {
        var bet = {
            amount: sessionInfos.amount,
            amountBet: betValue,
            isBet: false,
            user: casino,
            sessionId: session,
            playerId: sessionInfos.playerId,
        };
        stompClient.send("/app/betBlackjackV2", {}, JSON.stringify(bet));
    }

    async function sendFinishRoundFirstHand() {
        var finishRound = {
            betIdFirstHand: betIdFirstHand,
            betIdSecondHand: betIdSecondHand,
            user: casino,
            isFirstHand: true
        };
        stompClient.send("/app/finishBlackjackRoundV2", {}, JSON.stringify(finishRound));
    }

    async function sendFinishRoundSecondHand() {
        var finishRound = {
            betIdFirstHand: betIdFirstHand,
            betIdSecondHand: betIdSecondHand,
            user: casino,
            isFirstHand: false
        };
        stompClient.send("/app/finishBlackjackRoundV2", {}, JSON.stringify(finishRound));
    }

    async function sendDuplicateBet() {
        var duplicateBet = {
            betId: betIdFirstHand,
            user: casino
        };
        stompClient.send("/app/duplicateBetBlackjackV2", {}, JSON.stringify(duplicateBet));
    }

    async function sendFlipCardFirstHand() {
        var flipCard = {
            user: casino,
            betId: betIdFirstHand,
            playerId: sessionInfos.playerId,
            isFirstHand: true
        };
        stompClient.send("/app/flipBlackjackCardV2", {}, JSON.stringify(flipCard));
    }

    async function sendFlipCardSecondHand() {
        var flipCard = {
            user: casino,
            betId: betIdSecondHand,
            playerId: sessionInfos.playerId,
            isFirstHand: false
        };
        stompClient.send("/app/flipBlackjackCardV2", {}, JSON.stringify(flipCard));
    }

    async function sendDivideCards() {
        var divideCards = {
            user: casino,
            betId: betIdFirstHand,
            playerId: sessionInfos.playerId,
            betV2: {
                amount: sessionInfos.amount,
                amountBet: betValue,
                isBet: false,
                user: casino,
                sessionId: session,
                playerId: sessionInfos.playerId,
            }
        };
        stompClient.send("/app/divideBlackjackCardsV2", {}, JSON.stringify(divideCards));
    }

    async function sendGiveUp() {
        var giveUpCards = {
            user: casino,
            betId: betIdFirstHand,
            playerId: sessionInfos.playerId,
        };
        stompClient.send("/app/giveUpBlackjackV2", {}, JSON.stringify(giveUpCards));
    }

    async function sendAsValue(cardId: number, value: number) {
        var asValue = {
            cardId: cardId,
            playerId: sessionInfos.playerId,
            value: value
        };
        stompClient.send("/app/selectAsBlackjackValueV2", {}, JSON.stringify(asValue));
        if (asCard!.cards.length > 1) {
            var result = asCard!.cards.filter(function (el) {
                return el.cardId == cardId;
            });

            for (var elemento of result) {
                var index = asCard!.cards.indexOf(elemento);
                asCard!.cards.splice(index, 1);
            }
            setAsCard(asCard)
        } else {
            setAsCard(undefined)
        }
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    async function sendRestart() {
        setPrivateChat("");
        setPrivateResultFirstRandMessage("");
        setPrivateResultSecondRandMessage("");
        setCanRestart(true);
        setCanBet(false);
        setHomeCards(undefined);
        setPlayerCards(undefined);
        setPlayerCardsSecondHand(undefined);
        setCanDuplicateBet(true);
        setAsCard(undefined);
    }

    return (
        <Container>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Blurn>
                    <audio style={{ display: "none" }} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg" />
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                            alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Blurn>
            ) : (
                <Blurn>
                    <audio style={{ display: "none" }} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg" />
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                            alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <BorderTable>
                        <Table>
                            <TableTexture>
                                <CheapImg src={Cheap}></CheapImg>
                                <CardsContainer>
                                    <HomeCards>
                                        <Cards style={{
                                            position: "relative",
                                            width: homeCards !== undefined ? (((homeCards?.length - 1) * 1.5) + 5).toString().concat("rem") : "",
                                            height: homeCards !== undefined ? (((homeCards?.length - 1) * 0.6) + 7).toString().concat("rem") : ""
                                        }}>
                                            {homeCards?.map((card, index) => (
                                                <Card style={{
                                                    position: "absolute",
                                                    zIndex: index,
                                                    left: (index === 0 ? 0 : (index * 2)).toString().concat("rem"),
                                                    top: (index === 0 ? 0 : (index * 0.6)).toString().concat("rem")
                                                }}
                                                    src={card.number == "A" && card.suits == "CLUB" ? Club1 : card.number == "TWO" && card.suits == "CLUB" ? Club2 : card.number == "THREE" && card.suits == "CLUB" ? Club3 : card.number == "FOUR" && card.suits == "CLUB" ? Club4 : card.number == "FIVE" && card.suits == "CLUB" ? Club5 : card.number == "SIX" && card.suits == "CLUB" ? Club6 : card.number == "SEVEN" && card.suits == "CLUB" ? Club7 : card.number == "EIGHT" && card.suits == "CLUB" ? Club8 : card.number == "NINE" && card.suits == "CLUB" ? Club9 : card.number == "TEN" && card.suits == "CLUB" ? Club10 : card.number == "J" && card.suits == "CLUB" ? Club11 : card.number == "Q" && card.suits == "CLUB" ? Club12 : card.number == "K" && card.suits == "CLUB" ? Club13 :
                                                        card.number == "A" && card.suits == "DIAMOND" ? Diamond1 : card.number == "TWO" && card.suits == "DIAMOND" ? Diamond2 : card.number == "THREE" && card.suits == "DIAMOND" ? Diamond3 : card.number == "FOUR" && card.suits == "DIAMOND" ? Diamond4 : card.number == "FIVE" && card.suits == "DIAMOND" ? Diamond5 : card.number == "SIX" && card.suits == "DIAMOND" ? Diamond6 : card.number == "SEVEN" && card.suits == "DIAMOND" ? Diamond7 : card.number == "EIGHT" && card.suits == "DIAMOND" ? Diamond8 : card.number == "NINE" && card.suits == "DIAMOND" ? Diamond9 : card.number == "TEN" && card.suits == "DIAMOND" ? Diamond10 : card.number == "J" && card.suits == "DIAMOND" ? Diamond11 : card.number == "Q" && card.suits == "DIAMOND" ? Diamond12 : card.number == "K" && card.suits == "DIAMOND" ? Diamond13 :
                                                            card.number == "A" && card.suits == "HEART" ? Heart1 : card.number == "TWO" && card.suits == "HEART" ? Heart2 : card.number == "THREE" && card.suits == "HEART" ? Heart3 : card.number == "FOUR" && card.suits == "HEART" ? Heart4 : card.number == "FIVE" && card.suits == "HEART" ? Heart5 : card.number == "SIX" && card.suits == "HEART" ? Heart6 : card.number == "SEVEN" && card.suits == "HEART" ? Heart7 : card.number == "EIGHT" && card.suits == "HEART" ? Heart8 : card.number == "NINE" && card.suits == "HEART" ? Heart9 : card.number == "TEN" && card.suits == "HEART" ? Heart10 : card.number == "J" && card.suits == "HEART" ? Heart11 : card.number == "Q" && card.suits == "HEART" ? Heart12 : card.number == "K" && card.suits == "HEART" ? Heart13 :
                                                                card.number == "A" && card.suits == "SPADE" ? Spade1 : card.number == "TWO" && card.suits == "SPADE" ? Spade2 : card.number == "THREE" && card.suits == "SPADE" ? Spade3 : card.number == "FOUR" && card.suits == "SPADE" ? Spade4 : card.number == "FIVE" && card.suits == "SPADE" ? Spade5 : card.number == "SIX" && card.suits == "SPADE" ? Spade6 : card.number == "SEVEN" && card.suits == "SPADE" ? Spade7 : card.number == "EIGHT" && card.suits == "SPADE" ? Spade8 : card.number == "NINE" && card.suits == "SPADE" ? Spade9 : card.number == "TEN" && card.suits == "SPADE" ? Spade10 : card.number == "J" && card.suits == "SPADE" ? Spade11 : card.number == "Q" && card.suits == "SPADE" ? Spade12 : card.number == "K" && card.suits == "SPADE" ? Spade13 :
                                                                    BackCard} alt='Back card'>
                                                </Card>
                                            ))}
                                        </Cards>
                                    </HomeCards>
                                    <PlayerCards style={{
                                        gap: playerCardsSecondHand !== undefined ? '10rem'
                                            : asCard !== undefined ? '5rem' : ''
                                    }}>
                                        <Hand>
                                            <ResultMessage>
                                                {t(privateResultFirstRandMessage)}
                                            </ResultMessage>
                                            <Cards style={{
                                                position: "relative",
                                                width: playerCards !== undefined ? (((playerCards?.length - 1) * 1.5) + 5).toString().concat("rem") : "",
                                                height: playerCards !== undefined ? (((playerCards?.length - 1) * 0.6) + 7).toString().concat("rem") : ""
                                            }}>
                                                {playerCards?.map((card, index) => (
                                                    <Card style={{
                                                        position: "absolute",
                                                        zIndex: index,
                                                        left: (index === 0 ? 0 : (index * 2)).toString().concat("rem"),
                                                        top: (index === 0 ? 0 : (index * 0.6)).toString().concat("rem")
                                                    }}
                                                        src={card.number == "A" && card.suits == "CLUB" ? Club1 : card.number == "TWO" && card.suits == "CLUB" ? Club2 : card.number == "THREE" && card.suits == "CLUB" ? Club3 : card.number == "FOUR" && card.suits == "CLUB" ? Club4 : card.number == "FIVE" && card.suits == "CLUB" ? Club5 : card.number == "SIX" && card.suits == "CLUB" ? Club6 : card.number == "SEVEN" && card.suits == "CLUB" ? Club7 : card.number == "EIGHT" && card.suits == "CLUB" ? Club8 : card.number == "NINE" && card.suits == "CLUB" ? Club9 : card.number == "TEN" && card.suits == "CLUB" ? Club10 : card.number == "J" && card.suits == "CLUB" ? Club11 : card.number == "Q" && card.suits == "CLUB" ? Club12 : card.number == "K" && card.suits == "CLUB" ? Club13 :
                                                            card.number == "A" && card.suits == "DIAMOND" ? Diamond1 : card.number == "TWO" && card.suits == "DIAMOND" ? Diamond2 : card.number == "THREE" && card.suits == "DIAMOND" ? Diamond3 : card.number == "FOUR" && card.suits == "DIAMOND" ? Diamond4 : card.number == "FIVE" && card.suits == "DIAMOND" ? Diamond5 : card.number == "SIX" && card.suits == "DIAMOND" ? Diamond6 : card.number == "SEVEN" && card.suits == "DIAMOND" ? Diamond7 : card.number == "EIGHT" && card.suits == "DIAMOND" ? Diamond8 : card.number == "NINE" && card.suits == "DIAMOND" ? Diamond9 : card.number == "TEN" && card.suits == "DIAMOND" ? Diamond10 : card.number == "J" && card.suits == "DIAMOND" ? Diamond11 : card.number == "Q" && card.suits == "DIAMOND" ? Diamond12 : card.number == "K" && card.suits == "DIAMOND" ? Diamond13 :
                                                                card.number == "A" && card.suits == "HEART" ? Heart1 : card.number == "TWO" && card.suits == "HEART" ? Heart2 : card.number == "THREE" && card.suits == "HEART" ? Heart3 : card.number == "FOUR" && card.suits == "HEART" ? Heart4 : card.number == "FIVE" && card.suits == "HEART" ? Heart5 : card.number == "SIX" && card.suits == "HEART" ? Heart6 : card.number == "SEVEN" && card.suits == "HEART" ? Heart7 : card.number == "EIGHT" && card.suits == "HEART" ? Heart8 : card.number == "NINE" && card.suits == "HEART" ? Heart9 : card.number == "TEN" && card.suits == "HEART" ? Heart10 : card.number == "J" && card.suits == "HEART" ? Heart11 : card.number == "Q" && card.suits == "HEART" ? Heart12 : card.number == "K" && card.suits == "HEART" ? Heart13 :
                                                                    card.number == "A" && card.suits == "SPADE" ? Spade1 : card.number == "TWO" && card.suits == "SPADE" ? Spade2 : card.number == "THREE" && card.suits == "SPADE" ? Spade3 : card.number == "FOUR" && card.suits == "SPADE" ? Spade4 : card.number == "FIVE" && card.suits == "SPADE" ? Spade5 : card.number == "SIX" && card.suits == "SPADE" ? Spade6 : card.number == "SEVEN" && card.suits == "SPADE" ? Spade7 : card.number == "EIGHT" && card.suits == "SPADE" ? Spade8 : card.number == "NINE" && card.suits == "SPADE" ? Spade9 : card.number == "TEN" && card.suits == "SPADE" ? Spade10 : card.number == "J" && card.suits == "SPADE" ? Spade11 : card.number == "Q" && card.suits == "SPADE" ? Spade12 : card.number == "K" && card.suits == "SPADE" ? Spade13 :
                                                                        BackCard} alt='Back card'>
                                                    </Card>
                                                ))}
                                            </Cards>
                                            <HandButtons>
                                                <ActionButton style={{ fontSize: "0.8rem" }} hidden={canStayFirstHand}
                                                    disabled={canStayFirstHand}
                                                    onClick={sendFinishRoundFirstHand}>
                                                    {t("stay")}
                                                </ActionButton>
                                                <ActionButton style={{ fontSize: "0.8rem" }} hidden={canFlipCardFirstHand}
                                                    disabled={canFlipCardFirstHand}
                                                    onClick={sendFlipCardFirstHand}>
                                                    {t("oneMoreLetter")}
                                                </ActionButton>
                                            </HandButtons>
                                        </Hand>
                                        {asCard?.cards.map((aux) => (
                                            <Hand>
                                                <Cards>
                                                    <Card
                                                        src={aux.card.number == "A" && aux.card.suits == "CLUB" ? Club1 :
                                                            aux.card.number == "A" && aux.card.suits == "DIAMOND" ? Diamond1 :
                                                                aux.card.number == "A" && aux.card.suits == "HEART" ? Heart1 :
                                                                    aux.card.number == "A" && aux.card.suits == "SPADE" ? Spade1 :
                                                                        BackCard} alt='Back card'>
                                                    </Card>
                                                </Cards>
                                                <HandButtons>
                                                    <ActionButton style={{ fontSize: "0.8rem" }}
                                                        onClick={() => sendAsValue(aux.cardId, 1)}>1</ActionButton>
                                                    <ActionButton style={{ fontSize: "0.8rem" }}
                                                        onClick={() => sendAsValue(aux.cardId, 11)}>11</ActionButton>
                                                </HandButtons>
                                            </Hand>

                                        ))}
                                        <Hand>
                                            <ResultMessage>
                                                {t(privateResultSecondRandMessage)}
                                            </ResultMessage>
                                            <Cards style={{
                                                position: "relative",
                                                width: playerCardsSecondHand !== undefined ? (((playerCardsSecondHand?.length - 1) * 1.5) + 5).toString().concat("rem") : "",
                                                height: playerCardsSecondHand !== undefined ? (((playerCardsSecondHand?.length - 1) * 0.6) + 7).toString().concat("rem") : ""
                                            }}>
                                                {playerCardsSecondHand?.map((card, index) => (
                                                    <Card style={{
                                                        position: "absolute",
                                                        zIndex: index,
                                                        left: (index === 0 ? 0 : (index * 2)).toString().concat("rem"),
                                                        top: (index === 0 ? 0 : (index * 0.6)).toString().concat("rem")
                                                    }}
                                                        src={card.number == "A" && card.suits == "CLUB" ? Club1 : card.number == "TWO" && card.suits == "CLUB" ? Club2 : card.number == "THREE" && card.suits == "CLUB" ? Club3 : card.number == "FOUR" && card.suits == "CLUB" ? Club4 : card.number == "FIVE" && card.suits == "CLUB" ? Club5 : card.number == "SIX" && card.suits == "CLUB" ? Club6 : card.number == "SEVEN" && card.suits == "CLUB" ? Club7 : card.number == "EIGHT" && card.suits == "CLUB" ? Club8 : card.number == "NINE" && card.suits == "CLUB" ? Club9 : card.number == "TEN" && card.suits == "CLUB" ? Club10 : card.number == "J" && card.suits == "CLUB" ? Club11 : card.number == "Q" && card.suits == "CLUB" ? Club12 : card.number == "K" && card.suits == "CLUB" ? Club13 :
                                                            card.number == "A" && card.suits == "DIAMOND" ? Diamond1 : card.number == "TWO" && card.suits == "DIAMOND" ? Diamond2 : card.number == "THREE" && card.suits == "DIAMOND" ? Diamond3 : card.number == "FOUR" && card.suits == "DIAMOND" ? Diamond4 : card.number == "FIVE" && card.suits == "DIAMOND" ? Diamond5 : card.number == "SIX" && card.suits == "DIAMOND" ? Diamond6 : card.number == "SEVEN" && card.suits == "DIAMOND" ? Diamond7 : card.number == "EIGHT" && card.suits == "DIAMOND" ? Diamond8 : card.number == "NINE" && card.suits == "DIAMOND" ? Diamond9 : card.number == "TEN" && card.suits == "DIAMOND" ? Diamond10 : card.number == "J" && card.suits == "DIAMOND" ? Diamond11 : card.number == "Q" && card.suits == "DIAMOND" ? Diamond12 : card.number == "K" && card.suits == "DIAMOND" ? Diamond13 :
                                                                card.number == "A" && card.suits == "HEART" ? Heart1 : card.number == "TWO" && card.suits == "HEART" ? Heart2 : card.number == "THREE" && card.suits == "HEART" ? Heart3 : card.number == "FOUR" && card.suits == "HEART" ? Heart4 : card.number == "FIVE" && card.suits == "HEART" ? Heart5 : card.number == "SIX" && card.suits == "HEART" ? Heart6 : card.number == "SEVEN" && card.suits == "HEART" ? Heart7 : card.number == "EIGHT" && card.suits == "HEART" ? Heart8 : card.number == "NINE" && card.suits == "HEART" ? Heart9 : card.number == "TEN" && card.suits == "HEART" ? Heart10 : card.number == "J" && card.suits == "HEART" ? Heart11 : card.number == "Q" && card.suits == "HEART" ? Heart12 : card.number == "K" && card.suits == "HEART" ? Heart13 :
                                                                    card.number == "A" && card.suits == "SPADE" ? Spade1 : card.number == "TWO" && card.suits == "SPADE" ? Spade2 : card.number == "THREE" && card.suits == "SPADE" ? Spade3 : card.number == "FOUR" && card.suits == "SPADE" ? Spade4 : card.number == "FIVE" && card.suits == "SPADE" ? Spade5 : card.number == "SIX" && card.suits == "SPADE" ? Spade6 : card.number == "SEVEN" && card.suits == "SPADE" ? Spade7 : card.number == "EIGHT" && card.suits == "SPADE" ? Spade8 : card.number == "NINE" && card.suits == "SPADE" ? Spade9 : card.number == "TEN" && card.suits == "SPADE" ? Spade10 : card.number == "J" && card.suits == "SPADE" ? Spade11 : card.number == "Q" && card.suits == "SPADE" ? Spade12 : card.number == "K" && card.suits == "SPADE" ? Spade13 :
                                                                        BackCard} alt='Back card'>
                                                    </Card>
                                                ))}
                                            </Cards>
                                            <HandButtons>
                                                <ActionButton style={{ fontSize: "0.8rem" }}
                                                    hidden={canStaySecondHand}
                                                    disabled={canStaySecondHand}
                                                    onClick={sendFinishRoundSecondHand}>
                                                    {t("stay")}
                                                </ActionButton>
                                                <ActionButton style={{ fontSize: "0.8rem" }}
                                                    hidden={canFlipCardSecondHand}
                                                    disabled={canFlipCardSecondHand}
                                                    onClick={sendFlipCardSecondHand}>
                                                    {t("oneMoreLetter")}
                                                </ActionButton>
                                            </HandButtons>
                                        </Hand>
                                    </PlayerCards>
                                </CardsContainer>
                                <PlayerOptions>
                                    <SessionInfos>
                                        <InfoMessage>
                                            {sessionInfos.playerName}
                                        </InfoMessage>
                                        <InfoMessage>
                                            {betValue.toFixed(2)}
                                        </InfoMessage>
                                    </SessionInfos>
                                    <PokerChips>
                                        <PokerChipButton id='1' onClick={handleBetValue} src={PokerChip1}
                                            alt='0.25'></PokerChipButton>
                                        <PokerChipButton id='2' onClick={handleBetValue} src={PokerChip2}
                                            alt='0.50'></PokerChipButton>
                                        <PokerChipButton id='3' onClick={handleBetValue} src={PokerChip3}
                                            alt='1'></PokerChipButton>
                                        <PokerChipButton id='4' onClick={handleBetValue} src={PokerChip4}
                                            alt='2.50'></PokerChipButton>
                                        <PokerChipButton id='5' onClick={handleBetValue} src={PokerChip5}
                                            alt='5.00'></PokerChipButton>
                                        <PokerChipButton id='6' onClick={handleBetValue} src={PokerChip6}
                                            alt='10'></PokerChipButton>
                                        <PokerChipButton id='7' onClick={handleBetValue} src={PokerChip7}
                                            alt='50'></PokerChipButton>
                                        <PokerChipButton id='8' onClick={handleBetValue} src={PokerChip8}
                                            alt='100'></PokerChipButton>
                                        <PokerChipButton id='9' onClick={handleBetValue} src={PokerChip9}
                                            alt='200'></PokerChipButton>
                                    </PokerChips>
                                </PlayerOptions>
                            </TableTexture>
                        </Table>
                    </BorderTable>
                    <PlayerActions>
                        <PlayerActionInfos>
                            <BetInfo>
                                <BetInfoMessage>
                                    {sessionInfos.amount.toFixed(2)}
                                </BetInfoMessage>
                                <BetInfoMessage>
                                    {t("wallet")}
                                </BetInfoMessage>
                            </BetInfo>
                            <BetInfo>
                                <BetInfoMessage>
                                    {(betValue * 2).toFixed(2)}
                                </BetInfoMessage>
                                <BetInfoMessage>
                                    {t("award")}
                                </BetInfoMessage>
                            </BetInfo>
                        </PlayerActionInfos>
                        <PlayerActionButtons>
                            <ActionButton hidden={canDivideCards} disabled={canDivideCards} onClick={sendDivideCards}>
                                {t("splitCards")}
                            </ActionButton>
                            <ActionButton hidden={canDuplicateBet} disabled={canDuplicateBet}
                                onClick={sendDuplicateBet}>
                                {t("doubleBet")}
                            </ActionButton>
                            <ActionButton hidden={canGiveUp} disabled={canGiveUp} onClick={sendGiveUp}>
                                {t("giveUpTheBet")}
                            </ActionButton>
                            <ActionButton id='0' hidden={betValue === 0} disabled={betValue === 0}
                                onClick={handleBetValue}>
                                {t("resetBetValue")}
                            </ActionButton>
                            <BetButton hidden={canBet} disabled={canBet} onClick={sendBet}>
                                {t("bet")}
                            </BetButton>
                            <RestartButton hidden={canRestart} disabled={canRestart}
                                onClick={sendRestart}>
                                {t("restart")}
                            </RestartButton>
                        </PlayerActionButtons>

                    </PlayerActions>
                    <ErrorMensageData>{t(privateChat)}</ErrorMensageData>
                </Blurn>
            )}
        </Container>
    )
}

export default BlackjackV2Room
import Seta from '../../assets/help/Polygon.svg'
import {useState} from 'react'

import {CollapseContainer, ContainerText, ContainerTitle} from './styles'

interface ICollapseProps {
    title: string
    description: string
}

export const Collapse = ({title, description}: ICollapseProps) => {
    const [open, setOpen] = useState(false)

    return (
        <CollapseContainer open={open}>
            <ContainerTitle
                onClick={() => {
                    setOpen(!open)
                }}
            >
                {title}
                <img src={Seta} alt=""/>
            </ContainerTitle>
            {open && <ContainerText>{description}</ContainerText>}
        </CollapseContainer>
    )
}

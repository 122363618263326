import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
    :root {
    }

    * {
        padding: 0;
        margin: 0;
        text-decoration: none;
        box-sizing: border-box;
    }

    body {
        background: ${(props) => props.theme.COLORS.linearBackground};
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }
        @media (max-width: 750px) {
            font-size: 87.5%;
        }
    }
`

export default GlobalStyle

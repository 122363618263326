import {useEffect, useState} from 'react'
import {
    GetLastResult,
    GetLastResults,
    GetRoundSilverBets,
    GetRoundGoldBets,
    GetRoundDiamondBets
} from '../../../hooks/useDoubleV2';
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../../hooks/useSessionInfo';
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {IDoubleV2Bets, IDoubleV2Result, IDoubleV2Results, ISessionInfos} from '../../../types';


import {
    Bets,
    BetBox,
    ResultBox,
    Container,
    BetMesage,
    ButtonGold,
    CardCircle,
    ButtonSilver,
    BetColorsContent,
    ButtonDiamond,
    BetValueContent,
    BetValueInputContent,
    ErrorMensageData,
    BetValueInput,
    BetValueChangeButton,
    BetButton,
    GameContent,
    BetsContent,
    SessionInfos,
    InfoMessage,
    LastResultsContent,
    LastResult,
    LastResultData,
    ResultsRoulette,
    LastResultsMesage,
    RouletteMesage,
    GoldResult,
    DiamondResult,
    SilverResult,
    BetsAmount,
    BetsAmountText,
    BetsAmountResult,
    PlayerBets,
    PlayerBetsResult,
    PlayerBetsContent,
    SleepProgress,
    Roulette,
    RouletteCard,
    RouletteTimer,
    RouletteNumbers,
    ProgressBar,
    RouletteResult,
    RouletteResultCircle,
    LastResultsComponent,
    ButtonCardCircle,
    Selector, ButtonMusic, SoundImage, ErrorMessageData, ContainerInvalid
} from './styles';

import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import {useTranslation} from "react-i18next";
import {GetCasinoAccess} from "../../../hooks/useAuth";
import {URLs} from "../../../services/service.config";

const DoubleV2Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [blockBet, setBlockBet] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [lastResult, setLastResult] = useState<IDoubleV2Result>({
        result: 0,
        color: "",
        isValid: true
    });
    const [sleep, setSleep] = useState(0);
    const [rolling, setRolling] = useState(0);
    const [focusing, setFocusing] = useState(true);
    const [lastResults, setLastResults] = useState<IDoubleV2Results>({
        results: [],
        isValid: true
    });
    const [silverBets, setSilverBets] = useState<IDoubleV2Bets>({
        amount: 0,
        roundDoubleV2Bets: [],
        isValid: true
    });
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [goldBets, setGoldBets] = useState<IDoubleV2Bets>({
        amount: 0,
        roundDoubleV2Bets: [],
        isValid: true
    });
    const [diamondBets, setDiamondBets] = useState<IDoubleV2Bets>({
        amount: 0,
        roundDoubleV2Bets: [],
        isValid: true
    });
    const [userData, setUserData] = useState({
        result: '',
        betValue: 0,
        colorSelected: ''
    });
    const [isResponsive, setIsResponsive] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess();
        checkResponsive();
        window.addEventListener('resize', checkResponsive)
        return () => {
            window.removeEventListener('resize', checkResponsive)
        }
    }, []);

    const checkResponsive = () => {
        const isResponsiveSize = window.innerWidth <= 1250
        setIsResponsive(isResponsiveSize)
    }

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Mining Roulette", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsDoubleV2)));
        FindSessionInfos();
        FindLastResult();
        FindTwentyResults();
        FindRoundSilverBets();
        FindRoundGoldBets();
        FindRoundDiamondBets();
    }

    async function FindSessionInfos() {
        setSessionInfos(await GetSessionInfos(session))
    }

    async function FindLastResult() {
        setLastResult(await GetLastResult(session!))
    }

    async function FindTwentyResults() {
        setLastResults(await GetLastResults(session!))
    }

    async function FindRoundSilverBets() {
        setSilverBets(await GetRoundSilverBets(session!))
    }

    async function FindRoundGoldBets() {
        setGoldBets(await GetRoundGoldBets(session!))
    }

    async function FindRoundDiamondBets() {
        setDiamondBets(await GetRoundDiamondBets(session!))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError)
    }

    const onConnected = () => {
        stompClient.subscribe('/user/' + casino + '/doubleV2Results/public', onLastResultReceived);
        stompClient.subscribe('/user/' + casino + '/lastTwentyDoubleV2Results/public', onLastResultsReceived);
        stompClient.subscribe('/user/' + casino + '/silverBets/public', onSilverBetReceived);
        stompClient.subscribe('/user/' + casino + '/goldBets/public', onGoldBetReceived);
        stompClient.subscribe('/user/' + casino + '/diamondBets/public', onDiamondBetReceived);
        stompClient.subscribe('/user/' + casino + '/blockDoubleV2Bet/public', onBlockBetReceived);
        stompClient.subscribe('/user/' + casino + '/doubleV2Sleep/public', onSleepReceived);
        stompClient.subscribe('/user/' + casino + '/doubleV2Rolling/public', onRollingReceived);
        stompClient.subscribe('/user/' + casino + '/focusingDoubleV2/public', onFocusingReceived);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-doubleV2', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/doubleV2SessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidDoubleV2', onIsValidMessage);
    }

    const onError = (err: any) => {
    }

    const onLastResultReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setLastResult(payloadData);
        setShowResult(true);
    }

    const onLastResultsReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setLastResults(payloadData);
    }

    const onSilverBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSilverBets(payloadData);
    }

    const onGoldBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setGoldBets(payloadData);
    }

    const onDiamondBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setDiamondBets(payloadData);
    }

    const onBlockBetReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBlockBet(payloadData);
    }

    const onSleepReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSleep(payloadData);
        setShowTimer(true);
    }

    const onRollingReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setRolling(payloadData);
    }

    const onFocusingReceived = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setFocusing(payloadData);
        setShowTimer(payloadData);
        setShowResult(false);
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setErrorMessage(payloadData.message);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({...sessionInfos, "isValid": payloadData});
    }

    const handleBetValue = (event: { target: { value: any; }; }) => {
        const {value} = event.target;
        setUserData({...userData, "betValue": value});
    }

    const rouletteNumbers = [
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
        {result: 10, color: 'GOLD'},
        {result: 3, color: 'SILVER'},
        {result: 4, color: 'SILVER'},
        {result: 5, color: 'SILVER'},
        {result: 6, color: 'SILVER'},
        {result: 11, color: 'GOLD'},
        {result: 14, color: 'DIAMOND'},
        {result: 12, color: 'GOLD'},
        {result: 7, color: 'SILVER'},
        {result: 8, color: 'SILVER'},
        {result: 1, color: 'SILVER'},
        {result: 2, color: 'SILVER'},
        {result: 9, color: 'GOLD'},
        {result: 13, color: 'DIAMOND'},
    ]

    function selectGold() {
        setUserData({...userData, "colorSelected": "GOLD"});
    }

    function selectDiamond() {
        setUserData({...userData, "colorSelected": "DIAMOND"});
    }

    function selectSilver() {
        setUserData({...userData, "colorSelected": "SILVER"});
    }

    function divideBetValue() {
        setUserData({...userData, "betValue": userData.betValue / 2});
    }

    function multiplyBetValue() {
        setUserData({...userData, "betValue": userData.betValue * 2});
    }

    function sendBet() {
        if (stompClient) {
            var doubleBets = {
                amount: sessionInfos.amount,
                amountBet: userData.betValue,
                playerId: sessionInfos.playerId!,
                color: userData.colorSelected,
                isBet: false,
                user: casino,
                sessionId: session
            };
            stompClient.send("/app/betDoubleV2", {}, JSON.stringify(doubleBets));
            setErrorMessage("");
        }
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <ContainerInvalid>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </ContainerInvalid>
            ) : (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <SessionInfos>
                        <InfoMessage>
                            {t("username")}: {sessionInfos.playerName}
                        </InfoMessage>
                        <InfoMessage>
                            {t("wallet")}: {sessionInfos.amount.toFixed(2)}
                        </InfoMessage>
                    </SessionInfos>

                    <GameContent>
                        <BetBox>
                            <BetMesage>{t("selectColor")}</BetMesage>
                            <BetColorsContent>
                                <ButtonSilver onClick={selectSilver}
                                              style={{border: userData.colorSelected === "SILVER" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x2</ButtonCardCircle>
                                </ButtonSilver>
                                <ButtonGold onClick={selectGold}
                                            style={{border: userData.colorSelected === "GOLD" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x4</ButtonCardCircle>
                                </ButtonGold>
                                <ButtonDiamond onClick={selectDiamond}
                                               style={{border: userData.colorSelected === "DIAMOND" ? '1px solid #fff' : ''}}>
                                    <ButtonCardCircle>x6</ButtonCardCircle>
                                </ButtonDiamond>
                            </BetColorsContent>

                            {isResponsive ? (
                                <BetValueContent>
                                    <BetValueInputContent>
                                        <BetValueInput type={"number"} placeholder={t("bettingAmount")}
                                                       value={userData.betValue}
                                                       onChange={handleBetValue}/>
                                        <BetValueChangeButton onClick={divideBetValue}>1/2</BetValueChangeButton>
                                        <BetValueChangeButton onClick={multiplyBetValue}>X2</BetValueChangeButton>
                                        <BetButton onClick={sendBet} disabled={blockBet}>Bet</BetButton>
                                    </BetValueInputContent>
                                    <ErrorMensageData>{t(errorMessage)}</ErrorMensageData>
                                </BetValueContent>
                            ) : (
                                <BetValueContent>
                                    <BetValueInputContent>
                                        <BetValueInput type={"number"} placeholder={t("bettingAmount")}
                                                       value={userData.betValue}
                                                       onChange={handleBetValue}/>
                                        <BetValueChangeButton onClick={divideBetValue}>1/2</BetValueChangeButton>
                                        <BetValueChangeButton onClick={multiplyBetValue}>X2</BetValueChangeButton>
                                    </BetValueInputContent>
                                    <BetButton onClick={sendBet} disabled={blockBet}>Bet</BetButton>
                                    <ErrorMensageData>{t(errorMessage)}</ErrorMensageData>
                                </BetValueContent>
                            )}
                        </BetBox>
                        <ResultBox>
                            <ResultsRoulette>
                                <RouletteTimer>
                                    <RouletteMesage style={{display: showResult === true ? '' : 'none'}}>
                                        {t("miningSpin")}{lastResult.result}!
                                    </RouletteMesage>

                                    <RouletteMesage
                                        style={{display: showResult === false && showTimer === false ? '' : 'none'}}>
                                        {t("rolling")}
                                    </RouletteMesage>

                                    <SleepProgress style={{display: showTimer === true ? '' : 'none'}}>
                                        <RouletteMesage>
                                            {t("timeToNextRound")}
                                        </RouletteMesage>
                                        <ProgressBar value={sleep} max={100}></ProgressBar>
                                    </SleepProgress>
                                </RouletteTimer>
                                <Roulette>
                                    <RouletteNumbers style={{
                                        transform: focusing === false ? 'translateX(-'.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(-49rem)',
                                        transitionDuration: focusing === false ? '3000ms' : '1000ms',
                                        transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                    }}>
                                        <Selector style={{
                                            transform: focusing === false ? 'translateX('.concat(rolling.toFixed(2).toString()).concat('rem)') : 'translateX(49rem)',
                                            transitionDuration: focusing === false ? '3000ms' : '1000ms',
                                            transitionDelay: focusing === false ? '-1000ms' : '0ms'
                                        }}></Selector>
                                        {rouletteNumbers.map((r, index) => (
                                            <RouletteCard key={index}>
                                                <RouletteResult style={{
                                                    background: r.color == "DIAMOND" ? '#b9f2ff' :
                                                        r.color == "GOLD" ? '#ffd700' : '#c0c0c0',
                                                }}>
                                                    <CardCircle style={{background: 'none', color: '#fff'}}>
                                                        {r.result}
                                                    </CardCircle>
                                                </RouletteResult>
                                            </RouletteCard>
                                        ))}
                                    </RouletteNumbers>
                                </Roulette>
                            </ResultsRoulette>
                            <LastResultsComponent>
                                <LastResultsMesage>
                                    {t("previousSpins")}
                                </LastResultsMesage>
                                <LastResultsContent>
                                    {lastResults.results.map((r, index) => (
                                        <LastResult key={index}>
                                            <LastResultData style={{
                                                background: r.color == "DIAMOND" ? '#b9f2ff' :
                                                    r.color == "GOLD" ? '#ffd700' : '#c0c0c0',
                                            }}>
                                                <RouletteResultCircle style={{background: 'none', color: '#fff'}}>
                                                    {r.result}
                                                </RouletteResultCircle>
                                            </LastResultData>
                                        </LastResult>
                                    ))}
                                </LastResultsContent>
                            </LastResultsComponent>
                        </ResultBox>
                    </GameContent>

                    <BetsContent>
                        <Bets>
                            <SilverResult>
                                <CardCircle>x2</CardCircle>
                            </SilverResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{silverBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {silverBets.roundDoubleV2Bets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                        <Bets>
                            <GoldResult>
                                <CardCircle>x4</CardCircle>
                            </GoldResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{goldBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {goldBets.roundDoubleV2Bets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                        <Bets>
                            <DiamondResult>
                                <CardCircle>x6</CardCircle>
                            </DiamondResult>
                            <BetsAmount>
                                <BetsAmountText>
                                    {t("totalBets")}
                                </BetsAmountText>
                                <BetsAmountResult>{diamondBets.amount}</BetsAmountResult>
                            </BetsAmount>
                            <PlayerBets>
                                <PlayerBetsResult>
                                    {t("username")}
                                </PlayerBetsResult>
                                <PlayerBetsResult>
                                    {t("amount")}
                                </PlayerBetsResult>
                            </PlayerBets>
                            <PlayerBetsContent>
                                {diamondBets.roundDoubleV2Bets.map((bet, index) => (
                                    <PlayerBets key={index}>
                                        <PlayerBetsResult>{bet.playerName}</PlayerBetsResult>
                                        <PlayerBetsResult>{bet.amountBet}</PlayerBetsResult>
                                    </PlayerBets>
                                ))}
                            </PlayerBetsContent>
                        </Bets>
                    </BetsContent>
                </Container>
            )}
        </>
    )
}

export default DoubleV2Room

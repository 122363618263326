import {Route, Routes} from 'react-router-dom'
import {DefaultLayout} from '../layouts/DefaultLayout'
import {Home} from '../pages/home/inedx'
import {Login} from '../pages/login'
import {Register} from '../pages/register'
import {RecoveryPassword} from '../pages/recoveryPassword'
import {ForgotPasswordPage} from '../pages/forgotPassword'
import {Games} from '../pages/games'
import {Details} from '../pages/detals'
import {AboutUs} from '../pages/aboutus'
import SlotMachineV1Room from '../pages/slotMachineRooms/v1/SlotMachineV1Room'
import SlotMachineV2Room from '../pages/slotMachineRooms/v2/SlotMachineV2Room'
import BlackjackV1Room from '../pages/blackjackRooms/v1/BlackjackV1Room'
import BlackjackV2Room from '../pages/blackjackRooms/v2/BlackjackV2Room'
import DoubleV1Room from '../pages/doubleRooms/v1/DoubleV1Room'
import DoubleV2Room from '../pages/doubleRooms/v2/DoubleV2Room'
import DoubleV3Room from '../pages/doubleRooms/v3/DoubleV3Room'
import CrashV1Room from '../pages/crashRooms/CrashV1Room'
import DiceV1Room from '../pages/diceRooms/DiceV1Room'
import MinesV1Room from '../pages/minesRooms/MinesV1Room'

export const UnloggedRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<DefaultLayout/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/recoveryPassword/:token" element={<RecoveryPassword/>}/>
                <Route path="/forgotPassword" element={<ForgotPasswordPage/>}/>
                <Route path="/games" element={<Games/>}/>
                <Route path="/details/:id" element={<Details/>}/>
                <Route path="/powergaming" element={<AboutUs/>}/>
            </Route>
            <Route path="/slot-machine-777/:casino/:session" element={< SlotMachineV1Room/>}/>
            <Route path="/slot-machine-zoo/:casino/:session" element={< SlotMachineV2Room/>}/>
            <Route path="/twenty-one/:casino/:session" element={< BlackjackV1Room/>}/>
            <Route path="/blackjack-vegas/:casino/:session" element={< BlackjackV2Room/>}/>
            <Route path="/double-joker/:casino/:session" element={< DoubleV1Room/>}/>
            <Route path="/mining-roulette/:casino/:session" element={< DoubleV2Room/>}/>
            <Route path="/color-roulette/:casino/:session" element={< DoubleV3Room/>}/>
            <Route path="/crash-trade/:casino/:session" element={< CrashV1Room/>}/>
            <Route path="/dice-percentage/:casino/:session" element={< DiceV1Room/>}/>
            <Route path="/pirate-hunt/:casino/:session" element={< MinesV1Room/>}/>
        </Routes>
    )
}
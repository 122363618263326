import styled from 'styled-components'

export const ContainerMargen = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    @media (max-width: 750px) {
        margin-top: 6rem;
    }
`

export const ContainerBorder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: 1.6rem;

    @media (max-width: 750px) {
        gap: 0rem;
    }
`

export const RegisterContainer = styled.div`
    display: flex;
    margin: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.COLORS.blackBlue};
    border-radius: 50px;
    padding: 3rem;
    background: ${(props) => props.theme.COLORS.shadow};
    width: 40rem;

    @media (max-width: 750px) {
        width: 25rem;
        padding: 2rem;
        margin: 1rem;
    }
`

export const H1Container = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    font-family: 'Poppins';
    font-style: italic;
    line-height: 4rem;
    font-size: 2.3rem;
    color: ${(props) => props.theme.COLORS.blackBlue};

    @media (max-width: 750px) {
        line-height: 3rem;
        font-size: 2rem;
    }
`

export const Wrapinput = styled.div`
    width: 25rem;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 1.5rem;

    @media (max-width: 750px) {
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.6rem;
    }

    input {
        all: unset;
        width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;
        width: 22rem;
        color: ${(props) => props.theme.COLORS.blackBlue};
    }

    .inputMask {
        width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;
        width: 22rem;
        color: ${(props) => props.theme.COLORS.blackBlue};
    }

    @media (max-width: 750px) {
        line-height: 2rem;
        font-size: 1.2rem;
    }
`

export const Container = styled.div``

export const Button = styled.button`
    width: 80%;
    border-radius: 15px;
    border-style: solid;
    height: 4rem;
    background: ${(props) => props.theme.COLORS.blackBlue};
    border: 1px solid ${(props) => props.theme.COLORS.white};
    font-size: 2rem;
    margin-top: 1.5rem;
    color: ${(props) => props.theme.COLORS.white};
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

export const ErrorMensageData = styled.div`
    font-size: 23px;
    padding: 0.5rem;
    color: ${(props) => props.theme.COLORS.red};
    text-align: center;
`
import {Link} from "react-router-dom";
import {Carrosel} from "../../components/Carrosel";
import {Help} from "../../components/Help";
import {
    ContainerHome,
    StartContainer,
    CarroselContainer,
    Tittle,
    ButtonContainer,
    Questions,
    TittleTwo,
} from "./styles";
import {useTranslation} from "react-i18next";

export function Home() {
    const { t } = useTranslation();

    return (
        <ContainerHome>
            <Tittle>
                {t("homeTittle")}
                <p>
                    {t("homeTittleAux")}
                </p>
            </Tittle>
            <StartContainer>
            <CarroselContainer>
                    <Carrosel/>
                </CarroselContainer>
                <TittleTwo>
                    {t("homeSubtitle")}
                </TittleTwo>
                <Link to="/games">
                    <ButtonContainer>
                        {t("discoveryOurGames")}
                    </ButtonContainer>
                </Link>

            </StartContainer>
            <Questions>
                {t("commonQuestions")}
            </Questions>
            <Help/>
        </ContainerHome>
    );
}

import {useState} from 'react'

import Person from '../../assets/login/person.svg'
import {ForgotPassword} from '../../hooks/useAuth'

import {RegisterContainer, H1Container, Wrapinput, Content, Container} from './styles'
import {useTranslation} from "react-i18next";
import i18n from "../../i18n/index"

export function ForgotPasswordPage() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')

    async function Submit() {
        event?.preventDefault()
        setMsg(t("sending"))
        setMsg(await ForgotPassword(email, i18n.language))
    }

    return (
        <Container>
            <RegisterContainer>
                <Content>
                    <H1Container>
                        {t("forgotMyPassword")}
                    </H1Container>
                    <>
                        <Wrapinput>
                            <img src={Person} alt={t("person")}/>
                            <input
                                className={email !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("registeredEmail")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Wrapinput>
                    </>
                    <h3>{msg}</h3>
                    <button onClick={Submit}>{t("sentRecoveryCode")}</button>
                </Content>
            </RegisterContainer>
        </Container>

    )
}
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../hooks/useSessionInfo';
import Boom from '../../assets/mines/pirate.png'
import Diamond from '../../assets/mines/bau.png'
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import {ISessionInfos} from '../../types';
import playMusic from '../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../assets/audio/images/pauseMusicWhite.png';
import dice from '../../assets/audio/dice.mp3';

import {
    Action,
    Actions,
    BetButton,
    Board,
    ButtonMusic,
    Buttons,
    CashOutButton,
    Cell,
    Column,
    Container,
    ErrorMensageData, ErrorMessageData,
    GameTable,
    Imagens,
    Infos,
    InputMensage,
    Menu,
    MenuContent,
    RestartButton,
    Select,
    SoundImage,
    ValeuIfWin
} from './styles';
import {useTranslation} from "react-i18next";
import {GetCasinoAccess} from "../../hooks/useAuth";
import {URLs} from "../../services/service.config";

const MinesV1Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [betValue, setBetValue] = useState(0);
    const [valueWin, setValueWin] = useState(0);
    const [canCashOut, setCanCashOut] = useState(true);
    const [canRestart, setCanRestart] = useState(true);
    const [betId, setBetId] = useState(0);
    const [bombCounter, setBombCounter] = useState(2);
    const [canFlipMines, setCanFlipMines] = useState(true);
    const [canBet, setCanBet] = useState(true);
    const [cell0, setCell0] = useState({
        bomb: false,
        win: false
    });
    const [cell1, setCell1] = useState({
        bomb: false,
        win: false
    });
    const [cell2, setCell2] = useState({
        bomb: false,
        win: false
    });
    const [cell3, setCell3] = useState({
        bomb: false,
        win: false
    });
    const [cell4, setCell4] = useState({
        bomb: false,
        win: false
    });
    const [cell5, setCell5] = useState({
        bomb: false,
        win: false
    });
    const [cell6, setCell6] = useState({
        bomb: false,
        win: false
    });
    const [cell7, setCell7] = useState({
        bomb: false,
        win: false
    });
    const [cell8, setCell8] = useState({
        bomb: false,
        win: false
    });
    const [cell9, setCell9] = useState({
        bomb: false,
        win: false
    });
    const [cell10, setCell10] = useState({
        bomb: false,
        win: false
    });
    const [cell11, setCell11] = useState({
        bomb: false,
        win: false
    });
    const [cell12, setCell12] = useState({
        bomb: false,
        win: false
    });
    const [cell13, setCell13] = useState({
        bomb: false,
        win: false
    });
    const [cell14, setCell14] = useState({
        bomb: false,
        win: false
    });
    const [cell15, setCell15] = useState({
        bomb: false,
        win: false
    });
    const [cell16, setCell16] = useState({
        bomb: false,
        win: false
    });
    const [cell17, setCell17] = useState({
        bomb: false,
        win: false
    });
    const [cell18, setCell18] = useState({
        bomb: false,
        win: false
    });
    const [cell19, setCell19] = useState({
        bomb: false,
        win: false
    });
    const [cell20, setCell20] = useState({
        bomb: false,
        win: false
    });
    const [cell21, setCell21] = useState({
        bomb: false,
        win: false
    });
    const [cell22, setCell22] = useState({
        bomb: false,
        win: false
    });
    const [cell23, setCell23] = useState({
        bomb: false,
        win: false
    });
    const [cell24, setCell24] = useState({
        bomb: false,
        win: false
    });
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess();
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Pirate Hunt", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsMinesV1)));
        FindSessionInfos();
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = async () => {
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-mines', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/betMines', onBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFinishRound', onCanFinishRoundMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/minesSessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/flipMinesCell', onFlipMinesCellMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFlipMines', onCanFlipMinesMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/valueIfWin', onValueIfWinMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canRestart', onCanRestartMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValid', onIsValidMessage);
        setCanBet(false);
    }

    const onError = (err: any) => {
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({...sessionInfos, "isValid": payloadData});
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBetId(payloadData.betId);
        setBetValue(payloadData.amountBet);
        setCanBet(payloadData.isBet);
        setPrivateChat("")
    }

    const onCanFinishRoundMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanCashOut(payloadData);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onFlipMinesCellMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        const id = payloadData.cellId;
        const isBomb = payloadData.isBomb;
        if (id == 0) {
            setCell0({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 1) {
            setCell1({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 2) {
            setCell2({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 3) {
            setCell3({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 4) {
            setCell4({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 5) {
            setCell5({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 6) {
            setCell6({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 7) {
            setCell7({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 8) {
            setCell8({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 9) {
            setCell9({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 10) {
            setCell10({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 11) {
            setCell11({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 12) {
            setCell12({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 13) {
            setCell13({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 14) {
            setCell14({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 15) {
            setCell15({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 16) {
            setCell16({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 17) {
            setCell17({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 18) {
            setCell18({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 19) {
            setCell19({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 20) {
            setCell20({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 21) {
            setCell21({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 22) {
            setCell22({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 23) {
            setCell23({
                bomb: isBomb,
                win: !isBomb
            });
        }
        if (id == 24) {
            setCell24({
                bomb: isBomb,
                win: !isBomb
            });
        }
    }

    const onCanFlipMinesMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanFlipMines(payloadData);
    }

    const onValueIfWinMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setValueWin(payloadData);
    }

    const onCanRestartMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanRestart(payloadData);
    }

    async function FindSessionInfos() {
        await setSessionInfos(await GetSessionInfos(session))
    }

    const handleBetValue = (event: any) => {
        const {value} = event.target;
        setBetValue(value);
    }

    const handleResult = (event: any) => {
        if (!canFlipMines) {
            const id = event.target.id;
            var flipCell = {
                minesFlipResponse: {
                    cellId: id
                },
                user: casino,
                betId: betId,
                playerId: sessionInfos.playerId!,
            };
            stompClient.send("/app/flipMinesCell", {}, JSON.stringify(flipCell));
        }
    }

    const handleBombValue = (event: any) => {
        const count = event.target.value;
        setBombCounter(count);
    }

    async function sendBet() {
        var bet = {
            amount: sessionInfos.amount,
            amountBet: betValue,
            isBet: false,
            user: casino,
            sessionId: session,
            playerId: sessionInfos.playerId!,
            bombsCounter: bombCounter
        };
        stompClient.send("/app/betMines", {}, JSON.stringify(bet));
    }

    async function sendCashOut() {
        var finishRound = {
            betId: betId,
            user: casino
        };
        stompClient.send("/app/finishRound", {}, JSON.stringify(finishRound));
        setCell0({
            bomb: false,
            win: false
        });
        setCell1({
            bomb: false,
            win: false
        });
        setCell2({
            bomb: false,
            win: false
        });
        setCell3({
            bomb: false,
            win: false
        });
        setCell4({
            bomb: false,
            win: false
        });
        setCell5({
            bomb: false,
            win: false
        });
        setCell6({
            bomb: false,
            win: false
        });
        setCell7({
            bomb: false,
            win: false
        });
        setCell8({
            bomb: false,
            win: false
        });
        setCell9({
            bomb: false,
            win: false
        });
        setCell10({
            bomb: false,
            win: false
        });
        setCell11({
            bomb: false,
            win: false
        });
        setCell12({
            bomb: false,
            win: false
        });
        setCell13({
            bomb: false,
            win: false
        });
        setCell14({
            bomb: false,
            win: false
        });
        setCell15({
            bomb: false,
            win: false
        });
        setCell16({
            bomb: false,
            win: false
        });
        setCell17({
            bomb: false,
            win: false
        });
        setCell18({
            bomb: false,
            win: false
        });
        setCell19({
            bomb: false,
            win: false
        });
        setCell20({
            bomb: false,
            win: false
        });
        setCell21({
            bomb: false,
            win: false
        });
        setCell22({
            bomb: false,
            win: false
        });
        setCell23({
            bomb: false,
            win: false
        });
        setCell24({
            bomb: false,
            win: false
        });
    }

    async function sendRestart() {
        setCanBet(false);
        setCell0({
            bomb: false,
            win: false
        });
        setCell1({
            bomb: false,
            win: false
        });
        setCell2({
            bomb: false,
            win: false
        });
        setCell3({
            bomb: false,
            win: false
        });
        setCell4({
            bomb: false,
            win: false
        });
        setCell5({
            bomb: false,
            win: false
        });
        setCell6({
            bomb: false,
            win: false
        });
        setCell7({
            bomb: false,
            win: false
        });
        setCell8({
            bomb: false,
            win: false
        });
        setCell9({
            bomb: false,
            win: false
        });
        setCell10({
            bomb: false,
            win: false
        });
        setCell11({
            bomb: false,
            win: false
        });
        setCell12({
            bomb: false,
            win: false
        });
        setCell13({
            bomb: false,
            win: false
        });
        setCell14({
            bomb: false,
            win: false
        });
        setCell15({
            bomb: false,
            win: false
        });
        setCell16({
            bomb: false,
            win: false
        });
        setCell17({
            bomb: false,
            win: false
        });
        setCell18({
            bomb: false,
            win: false
        });
        setCell19({
            bomb: false,
            win: false
        });
        setCell20({
            bomb: false,
            win: false
        });
        setCell21({
            bomb: false,
            win: false
        });
        setCell22({
            bomb: false,
            win: false
        });
        setCell23({
            bomb: false,
            win: false
        });
        setCell24({
            bomb: false,
            win: false
        });
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Container>
            ) : (
                <Container>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                                    alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <GameTable>
                        <Infos>
                            {t("currency")}{sessionInfos.amount.toFixed(2)}
                        </Infos>
                        <Board>
                            <Column>
                                <Cell id='0' onClick={handleResult}>
                                    <div style={{display: cell0.bomb ? 'flex' : 'none'}}>
                                        <Imagens
                                            src={Boom}
                                            alt="Boom"
                                        /></div>
                                    <div style={{display: cell0.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='1' onClick={handleResult}>
                                    <div style={{display: cell1.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell1.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='2' onClick={handleResult}>
                                    <div style={{display: cell2.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell2.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='3' onClick={handleResult}>
                                    <div style={{display: cell3.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell3.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='4' onClick={handleResult}>
                                    <div style={{display: cell4.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell4.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>

                            </Column>

                            <Column>
                                <Cell id='5' onClick={handleResult}>
                                    <div style={{display: cell5.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell5.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='6' onClick={handleResult}>
                                    <div style={{display: cell6.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell6.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='7' onClick={handleResult}>
                                    <div style={{display: cell7.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell7.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='8' onClick={handleResult}>
                                    <div style={{display: cell8.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell8.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='9' onClick={handleResult}>
                                    <div style={{display: cell9.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell9.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                            </Column>

                            <Column>
                                <Cell id='10' onClick={handleResult}>
                                    <div style={{display: cell10.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell10.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='11' onClick={handleResult}>
                                    <div style={{display: cell11.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell11.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='12' onClick={handleResult}>
                                    <div style={{display: cell12.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell12.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='13' onClick={handleResult}>
                                    <div style={{display: cell13.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell13.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='14' onClick={handleResult}>
                                    <div style={{display: cell14.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell14.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                            </Column>
                            <Column>
                                <Cell id='15' onClick={handleResult}>
                                    <div style={{display: cell15.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell15.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='16' onClick={handleResult}>
                                    <div style={{display: cell16.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell16.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='17' onClick={handleResult}>
                                    <div style={{display: cell17.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell17.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='18' onClick={handleResult}>
                                    <div style={{display: cell18.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell18.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='19' onClick={handleResult}>
                                    <div style={{display: cell19.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell19.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                            </Column>
                            <Column>
                                <Cell id='20' onClick={handleResult}>
                                    <div style={{display: cell20.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell20.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='21' onClick={handleResult}>
                                    <div style={{display: cell21.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell21.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='22' onClick={handleResult}>
                                    <div style={{display: cell22.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell22.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='23' onClick={handleResult}>
                                    <div style={{display: cell23.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell23.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                                <Cell id='24' onClick={handleResult}>
                                    <div style={{display: cell24.bomb ? 'flex' : 'none'}}>
                                        <Imagens src={Boom}/>
                                    </div>
                                    <div style={{display: cell24.win ? 'flex' : 'none'}}>
                                        <Imagens src={Diamond}/>
                                    </div>
                                </Cell>
                            </Column>
                        </Board>
                    </GameTable>

                    <Menu>
                        <MenuContent>
                            <Actions>
                                <Action>
                                    {t("numberOfSkulls")}
                                    <Select onChange={handleBombValue}>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                    </Select>
                                </Action>
                                <Action>
                                    {t("betAmount")}
                                    <InputMensage type={"number"} value={betValue} onChange={handleBetValue}/>
                                </Action>
                                <ErrorMensageData
                                    style={{display: privateChat ? "flex" : "none"}}>{t(privateChat)}</ErrorMensageData>
                            </Actions>


                            <Buttons>
                                <BetButton disabled={canBet} onClick={sendBet}>
                                    {t("bet")}
                                </BetButton>
                                <CashOutButton disabled={canCashOut} onClick={sendCashOut}>
                                    {t("cashOut")}
                                </CashOutButton>
                                <RestartButton disabled={canRestart} onClick={sendRestart}>
                                    {t("restart")}
                                </RestartButton>
                            </Buttons>
                        </MenuContent>
                        <ValeuIfWin>
                            {t("nextAward")} {valueWin.toFixed(2)}
                        </ValeuIfWin>
                    </Menu>
                </Container>
            )}
        </>
    )
}

export default MinesV1Room
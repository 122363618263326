import {DICE_CONFIG} from '../services/service.config'
import {api} from '../services/api'
import {IDiceBet, IDiceResult} from '../types';

export async function Bet(bet: IDiceBet) {
    let result: IDiceResult = {
        isBet: false,
        result: 0,
        amount: 0,
        amountReceived: 0,
        isValid: true
    };
    await api
        .post(
            DICE_CONFIG.bet.path + DICE_CONFIG.bet.endpoint,
            bet
        )
        .then((response) => {
            result = response.data;
        })
        .catch()
    return result
}
import Carousel from 'react-spring-3d-carousel'
import {config} from 'react-spring'
import {useState} from 'react'

import double from '../../assets/games/logo/double.png'
import piratehunt from '../../assets/games/logo/piratehunt.png'
import blackjack1 from '../../assets/games/logo/blackjack1.png'
import twentyone from '../../assets/games/logo/21.png'
import slotMachineZoo from '../../assets/games/logo/zoo.png'
import slotMachine from '../../assets/games/logo/777.png'
import dice from '../../assets/games/logo/dice.png'
import trade from '../../assets/games/logo/trade.png'
import colorRoulette from '../../assets/games/logo/colorRoulette.png'
import miningRoulette from '../../assets/games/logo/miningRoulette.png'


import {Container} from './styles'

interface ICarroselProps {
    goToSlide: number
    offsetRadius?: number
    showNavigation: boolean
    config: object
}

export const Carrosel = () => {
    const [state, setState] = useState<ICarroselProps>({
        goToSlide: 1,
        offsetRadius: 2,
        showNavigation: false,
        config: config.molasses,
    })

    const slides = [
        {
            key: 1,
            content: <img src={piratehunt} alt=""/>,
        },
        {
            key: 2,
            content: <img src={dice} alt=""/>,
        },
        {
            key: 3,
            content: <img src={double} alt=""/>,
        },
        {
            key: 4,
            content: <img src={twentyone} alt=""/>,
        },
        {
            key: 5,
            content: <img src={slotMachineZoo} alt=""/>,
        },
        {
            key: 6,
            content: <img src={blackjack1} alt=""/>,
        },
        {
            key: 7,
            content: <img src={slotMachine} alt=""/>,
        },
        {
            key: 8,
            content: <img src={trade} alt=""/>,
        },
        {
            key: 9,
            content: <img src={colorRoulette} alt=""/>,
        },
        {
            key: 10,
            content: <img src={miningRoulette} alt=""/>,
        },

    ].map((slide, index) => {
        return {...slide, onClick: () => setState({...state, goToSlide: index})}
    })

    return (
        <Container>
            <Carousel
                slides={slides}
                goToSlide={state.goToSlide}
                offsetRadius={state.offsetRadius}
                showNavigation={state.showNavigation}
                animationConfig={state.config}
            />
        </Container>
    )
}
import {FormEvent, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {GetResetPassword, PostResetPassword} from '../../hooks/useAuth'

import {
    RegisterContainer,
    H1Container,
    Wrapinput,
    Containerbtn,
    Container,
    ContainerBorder,
    ContainerAlert,
} from './styles'
import {useTranslation} from "react-i18next";

export function RecoveryPassword() {
    const { t } = useTranslation();
    const {token} = useParams()
    const [msg, setMsg] = useState({
        msg: "",
        msgStatus: ""
    })
    const [msgPassword, setMsgPassword] = useState<string | undefined>(t("passwordNotMatch"))

    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [matchPassword, setMatchPassword] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        CheckToken()
    }, [])

    useEffect(() => {
        passwordConfirmation === newPassword
            ? hiddeMessage()
            : setMatchPassword(false)
        setShowAlert(false)
        setMsgPassword(t("passwordNotMatch"))
    }, [newPassword, passwordConfirmation])

    function hiddeMessage() {
        setMatchPassword(true)
        setShowAlert(false)
    }

    async function submitUpdatePassword(event: FormEvent) {
        event.preventDefault()
        if (matchPassword && newPassword.length > 0) {
            setMsgPassword(await PostResetPassword(newPassword))
            setShowAlert(true)
        } else {
            setMsgPassword(t("emptyFields"))
            setShowAlert(true)
        }
    }

    async function CheckToken() {
        setMsg(await GetResetPassword(String(token)))
    }


    if (msg.msgStatus != "200") {
        return (
            <RegisterContainer>
                <ContainerBorder>
                    <H1Container>{t("invalidToken")}</H1Container>
                    <H1Container>{msg.msg}</H1Container>
                </ContainerBorder>
            </RegisterContainer>
        )
    } else {
        return (
            <RegisterContainer>
                <ContainerBorder>
                    <H1Container>{t("updatePasswordForUser")}{msg.msg}</H1Container>
                    <Container>
                        <Wrapinput>
                            <input
                                className={newPassword !== '' ? 'has-val input' : 'input'}
                                type="password"
                                placeholder={t("newPassword")}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>

                    <Container>
                        <Wrapinput>
                            <input
                                className={
                                    passwordConfirmation !== '' ? 'has-val input' : 'input'
                                }
                                type="password"
                                placeholder={t("confirmPassword")}
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                            />
                        </Wrapinput>
                    </Container>
                    <ContainerAlert match={matchPassword}>
                        <h4>{msgPassword}</h4>
                    </ContainerAlert>

                    <ContainerAlert match={!showAlert}>
                        <h4>{msgPassword}</h4>
                    </ContainerAlert>

                    <Containerbtn match={matchPassword} onClick={submitUpdatePassword}>
                        {t("send")}
                    </Containerbtn>
                </ContainerBorder>
            </RegisterContainer>
        )
    }
}
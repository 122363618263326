import {FormEvent, useState} from 'react'
import {NavLink} from 'react-router-dom'

import Person from '../../assets/login/person.svg'
import Lock from '../../assets/login/lock.svg'

import {
    Container,
    Containerlogin,
    Wraplogin,
    Loginform,
    Logintitle,
    Wrapinput,
    Containerbtn,
    Textcenter,
    ErrorMensageData,
} from './styles'

import {useAuth} from '../../hooks/useAuth'
import {useTranslation} from "react-i18next";

export function Login() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {AuthenticateUser} = useAuth()
    const [errorMessage, setErrorMessage] = useState<string | void>(undefined);

    async function signin(event: FormEvent) {
        event.preventDefault()

        setErrorMessage(await AuthenticateUser(email, password));
    }

    if (window.location.pathname !== '/login') {
        window.location.href = '/login'
    }

    return (
        <Container>
            <Containerlogin>
                <Wraplogin>
                    <Loginform>
                        <Logintitle>
                            {t("welcome")}
                        </Logintitle>
                        <Wrapinput>
                            <img src={Person} alt={t("person")}/>
                            <input
                                className={email !== '' ? 'has-val input' : 'input'}
                                type="text"
                                placeholder={t("userOrEmail")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Wrapinput>

                        <Wrapinput>
                            <img src={Lock} alt={t("padlock")}/>
                            <input
                                className={password !== '' ? 'has-val input' : 'input'}
                                type="password"
                                placeholder={t("password")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Wrapinput>
                        <ErrorMensageData hidden={errorMessage === undefined}>{errorMessage!}</ErrorMensageData>

                        <Containerbtn onClick={signin}>
                            {t("login")}
                        </Containerbtn>

                        <Textcenter>
                            <NavLink to="/register">
                                {t("register")}
                            </NavLink>
                        </Textcenter>
                        <Textcenter>
                            <NavLink to="/forgotPassword">
                                {t("forgotPassword")}
                            </NavLink>
                        </Textcenter>
                    </Loginform>
                </Wraplogin>
            </Containerlogin>
        </Container>
    )
}
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.background};
    align-items: center;
`

export const Machine = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 95%;
    width: 75%;
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.backgroundMachine};
    border: 1px solid black;
    border-radius: 1rem;

    @media (max-width: 1000px) {
        width: 80%;
    }
    @media (max-width: 800px) {
        width: 85%;
    }
    @media (max-width: 600px) {
        width: 90%;
    }
    @media (max-width: 500px) {
        width: 95%;
    }
`

export const MachineContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const MachineResults = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    height: 85%;
    width: 100%;

    @media (max-width: 750px) {
        height: 80%;
    }
    @media (max-width: 600px) {
        height: 75%;
    }
`

export const MachineActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 15%;
    width: 100%;

    @media (max-width: 750px) {
        height: 20%;
    }
    @media (max-width: 600px) {
        height: 25%;
    }
`

export const MachineActionsInformations = styled.div`
    display: flex;
    align-items: center;
    height: 50%;
    width: 100%;
    justify-content: space-around;
`

export const MachineActionsBet = styled.div`
    display: flex;
    align-items: center;
    height: 50%;
    width: 100%;
    justify-content: center;
`

export const MachineResultsAux = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 3%;
    height: 100%;
    color: ${(props) => props.theme.SLOT_MACHINE_ZOO.backgroundNumber};
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.colorNumber};
`

export const MachineResultsCenter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 96%;
    height: 100%;
    justify-content: center;
    gap: 1.5rem;

    @media (max-width: 1000px) {
        gap: 1rem;
    }
    @media (max-width: 800px) {
        gap: 0.8rem;
    }
    @media (max-width: 600px) {
        gap: 0.6rem;
    }
    @media (max-width: 500px) {
        gap: 0.4rem;
    }
`

export const MachineCellAux = styled.div`
    height: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const MachineColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
`

export const MachineNumber = styled.div`
    font-size: 1.5rem;
    padding: 0.5rem;

    @media (max-width: 1250px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.3rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const MachineCell = styled.div`
    width: 100%;
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.backgroundMAchineResult};
    height: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ResultImg = styled.img`
    width: auto;
    height: auto;
    max-width: 9rem;

    @media (max-width: 550px) {
        max-width: 8rem;
    }

    @media (max-width: 450px) {
        max-width: 7rem;
    }

    @media (max-width: 400px) {
        max-width: 6.5rem;
    }

`

export const ErrorMensageData = styled.div`
    font-size: 1.5rem;
    padding: 0.5rem;
    color: ${(props) => props.theme.SLOT_MACHINE_ZOO.error_text_color};
    height: 20%;

    @media (max-width: 1250px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.3rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const BetButton = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
`

export const PlayImg = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;

    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }

    @media (max-width: 950px) {
        max-width: 2.5rem;
    }

    @media (max-width: 750px) {
        max-width: 3.5rem;
    }
`

export const ExitImg = styled.img`
    cursor: pointer;
    position: relative;
    width: auto;
    height: auto;
    max-width: 4rem;
    
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`

export const Action = styled.div`
    color: ${(props) => props.theme.SLOT_MACHINE_ZOO.white};
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 0.5rem;
`

export const Text = styled.text`
    font-family: "Zen Tokyo Zoo";
    font-size: 1.5rem;
    text-align: center;
    
    @media (max-width: 1250px) {
        font-size: 1.4rem;
    }
`


export const BoxValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lobster', cursive;
    font-size: 1.5rem;
    height: 2rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.gray};
    padding: 1rem;

    @media (max-width: 1250px) {
        font-size: 1.4rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.3rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const MachineBetOptions = styled.div`
    background-color: ${(props) => props.theme.SLOT_MACHINE_ZOO.gray};
    border-radius: 1rem;
    position: absolute;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    top: 40%;
    z-index: 1;
`

export const Blurn = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: ${(props) => props.theme.SLOT_MACHINE_ZOO.blur};
`

export const MachineBetContent = styled.div`
    display: flex;
`


export const MachineSelectOption = styled.div`

`

export const MachineOption = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

export const MachineOptionContent = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ExitButton = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
`

export const SelectValue = styled.text``

export const Select = styled.select`
    background-color: ${(props) => props.theme.SLOT_MACHINE_ZOO.gray};
    height: 2rem;
    width: 5rem;
    color: ${(props) => props.theme.SLOT_MACHINE_ZOO.white};
    padding-left: 10%;
    border-radius: 1rem;
`
export const SelectOption = styled.option`
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
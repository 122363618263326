import styled from 'styled-components'

export const Container = styled.div`
    @media (max-width: 750px) {
        height: 100%;
        margin-top: 6rem;
    }
`

export const Form = styled.div`
    border-radius: 10px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
    padding: 2rem;

    @media (max-width: 750px) {
        gap: 0.5rem;
        width: 90%;
    }
`

export const ContactContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;

    @media (max-width: 750px) {
        gap: 0.5rem;
    }
`

export const Description = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Roboto Mono;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 80%;


    @media (max-width: 850px) {
        font-size: 1.5rem;
    }

    @media (max-width: 750px) {
        font-size: 1.2rem;
    }

    @media (max-width: 550px) {
    }
`
export const DescriptionFrase = styled.div`
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: ${(props) => props.theme.COLORS.blackBlue};
    width: 80%;

    @media (max-width: 750px) {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    @media (max-width: 550px) {
        font-size: 1rem;
    }
`

export const Title = styled.div`
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.blackBlueBlue};

    @media (max-width: 550px) {
        font-size: 1.6rem;
    }

`

export const BaseTitle = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.darkBlue};

    @media (max-width: 750px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
`

export const FraseContainer = styled.div`
    color: ${(props) => props.theme.COLORS.darkBlue};
    text-align: center;
    text-shadow: 1px 1px 1px #022237;
    font-family: Roboto Mono;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 850px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
`

export const H1Container = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 850px) {
        font-size: 1.2rem;
        line-height: 1.7rem;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
`
export const H2Container = styled.div`
    color: ${(props) => props.theme.COLORS.blackBlue};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 750px) {
        font-size: 1.3rem;
        line-height: 1.7rem;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
`

export const TypeContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 25px;
    height: 4rem;
    width: 53rem;
    background: ${(props) => props.theme.shadow};

    input {
        all: unset;
        color: ${(props) => props.theme.white};
        font-size: 1.5rem;
        line-height: 25px;
        margin: 1rem;
        height: 10rem;
        width: 50rem;

        @media (max-width: 750px) {
            font-size: 1.3rem;
            line-height: 2rem;
        }
    }

    @media (max-width: 750px) {
        height: 2.5rem;
        width: 26rem;
    }
`

export const SendContainer = styled.div`
    button {
        all: unset;
        width: 80%;
        border-radius: 15px;
        border-style: solid;
        height: 4rem;
        width: 25rem;
        background: ${(props) => props.theme.COLORS.blackBlue};
        border: 1px solid ${(props) => props.theme.COLORS.white};
        font-size: 2rem;
        margin: 1rem;
        color: ${(props) => props.theme.COLORS.white};
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 750px) {
            text-align: center;
            padding-left: 1.9rem;
            height: 3.4rem;
        width: 20rem;
        }
    }
`

export const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    justify-content: flex-start;

    @media (max-width: 750px) {
        gap: 0rem;
        margin-top: 1rem;
    }
`

export const ContContainer = styled.div`
    color: ${(props) => props.theme.darkletter};
    font-family: 'Merriweather';
    font-size: 1.8rem;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 750px) {
        font-size: 1.4rem;
        line-height: 2rem;
    }
`

export const TitleBase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;

    @media (max-width: 750px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`

export const Data = styled.div`
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 750px) {
        font-size: 1rem;
        line-height: 1.8rem;
    }
`

export const DataContContainer = styled.div`
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    text-align: center;
    gap: 15px;

`

export const DivisionContainer = styled.div`
    gap: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Wrapinput = styled.div`
    width: 25rem;
    border-bottom: 2px solid #adadad;
    margin-bottom: 1.5rem;
    cursor: pointer;
    color: ${(props) => props.theme.COLORS.blackBlue};


    @media (max-width: 750px) {
        margin-bottom: 1.6rem;
    }

    input {
        height: auto;
        flex-wrap: wrap;
        all: unset;
        width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;
        color: ${(props) => props.theme.COLORS.blackBlue};
    }

    textarea {
        all: unset;
        width: 100%;
        height: 10rem;
        color: ${(props) => props.theme.COLORS.blackBlue};

    }

    .inputMask {
        width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        margin: 1rem;
        color: ${(props) => props.theme.COLORS.blackBlue};
    }

    @media (max-width: 750px) {
        line-height: 2rem;
        font-size: 1.2rem;
    }
`
import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.SLOT_MACHINE_777.background};
    background-size: cover;
    height: 100vh;
    align-items: center;
`

export const Blurn = styled.div`
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.SLOT_MACHINE_777.blur};
    display: flex;
    justify-content: center;
    align-items: center;
    `

export const FlyingtContent = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    width: 35%;
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    display: flex;
    height: 90%;
    width: 40%;
    flex-direction: column;

    @media (max-width: 1350px) {
        width: 50%;
    }

    @media (max-width: 1150px) {
        width: 60%;
    }

    @media (max-width: 1050px) {
        width: 70%;
    }

    @media (max-width: 950px) {
        width: 60%;
    }

    @media (max-width: 850px) {
        width: 60%;
    }

    @media (max-width: 750px) {
        width: 75%;
    }

    @media (max-width: 550px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 90%;
    }

    @media (max-width: 450px) {
        width: 95%;
    }

`

export const SlotMachineTop = styled.div`
    flex-direction: row;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 20%;
    background: ${(props) => props.theme.SLOT_MACHINE_777.machine_aux_background};
    border-radius: 50rem 50rem 0 0;
    border-left: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    border-right: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    border-top: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    overflow: hidden;
`

export const SlotMachine = styled.div`
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 55%;
    background: ${(props) => props.theme.SLOT_MACHINE_777.machine_background};
    border-style: double;
    border-left: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    border-right: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
`

export const SlotMachineButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25%;
    background: ${(props) => props.theme.SLOT_MACHINE_777.machine_aux_background};
    border-radius: 0 0 1rem 1rem;
    border-left: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    border-right: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    border-bottom: 2px solid ${(props) => props.theme.SLOT_MACHINE_777.greenNeon};
    flex-direction: column;
`

export const GrennBall = styled.div`
    height: 50%;
    width: 13%;
    border-radius: 500rem;
    box-shadow: 0px 0px 20px 5px #39FF14;
    background: ${(props) => props.theme.SLOT_MACHINE_777.grennBall};
`

export const PurpleBall = styled.div`
    height: 50%;
    width: 13%;
    border-radius: 500rem;
    box-shadow: 0px 0px 20px 5px #8A2BE2;
    background: ${(props) => props.theme.SLOT_MACHINE_777.purpleBall};
`

export const YellowBall = styled.div`
    height: 50%;
    width: 13%;
    border-radius: 500rem;
    box-shadow: 0px 0px 20px 5px #FFFF00;
    background: ${(props) => props.theme.SLOT_MACHINE_777.yellowBall};
`

export const BlueBall = styled.div`
    height: 50%;
    width: 13%;
    border-radius: 500rem;
    box-shadow: 0px 0px 20px 5px #00BFFF;
    background: ${(props) => props.theme.SLOT_MACHINE_777.blueBall};
`

export const PinkBall = styled.div`
    height: 50%;
    width: 13%;
    border-radius: 500rem;
    box-shadow: 0px 0px 20px 5px #FF69B4;
    background: ${(props) => props.theme.SLOT_MACHINE_777.pinkBall};
`

export const ContentSeven = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-evenly;
`

export const SevenImg = styled.img`
    height: 100%;
    border-radius: 1rem;
`

export const MachineResults = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    height: 80%;
    width: 100%;
    gap: 1rem;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    height: 100%;
    width: 27%;
`

export const Cell = styled.div`
    background: ${(props) => props.theme.SLOT_MACHINE_777.white};
    height: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CellValidumn = styled.div`
    background: ${(props) => props.theme.SLOT_MACHINE_777.beige};
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ResultImg = styled.img`
    height: 80%;
`

export const ButtonsContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 50%;
    justify-content: center;
`

export const Action = styled.div`
    color: ${(props) => props.theme.SLOT_MACHINE_777.white};
    width: 29%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 0.5rem;
`

export const PlayAction = styled.div`
    color: ${(props) => props.theme.SLOT_MACHINE_777.white};
    width: 13%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 0.5rem;
`

export const Text = styled.text`
    font-family: 'Lobster', cursive;
    font-size: 23px;
    text-align: center;
`

export const TextWin = styled.text`
    font-family: 'Lobster', cursive;
    font-size: 23px;
    text-align: center;

    @media (max-width: 985px) {
        margin-top: 1.8rem;
    }

    @media (max-width: 909px) {
        margin-top: 0rem;
    }

    @media (max-width: 821px) {
        margin-top: 1.8rem;
    }

    @media (max-width: 750px) {
        margin-top: 0rem;
    }
    
`

export const TextWallet = styled.text`
    font-family: 'Lobster', cursive;
    font-size: 23px;

    @media (max-width: 985px) {
        margin-top: 1.8rem;
    }

    @media (max-width: 909px) {
        margin-top: 0rem;
    }

    @media (max-width: 821px) {
        margin-top: 1.8rem;
    }
    
`

export const InputValue = styled.input`
    all: unset;
    font-family: 'Lobster', cursive;
    font-size: 23px;
    width: 90%;
    height: 3rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.SLOT_MACHINE_777.gray};
    color: ${(props) => props.theme.SLOT_MACHINE_777.white};
    text-align: center;
`

export const BoxValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lobster', cursive;
    font-size: 23px;
    width: 100%;
    height: 3rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.SLOT_MACHINE_777.gray};
`

export const ErrorMensageData = styled.div`
    font-family: 'Lobster', cursive;
    font-size: 23px;
    padding: 0.5rem;
    color: ${(props) => props.theme.SLOT_MACHINE_777.error_text_color};
    height: 20%;
`

export const BetButton = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-radius: 1rem;
    cursor: pointer;
`

export const BetImg = styled.img`
    cursor: pointer;
    margin-top: 1.5rem;
`

const floatAnimation7 = keyframes`
    0% {
        background-color: linear-gradient(180deg, #FF1493 0%, #ffd70000 100%);
        box-shadow: 0px 0px 20px 5px #0B610B;
    }
    25% {
        background-color: linear-gradient(180deg, #1E90FF 0%, #ffd70000 100%);
        box-shadow: 0px 0px 20px 5px #FF1493;
    }
    50% {
        background-color: linear-gradient(180deg, #FFD700 0%, #ffd70000 100%);
        box-shadow: 0px 0px 20px 5px #1E90FF;
    }
    75% {
        background-color: linear-gradient(180deg, #4B0082 0%, #ffd70000 100%);
        box-shadow: 0px 0px 20px 5px #FFD700;
    }
    100% {
        background-color: linear-gradient(180deg, #0B610B 0%, #ffd70000 100%);
        box-shadow: 0px 0px 20px 5px #4B0082;
    }
`;


export const FlyingtImg7 = styled.img`
    position: absolute;
    animation: ${floatAnimation7} 6s infinite linear;
    height: 10rem;
    width: 10rem;
    z-index: 999;
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
    cursor: pointer;
`

export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-image: url('/background21V2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
`

export const Blurn = styled.div`
height: 100vh;
width: 100%;
    background: ${(props) => props.theme.BLACKJACK_V2.blur};
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
`

export const BorderTable = styled.div`
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    background-image: url('/borderTable21V2.png');
    height: 72%;
    width: 72%;
    border-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${(props) => props.theme.BLACKJACK_V2.black};
    padding: 1rem;

    @media (max-width: 1400px) {
        height: 80%;
        width: 80%;
    }
    @media (max-width: 1300px) {
        width: 87%;
    }
    @media (max-width: 1150px) {
        width: 90%;
    }
    @media (max-width: 1050px) {
        width: 92%;
    }
    @media (max-width: 950px) {
        width: 95%;
        border-radius: 18rem;
    }
    @media (max-width: 800px) {
        width: 96%;
        border-radius: 16rem;
    }
    @media (max-width: 700px) {
        border-radius: 15rem;
    }
    @media (max-width: 600px) {
        border-radius: 10rem;
    }
`

export const Table = styled.div`
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.BLACKJACK_V2.table_background};
    border: 3px solid ${(props) => props.theme.BLACKJACK_V2.table_tissue_border};
    border-radius: 20rem;

    @media (max-width: 950px) {
        border-radius: 18rem;
    }
    @media (max-width: 800px) {
        border-radius: 16rem;
    }
    @media (max-width: 700px) {
        border-radius: 15rem;
    }
    @media (max-width: 600px) {
        border-radius: 10rem;
    }
`

export const TableTexture = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/tableTexture21V2.png');
    border-radius: 20rem;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

export const SessionInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const InfoMessage = styled.text`
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    font-family: Black Han Sans;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
`

export const PokerChips = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 60%;
    flex-wrap: wrap;
`

export const PokerChipButton = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
`

export const PlayerOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 40%;
    width: 100%;
`

export const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 60%;
    justify-content: space-evenly;
`

export const PlayerActions = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
`

export const BetInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    background: ${(props) => props.theme.BLACKJACK_V2.gray};
    border-radius: 1rem;
    padding: 0.5rem;
`

export const PlayerActionInfos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
`

export const PlayerActionButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
`

export const BetInfoMessage = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;

    @media (max-width: 1400px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.2rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const ErrorMensageData = styled.div`
    padding: 0.5rem;
    color: ${(props) => props.theme.BLACKJACK_V2.error_text_color};
`

export const BetButton = styled.button`
    border-radius: 1rem;
    cursor: pointer;
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    background: ${(props) => props.theme.BLACKJACK_V2.gray};
    font-family: Black Han Sans;
    font-size: 1.8rem;

    @media (max-width: 1400px) {
        font-size: 1.7rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.6rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.5rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const ActionButton = styled.button`
    border-radius: 1rem;
    cursor: pointer;
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    background: ${(props) => props.theme.BLACKJACK_V2.gray};
`

export const RestartButton = styled.button`
    border-radius: 1rem;
    cursor: pointer;
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    background: ${(props) => props.theme.BLACKJACK_V2.gray};
`

export const HomeCards = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    align-items: center;
    width: 22rem;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-content: center;
`

export const PlayerCards = styled.div`
    display: flex;
    align-items: center;
    min-width: 22rem;
    padding: 0.5rem;
    justify-content: center;
`

export const Cards = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
`

export const Hand = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
`

export const HandButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
`

export const Card = styled.img`
    min-width: 3rem;
    height: 7rem;
    border-radius: 0.5rem;
`

export const CheapImg = styled.img`
    min-width: 3rem;
    border-radius: 0.5rem;
    position: absolute;
    right: 30%;
    top: 10%;
    height: 11rem;

    @media (max-width: 1900px) {
        right: 28%;
    }
    @media (max-width: 1800px) {
        right: 26%;
    }
    @media (max-width: 1700px) {
        right: 24%;
    }
    @media (max-width: 1600px) {
        right: 22%;
    }
    @media (max-width: 1500px) {
        right: 20%;
    }
    @media (max-width: 1400px) {
        right: 18%;
    }
    @media (max-width: 1300px) {
        right: 16%;
    }
    @media (max-width: 1250px) {
        right: 15%;
    }
    @media (max-width: 1100px) {
        right: 14%;
    }
    @media (max-width: 1100px) {
        top: 8%;
    }
    @media (max-width: 850px) {
        top: 45%;
    }
    @media (max-width: 500px) {
        display: none;
    }
`

export const ResultMessage = styled.text`
    color: ${(props) => props.theme.BLACKJACK_V2.white};
    font-family: Black Han Sans;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;

    @media (max-width: 1400px) {
        font-size: 1.8rem;
    }
    @media (max-width: 1250px) {
        font-size: 1.7rem;
    }
    @media (max-width: 1100px) {
        font-size: 1.6rem;
    }
    @media (max-width: 750px) {
        font-size: 1.2rem;
    }
`

export const ButtonMusic = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.COLORS.transparent};
`
export const SoundImage = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 4rem;
    @media (max-width: 1050px) {
        max-width: 3.5rem;
    }
    @media (max-width: 950px) {
        max-width: 3rem;
    }
    @media (max-width: 750px) {
        max-width: 2rem;
    }
`;

export const ErrorMessageData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    padding: 0.5rem;
`
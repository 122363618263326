import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
`

export const Button = styled.button`
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    
    img {
        width: auto;
        height: auto;
        max-width: 2rem;
    }
    
`
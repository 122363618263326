import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GetSessionInfos } from '../../../hooks/useSessionInfo';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { ISessionInfos } from '../../../types';

import {
    BetButton,
    BetInfo,
    BetInfoMessage,
    Blurn,
    BorderTable, ButtonMusic,
    Card,
    CardButton,
    Cards,
    CardsContainer,
    ClearBetValue,
    Container,
    ErrorMensageData, ErrorMessageData,
    HomeCards,
    InfoMessage,
    PlayerActions,
    PlayerCards,
    PlayerOptions,
    PokerChipButton,
    PokerChips,
    RestartButton,
    ResultMessage,
    SessionInfos, SoundImage,
    StayButton,
    Table,
    TableTexture,
} from './styles'

import PokerChip1 from '../../../assets/games/blackjack/pokerChip/pokerChip1.svg'
import PokerChip2 from '../../../assets/games/blackjack/pokerChip/pokerChip2.svg'
import PokerChip3 from '../../../assets/games/blackjack/pokerChip/pokerChip3.svg'
import PokerChip4 from '../../../assets/games/blackjack/pokerChip/pokerChip4.svg'
import PokerChip5 from '../../../assets/games/blackjack/pokerChip/pokerChip5.svg'
import PokerChip6 from '../../../assets/games/blackjack/pokerChip/pokerChip6.svg'
import PokerChip7 from '../../../assets/games/blackjack/pokerChip/pokerChip7.svg'
import PokerChip8 from '../../../assets/games/blackjack/pokerChip/pokerChip8.svg'
import PokerChip9 from '../../../assets/games/blackjack/pokerChip/pokerChip9.svg'
import BackCard from '../../../assets/games/blackjack/v1/backCard.png'
import Club1 from '../../../assets/games/blackjack/cards/club/club1.svg'
import Club2 from '../../../assets/games/blackjack/cards/club/club2.svg'
import Club3 from '../../../assets/games/blackjack/cards/club/club3.svg'
import Club4 from '../../../assets/games/blackjack/cards/club/club4.svg'
import Club5 from '../../../assets/games/blackjack/cards/club/club5.svg'
import Club6 from '../../../assets/games/blackjack/cards/club/club6.svg'
import Club7 from '../../../assets/games/blackjack/cards/club/club7.svg'
import Club8 from '../../../assets/games/blackjack/cards/club/club8.svg'
import Club9 from '../../../assets/games/blackjack/cards/club/club9.svg'
import Club10 from '../../../assets/games/blackjack/cards/club/club10.svg'
import Club11 from '../../../assets/games/blackjack/cards/club/club11.svg'
import Club12 from '../../../assets/games/blackjack/cards/club/club12.svg'
import Club13 from '../../../assets/games/blackjack/cards/club/club13.svg'
import Diamond1 from '../../../assets/games/blackjack/cards/diamond/diamond1.svg'
import Diamond2 from '../../../assets/games/blackjack/cards/diamond/diamond2.svg'
import Diamond3 from '../../../assets/games/blackjack/cards/diamond/diamond3.svg'
import Diamond4 from '../../../assets/games/blackjack/cards/diamond/diamond4.svg'
import Diamond5 from '../../../assets/games/blackjack/cards/diamond/diamond5.svg'
import Diamond6 from '../../../assets/games/blackjack/cards/diamond/diamond6.svg'
import Diamond7 from '../../../assets/games/blackjack/cards/diamond/diamond7.svg'
import Diamond8 from '../../../assets/games/blackjack/cards/diamond/diamond8.svg'
import Diamond9 from '../../../assets/games/blackjack/cards/diamond/diamond9.svg'
import Diamond10 from '../../../assets/games/blackjack/cards/diamond/diamond10.svg'
import Diamond11 from '../../../assets/games/blackjack/cards/diamond/diamond11.svg'
import Diamond12 from '../../../assets/games/blackjack/cards/diamond/diamond12.svg'
import Diamond13 from '../../../assets/games/blackjack/cards/diamond/diamond13.svg'
import Heart1 from '../../../assets/games/blackjack/cards/heart/heart1.svg'
import Heart2 from '../../../assets/games/blackjack/cards/heart/heart2.svg'
import Heart3 from '../../../assets/games/blackjack/cards/heart/heart3.svg'
import Heart4 from '../../../assets/games/blackjack/cards/heart/heart4.svg'
import Heart5 from '../../../assets/games/blackjack/cards/heart/heart5.svg'
import Heart6 from '../../../assets/games/blackjack/cards/heart/heart6.svg'
import Heart7 from '../../../assets/games/blackjack/cards/heart/heart7.svg'
import Heart8 from '../../../assets/games/blackjack/cards/heart/heart8.svg'
import Heart9 from '../../../assets/games/blackjack/cards/heart/heart9.svg'
import Heart10 from '../../../assets/games/blackjack/cards/heart/heart10.svg'
import Heart11 from '../../../assets/games/blackjack/cards/heart/heart11.svg'
import Heart12 from '../../../assets/games/blackjack/cards/heart/heart12.svg'
import Heart13 from '../../../assets/games/blackjack/cards/heart/heart13.svg'
import Spade1 from '../../../assets/games/blackjack/cards/spade/spade1.svg'
import Spade2 from '../../../assets/games/blackjack/cards/spade/spade2.svg'
import Spade3 from '../../../assets/games/blackjack/cards/spade/spade3.svg'
import Spade4 from '../../../assets/games/blackjack/cards/spade/spade4.svg'
import Spade5 from '../../../assets/games/blackjack/cards/spade/spade5.svg'
import Spade6 from '../../../assets/games/blackjack/cards/spade/spade6.svg'
import Spade7 from '../../../assets/games/blackjack/cards/spade/spade7.svg'
import Spade8 from '../../../assets/games/blackjack/cards/spade/spade8.svg'
import Spade9 from '../../../assets/games/blackjack/cards/spade/spade9.svg'
import Spade10 from '../../../assets/games/blackjack/cards/spade/spade10.svg'
import Spade11 from '../../../assets/games/blackjack/cards/spade/spade11.svg'
import Spade12 from '../../../assets/games/blackjack/cards/spade/spade12.svg'
import Spade13 from '../../../assets/games/blackjack/cards/spade/spade13.svg'
import dice from '../../../assets/audio/dice.mp3';
import playMusic from '../../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../../assets/audio/images/pauseMusicWhite.png';
import { useTranslation } from "react-i18next";
import { GetCasinoAccess } from "../../../hooks/useAuth";
import { URLs } from "../../../services/service.config";

const BlackjackV1Room = () => {
    const { t } = useTranslation();
    const { casino, session } = useParams()
    const [stompClient, setStompClient] = useState<any>(null);
    const [privateChat, setPrivateChat] = useState("");
    const [privateResultMessage, setPrivateResultMessage] = useState("");
    const [canFinishRound, setCanFinishRound] = useState(true);
    const [canFlipCard, setCanFlipCard] = useState(true);
    const [canRestart, setCanRestart] = useState(true);
    const [canBet, setCanBet] = useState(true);
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [homeCards, setHomeCards] = useState([{
        suits: "",
        color: "",
        number: ""
    },
    {
        suits: "",
        color: "",
        number: ""
    }]);
    const [playerCards, setPlayerCards] = useState([{
        suits: "",
        color: "",
        number: ""
    },
    {
        suits: "",
        color: "",
        number: ""
    }]);
    const [betValue, setBetValue] = useState(0);
    const [betId, setBetId] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    let audioElement: HTMLAudioElement | null = null;
    const [casinoAccess, setCasinoAccess] = useState(false);

    useEffect(() => {
        FindCasinoAccess();
    }, [])

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Twenty One", session!));
    }

    useEffect(() => {
        casinoAccess && FindAllData();
    }, [casinoAccess]);

    function FindAllData() {
        setStompClient(over(new SockJS(URLs.backUrl + URLs.wsBlackjackV1)));
        FindSessionInfos();
    }

    async function FindSessionInfos() {
        await setSessionInfos(await GetSessionInfos(session))
    }

    useEffect(() => {
        if (sessionInfos.playerId != undefined && sessionInfos.isValid) {
            connect();
        }
    }, [sessionInfos.playerId])

    const connect = () => {
        stompClient.connect({}, onConnected, onError);
    }

    const onConnected = async () => {
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-blackjack', onPrivateMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/private-result-blackjack', onPrivateResultMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFinishBlackjackRound', onCanFinishRoundMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canFlipBlackjackCard', onCanFlipBlackjackMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/blackjackSessionInfos', onSessionInfosMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/canBlackjackRestart', onCanRestartMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/betBlackjack', onBetMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/homeCards', onHomeCardsMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/playerCards', onPlayerCardsMessage);
        stompClient.subscribe('/user/' + sessionInfos.playerId + '/isValidBlackjack', onIsValidMessage);
        setCanBet(false);
    }

    const onError = (err: any) => {
    }

    const onPrivateMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateChat(payloadData.message);
    }

    const onPrivateResultMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPrivateResultMessage(payloadData.message);
    }

    const onCanFinishRoundMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanFinishRound(payloadData);
    }

    const onCanFlipBlackjackMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanFlipCard(payloadData);
    }

    const onSessionInfosMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos(payloadData);
    }

    const onCanRestartMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setCanRestart(payloadData);
    }

    const onBetMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setBetId(payloadData.betId);
        setBetValue(payloadData.amountBet);
        setCanBet(payloadData.isBet);
        setPrivateChat("")
    }

    const onHomeCardsMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setHomeCards(payloadData.blackjackCardList);
    }

    const onPlayerCardsMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setPlayerCards(payloadData.blackjackCardList);
    }

    const onIsValidMessage = (payload: any) => {
        var payloadData = JSON.parse(payload.body);
        setSessionInfos({ ...sessionInfos, "isValid": payloadData });
    }

    const handleBetValue = (event: any) => {
        const id = event.target.id;
        if (id == 0) {
            setBetValue(0);
        }
        if (id == 1) {
            setBetValue(betValue + 0.25);
        }
        if (id == 2) {
            setBetValue(betValue + 0.5);
        }
        if (id == 3) {
            setBetValue(betValue + 1);
        }
        if (id == 4) {
            setBetValue(betValue + 2.5);
        }
        if (id == 5) {
            setBetValue(betValue + 5);
        }
        if (id == 6) {
            setBetValue(betValue + 10);
        }
        if (id == 7) {
            setBetValue(betValue + 50);
        }
        if (id == 8) {
            setBetValue(betValue + 100);
        }
        if (id == 9) {
            setBetValue(betValue + 200);
        }
    }

    async function sendBet() {
        var bet = {
            amount: sessionInfos.amount,
            amountBet: betValue,
            isBet: false,
            user: casino,
            sessionId: session,
            playerId: sessionInfos.playerId!,
        };
        stompClient.send("/app/betBlackjack", {}, JSON.stringify(bet));
    }

    async function sendFinishRound() {
        var finishRound = {
            betId: betId,
            user: casino
        };
        stompClient.send("/app/finishBlackjackRound", {}, JSON.stringify(finishRound));
    }

    async function sendFlipCard() {
        var flipCard = {
            user: casino,
            betId: betId,
            playerId: sessionInfos.playerId!,
        };
        stompClient.send("/app/flipBlackjackCard", {}, JSON.stringify(flipCard));
    }

    async function sendRestart() {
        setCanBet(false);
        setCanRestart(true);
        setPrivateResultMessage("");
        setHomeCards([{
            suits: "",
            color: "",
            number: ""
        },
        {
            suits: "",
            color: "",
            number: ""
        }]);
        setPlayerCards([{
            suits: "",
            color: "",
            number: ""
        },
        {
            suits: "",
            color: "",
            number: ""
        }]);
    }

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <Container>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Blurn>
                    <audio style={{ display: "none" }} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg" />
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                            alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Blurn>
            ) : (
                <Blurn>
                    <audio style={{ display: "none" }} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg" />
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <SoundImage src={isPlaying ? playMusic : pauseMusic}
                            alt={isPlaying ? 'Play' : 'Pause'}></SoundImage>
                    </ButtonMusic>
                    <BorderTable>
                        <Table>
                            <TableTexture style={{ justifyContent: !canBet ? "space-between" : "center" }}>
                                <CardsContainer>
                                    <HomeCards>
                                        <Cards style={{
                                            position: "relative",
                                            width: playerCards !== undefined ? (((playerCards?.length - 1) * 1.5) + 5).toString().concat("rem") : "",
                                            height: playerCards !== undefined ? (((playerCards?.length - 1) * 0.6) + 7).toString().concat("rem") : ""
                                        }}>
                                            {homeCards.map((card, index) => (
                                                <Card key={index} style={{
                                                    position: "absolute",
                                                    zIndex: index,
                                                    left: (index === 0 ? 0 : (index * 2)).toString().concat("rem"),
                                                    top: (index === 0 ? 0 : (index * 0.6)).toString().concat("rem")
                                                }}
                                                    src={card.number == "A" && card.suits == "CLUB" ? Club1 : card.number == "TWO" && card.suits == "CLUB" ? Club2 : card.number == "THREE" && card.suits == "CLUB" ? Club3 : card.number == "FOUR" && card.suits == "CLUB" ? Club4 : card.number == "FIVE" && card.suits == "CLUB" ? Club5 : card.number == "SIX" && card.suits == "CLUB" ? Club6 : card.number == "SEVEN" && card.suits == "CLUB" ? Club7 : card.number == "EIGHT" && card.suits == "CLUB" ? Club8 : card.number == "NINE" && card.suits == "CLUB" ? Club9 : card.number == "TEN" && card.suits == "CLUB" ? Club10 : card.number == "J" && card.suits == "CLUB" ? Club11 : card.number == "Q" && card.suits == "CLUB" ? Club12 : card.number == "K" && card.suits == "CLUB" ? Club13 :
                                                        card.number == "A" && card.suits == "DIAMOND" ? Diamond1 : card.number == "TWO" && card.suits == "DIAMOND" ? Diamond2 : card.number == "THREE" && card.suits == "DIAMOND" ? Diamond3 : card.number == "FOUR" && card.suits == "DIAMOND" ? Diamond4 : card.number == "FIVE" && card.suits == "DIAMOND" ? Diamond5 : card.number == "SIX" && card.suits == "DIAMOND" ? Diamond6 : card.number == "SEVEN" && card.suits == "DIAMOND" ? Diamond7 : card.number == "EIGHT" && card.suits == "DIAMOND" ? Diamond8 : card.number == "NINE" && card.suits == "DIAMOND" ? Diamond9 : card.number == "TEN" && card.suits == "DIAMOND" ? Diamond10 : card.number == "J" && card.suits == "DIAMOND" ? Diamond11 : card.number == "Q" && card.suits == "DIAMOND" ? Diamond12 : card.number == "K" && card.suits == "DIAMOND" ? Diamond13 :
                                                            card.number == "A" && card.suits == "HEART" ? Heart1 : card.number == "TWO" && card.suits == "HEART" ? Heart2 : card.number == "THREE" && card.suits == "HEART" ? Heart3 : card.number == "FOUR" && card.suits == "HEART" ? Heart4 : card.number == "FIVE" && card.suits == "HEART" ? Heart5 : card.number == "SIX" && card.suits == "HEART" ? Heart6 : card.number == "SEVEN" && card.suits == "HEART" ? Heart7 : card.number == "EIGHT" && card.suits == "HEART" ? Heart8 : card.number == "NINE" && card.suits == "HEART" ? Heart9 : card.number == "TEN" && card.suits == "HEART" ? Heart10 : card.number == "J" && card.suits == "HEART" ? Heart11 : card.number == "Q" && card.suits == "HEART" ? Heart12 : card.number == "K" && card.suits == "HEART" ? Heart13 :
                                                                card.number == "A" && card.suits == "SPADE" ? Spade1 : card.number == "TWO" && card.suits == "SPADE" ? Spade2 : card.number == "THREE" && card.suits == "SPADE" ? Spade3 : card.number == "FOUR" && card.suits == "SPADE" ? Spade4 : card.number == "FIVE" && card.suits == "SPADE" ? Spade5 : card.number == "SIX" && card.suits == "SPADE" ? Spade6 : card.number == "SEVEN" && card.suits == "SPADE" ? Spade7 : card.number == "EIGHT" && card.suits == "SPADE" ? Spade8 : card.number == "NINE" && card.suits == "SPADE" ? Spade9 : card.number == "TEN" && card.suits == "SPADE" ? Spade10 : card.number == "J" && card.suits == "SPADE" ? Spade11 : card.number == "Q" && card.suits == "SPADE" ? Spade12 : card.number == "K" && card.suits == "SPADE" ? Spade13 :
                                                                    BackCard} alt='Back card'>
                                                </Card>
                                            ))}
                                        </Cards>
                                    </HomeCards>
                                    <ResultMessage>
                                        {t(privateResultMessage)}
                                    </ResultMessage>
                                    <PlayerCards>
                                        <Cards style={{
                                            position: "relative",
                                            width: playerCards !== undefined ? (((playerCards?.length - 1) * 1.5) + 5).toString().concat("rem") : "",
                                            height: playerCards !== undefined ? (((playerCards?.length - 1) * 0.6) + 7).toString().concat("rem") : ""
                                        }}>
                                            {playerCards.map((card, index) => (
                                                <Card key={index} style={{
                                                    position: "absolute",
                                                    zIndex: index,
                                                    left: (index === 0 ? 0 : (index * 2)).toString().concat("rem"),
                                                    top: (index === 0 ? 0 : (index * 0.6)).toString().concat("rem")
                                                }}
                                                    src={card.number == "A" && card.suits == "CLUB" ? Club1 : card.number == "TWO" && card.suits == "CLUB" ? Club2 : card.number == "THREE" && card.suits == "CLUB" ? Club3 : card.number == "FOUR" && card.suits == "CLUB" ? Club4 : card.number == "FIVE" && card.suits == "CLUB" ? Club5 : card.number == "SIX" && card.suits == "CLUB" ? Club6 : card.number == "SEVEN" && card.suits == "CLUB" ? Club7 : card.number == "EIGHT" && card.suits == "CLUB" ? Club8 : card.number == "NINE" && card.suits == "CLUB" ? Club9 : card.number == "TEN" && card.suits == "CLUB" ? Club10 : card.number == "J" && card.suits == "CLUB" ? Club11 : card.number == "Q" && card.suits == "CLUB" ? Club12 : card.number == "K" && card.suits == "CLUB" ? Club13 :
                                                        card.number == "A" && card.suits == "DIAMOND" ? Diamond1 : card.number == "TWO" && card.suits == "DIAMOND" ? Diamond2 : card.number == "THREE" && card.suits == "DIAMOND" ? Diamond3 : card.number == "FOUR" && card.suits == "DIAMOND" ? Diamond4 : card.number == "FIVE" && card.suits == "DIAMOND" ? Diamond5 : card.number == "SIX" && card.suits == "DIAMOND" ? Diamond6 : card.number == "SEVEN" && card.suits == "DIAMOND" ? Diamond7 : card.number == "EIGHT" && card.suits == "DIAMOND" ? Diamond8 : card.number == "NINE" && card.suits == "DIAMOND" ? Diamond9 : card.number == "TEN" && card.suits == "DIAMOND" ? Diamond10 : card.number == "J" && card.suits == "DIAMOND" ? Diamond11 : card.number == "Q" && card.suits == "DIAMOND" ? Diamond12 : card.number == "K" && card.suits == "DIAMOND" ? Diamond13 :
                                                            card.number == "A" && card.suits == "HEART" ? Heart1 : card.number == "TWO" && card.suits == "HEART" ? Heart2 : card.number == "THREE" && card.suits == "HEART" ? Heart3 : card.number == "FOUR" && card.suits == "HEART" ? Heart4 : card.number == "FIVE" && card.suits == "HEART" ? Heart5 : card.number == "SIX" && card.suits == "HEART" ? Heart6 : card.number == "SEVEN" && card.suits == "HEART" ? Heart7 : card.number == "EIGHT" && card.suits == "HEART" ? Heart8 : card.number == "NINE" && card.suits == "HEART" ? Heart9 : card.number == "TEN" && card.suits == "HEART" ? Heart10 : card.number == "J" && card.suits == "HEART" ? Heart11 : card.number == "Q" && card.suits == "HEART" ? Heart12 : card.number == "K" && card.suits == "HEART" ? Heart13 :
                                                                card.number == "A" && card.suits == "SPADE" ? Spade1 : card.number == "TWO" && card.suits == "SPADE" ? Spade2 : card.number == "THREE" && card.suits == "SPADE" ? Spade3 : card.number == "FOUR" && card.suits == "SPADE" ? Spade4 : card.number == "FIVE" && card.suits == "SPADE" ? Spade5 : card.number == "SIX" && card.suits == "SPADE" ? Spade6 : card.number == "SEVEN" && card.suits == "SPADE" ? Spade7 : card.number == "EIGHT" && card.suits == "SPADE" ? Spade8 : card.number == "NINE" && card.suits == "SPADE" ? Spade9 : card.number == "TEN" && card.suits == "SPADE" ? Spade10 : card.number == "J" && card.suits == "SPADE" ? Spade11 : card.number == "Q" && card.suits == "SPADE" ? Spade12 : card.number == "K" && card.suits == "SPADE" ? Spade13 :
                                                                    BackCard} alt='Back card'>
                                                </Card>
                                            ))}
                                        </Cards>
                                    </PlayerCards>
                                </CardsContainer>
                                <PlayerOptions style={{ display: canBet ? "none" : "flex" }}>
                                    <SessionInfos>
                                        <InfoMessage>
                                            {sessionInfos.playerName}
                                        </InfoMessage>
                                        <InfoMessage>
                                            {betValue.toFixed(2)}
                                        </InfoMessage>
                                    </SessionInfos>
                                    <PokerChips>
                                        <PokerChipButton id='1' onClick={handleBetValue} src={PokerChip1}
                                            alt='0.25'></PokerChipButton>
                                        <PokerChipButton id='2' onClick={handleBetValue} src={PokerChip2}
                                            alt='0.50'></PokerChipButton>
                                        <PokerChipButton id='3' onClick={handleBetValue} src={PokerChip3}
                                            alt='1'></PokerChipButton>
                                        <PokerChipButton id='4' onClick={handleBetValue} src={PokerChip4}
                                            alt='2.50'></PokerChipButton>
                                        <PokerChipButton id='5' onClick={handleBetValue} src={PokerChip5}
                                            alt='5.00'></PokerChipButton>
                                        <PokerChipButton id='6' onClick={handleBetValue} src={PokerChip6}
                                            alt='10'></PokerChipButton>
                                        <PokerChipButton id='7' onClick={handleBetValue} src={PokerChip7}
                                            alt='50'></PokerChipButton>
                                        <PokerChipButton id='8' onClick={handleBetValue} src={PokerChip8}
                                            alt='100'></PokerChipButton>
                                        <PokerChipButton id='9' onClick={handleBetValue} src={PokerChip9}
                                            alt='200'></PokerChipButton>
                                    </PokerChips>
                                </PlayerOptions>
                            </TableTexture>
                        </Table>
                    </BorderTable>
                    <PlayerActions>
                        <BetInfo>
                            <BetInfoMessage>
                                {sessionInfos.amount.toFixed(2)}
                            </BetInfoMessage>
                            <BetInfoMessage>
                                {t("balance")}
                            </BetInfoMessage>
                        </BetInfo>
                        <BetInfo>
                            <BetInfoMessage>
                                {(betValue * 2).toFixed(2)}
                            </BetInfoMessage>
                            <BetInfoMessage>
                                {t("award")}
                            </BetInfoMessage>
                        </BetInfo>
                    </PlayerActions>
                    <PlayerActions>
                        <StayButton hidden={canFinishRound} disabled={canFinishRound}
                                    onClick={sendFinishRound}>
                            {t("stay")}
                        </StayButton>
                        <ClearBetValue id='0' onClick={handleBetValue}>
                            {t("resetBetValue")}
                        </ClearBetValue>
                        <CardButton hidden={canFlipCard} disabled={canFlipCard} onClick={sendFlipCard}>
                            {t("oneMoreLetter")}
                        </CardButton>
                        <BetButton hidden={canBet} disabled={canBet} onClick={sendBet}>
                            {t("bet")}
                        </BetButton>
                        <RestartButton hidden={canRestart} disabled={canRestart}
                            onClick={sendRestart}>
                            {t("restart")}
                        </RestartButton>
                    </PlayerActions>
                    <ErrorMensageData>{t(privateChat)}</ErrorMensageData>
                </Blurn>
            )}
        </Container>
    )
}

export default BlackjackV1Room
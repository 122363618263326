import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {GetSessionInfos} from '../../hooks/useSessionInfo';
import {Bet} from '../../hooks/useDice';
import {IDiceBet, IDiceResult, ISessionInfos} from '../../types';
import Rolar from '../../assets/games/dice/rolar.png';
import dice from '../../assets/audio/dice.mp3';
import playMusic from '../../assets/audio/images/playMusicWhite.png';
import pauseMusic from '../../assets/audio/images/pauseMusicWhite.png';

import {
    ButtonBet,
    ButtonContainer,
    ButtonDice,
    Container,
    Imagens,
    Infos,
    LastResults,
    Mensage,
    MensageData,
    Menu,
    Separation,
    ProgressBarContainer,
    LastResultsContainer,
    Percentages,
    Blurn,
    GameContainer,
    Actions,
    ButtonMusic,
    ErrorMessageData,
    PercentageDiv,
    PercentageValue,
    ProgressBarSelector,
    ProgressBarRight,
    ProgressBarLeft
} from './styles';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {GetCasinoAccess} from "../../hooks/useAuth";


const DiceV1Room = () => {
    const {t} = useTranslation();
    const {casino, session} = useParams();
    const [privateChat, setPrivateChat] = useState("");
    const [lastResult, setLastResult] = useState("");
    const [lastTwentyResults, setLastTwentyResults] = useState<string[]>([]);
    const [sessionInfos, setSessionInfos] = useState<ISessionInfos>({
        playerName: "",
        amount: 0,
        playerId: undefined,
        isValid: true
    });
    const [betValue, setBetValue] = useState(0);
    const [multiply, setMultiply] = useState(0);
    //const [valueWin, setValueWin] = useState(0);
    const [progress, setProgress] = useState(50);
    const [amountReceived, setAmountReceived] = useState(0);
    const [progressBet, setProgressBet] = useState(0);
    const [editing, setEditing] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [casinoAccess, setCasinoAccess] = useState(false);
    let audioElement: HTMLAudioElement | null = null;

    const percentages = [
        {result: 0},
        {result: 25},
        {result: 50},
        {result: 75},
        {result: 100},
    ]

    useEffect(() => {
        FindCasinoAccess()
    }, []);

    useEffect(() => {
        casinoAccess && FindSessionInfos();
    }, [casinoAccess]);

    useEffect(() => {
        setMultiply(95 / progress);
        //setValueWin(betValue * multiply);
    }, [progress]);

    async function FindCasinoAccess() {
        setCasinoAccess(await GetCasinoAccess(casino!, "Dice Percentage", session!));
    }

    async function FindSessionInfos() {
        setSessionInfos(await GetSessionInfos(session));
    }

    const handleBetValue = (event: any) => {
        const {value} = event.target;
        setBetValue(value);
    };

    const handleLimitValue = (event: any) => {
        const {value} = event.target;
        setProgress(value >= 100 ? 100 : value <= 0 ? 0 : value);
    };

    async function sendBetBet() {
        var bet: IDiceBet = {
            amount: sessionInfos.amount,
            amountBet: betValue,
            limit: progress,
            playerId: sessionInfos.playerId!,
            sessionId: session,
            ln: i18n.language
        };
        const r: IDiceResult = await Bet(bet);
        setProgressBet(progress);
        setSessionInfos({...sessionInfos, "isValid": r.isValid});
        if (r.isBet) {
            setLastResult(r.result.toFixed(2));
            lastTwentyResults.push(r.result.toFixed(2));
            setLastTwentyResults([...lastTwentyResults]);
            setSessionInfos({...sessionInfos, "amount": r.amount, "isValid": r.isValid});
            setPrivateChat("");
            setAmountReceived(r.amountReceived);
        } else {
            setPrivateChat(t("betError"));
        }
    }

    const handleMouseDown = () => {
        setEditing(true);
    };

    const handleMouseUp = () => {
        setEditing(false);
    };

    const handleMouseMove = (e: any) => {
        if (editing) {
            const progressBar = document.getElementById('progress-bar');
            const rect = progressBar!.getBoundingClientRect();
            const width = rect.width;
            const offsetX = e.clientX - rect.left;
            const percentage = parseFloat(Math.min(100, Math.max(0, (offsetX / width) * 100)).toFixed(2));
            setProgress(percentage >= 100 ? 100 : percentage);
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        handleMove(event.touches[0]);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();
        handleMove(event.touches[0]);
    };

    const handleTouchEnd = () => {
        // @ts-ignore
        document.removeEventListener('touchmove', handleTouchMove);
    };

    const handleMove = (event: React.Touch) => {
        const progressBar = document.getElementById('progress-bar');
        // @ts-ignore
        const rect = progressBar.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        // @ts-ignore
        const newWidth = parseFloat(((offsetX / progressBar.offsetWidth) * 100).toFixed(2));
        setProgress(newWidth >= 100 ? 100 : newWidth <= 0 ? 0 : newWidth);
    };

    const togglePlay = () => {
        if (audioElement) {
            if (!isPlaying) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <Container>
            {!casinoAccess || !sessionInfos.isValid ? (
                <Blurn>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <Imagens src={isPlaying ? playMusic : pauseMusic} alt={isPlaying ? 'Play' : 'Pause'}></Imagens>
                    </ButtonMusic>
                    <ErrorMessageData>{t("pleaseLogInAgain")}</ErrorMessageData>
                </Blurn>
            ) : (

                <Blurn>
                    <audio style={{display: "none"}} ref={el => (audioElement = el)} controls>
                        <source src={dice} type="audio/mpeg"/>
                    </audio>
                    <ButtonMusic onClick={togglePlay}>
                        <Imagens src={isPlaying ? playMusic : pauseMusic} alt={isPlaying ? 'Play' : 'Pause'}></Imagens>
                    </ButtonMusic>
                    <GameContainer>
                        <LastResults>
                            {lastTwentyResults.slice(-6).map((chat, index) => (
                                <Mensage key={index}>
                                    <MensageData>{chat}</MensageData>
                                </Mensage>
                            ))}
                        </LastResults>

                        <ProgressBarContainer>
                            <Percentages>
                                {percentages.slice(-6).map((percentage, index) => (
                                    <PercentageDiv key={index}>
                                        <PercentageValue>{percentage.result}</PercentageValue>
                                    </PercentageDiv>
                                ))}
                            </Percentages>
                            <ProgressBarRight
                                id="progress-bar"
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseUp}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                <ProgressBarLeft progress={progress}>
                                    <ProgressBarSelector/>
                                </ProgressBarLeft>
                            </ProgressBarRight>
                        </ProgressBarContainer>
                        <LastResultsContainer style={{
                            background: lastResult != "" ? "#414141" : "",
                        }}>
                            <MensageData>{progressBet == 0 ? t("tryAgain") : parseFloat(lastResult) <= progressBet ? t("win") : t("tryAgain")}</MensageData>
                            <MensageData>{t("currency")}{amountReceived.toFixed(2)}</MensageData>
                        </LastResultsContainer>
                    </GameContainer>
                    <Menu>
                        <Actions>
                            <Infos>
                                <div>
                                    $ {sessionInfos.amount}
                                </div>
                            </Infos>
                            <Separation>
                                {t("betAmount")}
                                <ButtonDice type={"number"} placeholder="R$" value={betValue} onChange={handleBetValue}/>
                            </Separation>
                            <Separation>
                                {t("maximumValue")}
                                <ButtonDice type={"number"} placeholder="%" value={progress} onChange={handleLimitValue}/>
                            </Separation>
                            <Separation>
                                {t("multiplier")}
                                <ButtonContainer>
                                    {multiply.toFixed(2)}
                                </ButtonContainer>
                            </Separation>
                            <Separation>
                                {t("bet")}
                                <ButtonBet onClick={sendBetBet}>
                                    {t("roll")}
                                    <Imagens src={Rolar}/>
                                </ButtonBet>
                            </Separation>
                            {/*<Separation>*/}
                            {/*    {t("award")}*/}
                            {/*    <ButtonContainer>*/}
                            {/*        {valueWin.toFixed(2)}*/}
                            {/*    </ButtonContainer>*/}
                            {/*</Separation>*/}
                        </Actions>
                        <MensageData>{t(privateChat)}</MensageData>
                    </Menu>
                </Blurn>
            )}


        </Container>
    );
};

export default DiceV1Room;
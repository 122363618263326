import styled from 'styled-components'

export const CollapseContainer = styled.div<{ open: boolean }>`
    background: ${(props) => props.theme.COLORS.shadow};
    border-radius: 28px;
    display: flex;
    width: ${(props) => (props.open ? '75rem' : '60rem')};
    align-items: ${(props) => (!props.open ? 'center' : 'flex-start')};
    flex-direction: column;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @media (max-width: 1150px) {
        width: ${(props) => (props.open ? '65rem' : '50rem')};
        padding: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
    }

    @media (max-width: 950px) {
        width: ${(props) => (props.open ? '45rem' : '43rem')};
    }

    @media (max-width: 750px) {
        width: ${(props) => (props.open ? '35rem' : '33rem')};
    }

    @media (max-width: 550px) {
        width: ${(props) => (props.open ? '27rem' : '25rem')};
        padding: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
        justify-content: center;
    }
`

export const ContainerTitle = styled.nav`
  color: ${(props) => props.theme.COLORS.blackBlue};
  font-family: 'Poppins';
  font-style: italic;
  font-size: 24px;
  line-height: 36px;

  cursor: pointer;

  img {
    margin-left: 1rem;
  }

  @media (max-width: 750px) {
    font-size: 1.3rem;
    line-height: 2rem;
  }
`

export const ContainerText = styled.div`
  color: ${(props) => props.theme.COLORS.darkBlue};
  font-family: 'Poppins';
  font-style: italic;
  font-size: 20px;
  line-height: 30px;
  padding-left: 1rem;

  @media (max-width: 750px) {
    font-size: 1rem;
    line-height: 2rem;
    padding-left: 0.5rem;
  }
`

import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;

    img {
        border-radius: 20px;
        box-shadow: 1px 1px 10px 5px DimGray;

        cursor: pointer;
    }

    :first-child {
        width: 78%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    div {
        display: flex;
        justify-content: center;
        width: 70%;
        height: 100%;

        @media (max-width: 750px) {
            width: 78%;
        }
    }
`

import styled from 'styled-components'

export const Container = styled.footer`
    flex-direction: column;
    width: 100%;
    color: white;
    text-align: center;
    padding: 1rem 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
        color: #ffffff;
        font-style: italic;
    }
`
import {SESSION_CONFIG} from '../services/service.config'
import {api} from '../services/api'

export async function GetGameEcommerce(game: string) {
    let msg = ''
    await api
        .get(
            SESSION_CONFIG.getGameEcommerce.path +
            SESSION_CONFIG.getGameEcommerce.endpoint +
            '?game=' +
            game,
        )
        .then((response) => {
            msg = response.data
        })
        .catch((err) => (msg = err.response.data))

    return msg
}

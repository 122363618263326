import {Route, Routes} from 'react-router-dom'
import {DefaultLayout} from '../layouts/DefaultLayout'
import {Home} from '../pages/home/inedx'
import {Games} from '../pages/games'
import {Details} from '../pages/detals'
import {AboutUs} from '../pages/aboutus'

export const LoggedRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<DefaultLayout/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/games" element={<Games/>}/>
                <Route path="/details/:id" element={<Details/>}/>
                <Route path="/powergaming" element={<AboutUs/>}/>
            </Route>
        </Routes>
    )
}
import {
    ButtonContainer,
    Container,
    ContainerDescription,
    ContainerQuestions,
    ConteinerSeparation,
    Description,
    Info,
    Questions,
    Separation,
    Text,
    TitleDescription,
    Tittle,
} from "./styles";
import arrow from "../../assets/catalog/arrow.svg";
import {Link, useParams} from "react-router-dom";
import {Collapse} from "../../components/Collapse";
import {IProductPDP} from "../../types";
import {useEffect, useState} from "react";
import {GetProduct} from "../../hooks/useProducts";
import {GetGameEcommerce} from "../../hooks/useGames";
import {useTranslation} from "react-i18next";


export function Details() {
    const {t} = useTranslation();
    const {id} = useParams()
    const videoUrl = 'https://www.youtube.com/shorts/3wFVYGQM_Mo';

    const [product, setProduct] = useState<IProductPDP>({
        name: '',
        description: '',
        rtd: '',
        gameType: '',
        release: '',
        license: '',
    });

    useEffect(() => {
        FindProduct();
    }, []);

    async function FindProduct() {
        setProduct(await GetProduct(parseInt(id!)));
    }

    async function handleGame() {
        window.location.href = await GetGameEcommerce(product!.name);
    }

    return (
        <Container>
            <Tittle>{product.name}</Tittle>
            <video width="700" height="400" controls>
                <source src={videoUrl} type="video/mp4"/>
            </video>
            <ContainerDescription>
                <Description>
                    <TitleDescription>
                        {t("aboutTheGame")}
                    </TitleDescription>
                    <Text>
                        {t(product.description)}
                    </Text>
                    <Link to="/">
                        <ButtonContainer onClick={handleGame}>
                            {t("clickToPlay")}
                            <img src={arrow} alt={arrow}/>
                        </ButtonContainer>
                    </Link>
                </Description>
                <Info>
                    <TitleDescription>
                        {t("generalInformation")}
                    </TitleDescription>
                    <ConteinerSeparation>
                        <Separation>
                            <TitleDescription>
                                {t("rtd")}
                            </TitleDescription>
                            <Text>
                                {t(product.rtd)}
                            </Text>
                            <TitleDescription>
                                {t("gameType")}
                            </TitleDescription>
                            <Text>
                                {t(product.gameType)}
                            </Text>
                        </Separation>
                        <Separation>
                            <TitleDescription>
                                {t("release")}
                            </TitleDescription>
                            <Text>
                                {t(product.release)}
                            </Text>
                            <TitleDescription>
                                {t("license")}
                            </TitleDescription>
                            <Text>
                                {t(product.license)}
                            </Text>
                        </Separation>
                    </ConteinerSeparation>
                </Info>
            </ContainerDescription>
            <Questions>
                {t("gameCommonQuestions")}
            </Questions>
            <ContainerQuestions>

                <Collapse
                    title={t("gameQuestion1")}
                    description={t("gameAnswer1")}
                />
                <Collapse
                    title={t("gameQuestion2")}
                    description={t("gameAnswer2")}
                />
                <Collapse
                    title={t("gameQuestion3")}
                    description={t("gameAnswer3")}
                />
                <Collapse
                    title={t("gameQuestion4")}
                    description={t("gameAnswer4")}
                />
            </ContainerQuestions>

        </Container>
    );
}
import {Collapse} from '../Collapse'

import {HelpContainer, Questions} from './styles'
import {useTranslation} from "react-i18next";

export function Help() {
    const { t } = useTranslation();

    return (
        <>
            <HelpContainer>
                <Questions>
                    <Collapse
                        title={t("helpQuestion1")}
                        description={t("helpAnswer1")}
                    />
                    <Collapse
                        title={t("helpQuestion2")}
                        description={t("helpAnswer2")}
                    />
                    <Collapse
                        title={t("helpQuestion3")}
                        description={t("helpAnswer3")}
                    />
                    <Collapse
                        title={t("helpQuestion4")}
                        description={t("helpAnswer4")}
                    />
                </Questions>
            </HelpContainer>
        </>
    )
}
import arrow from "../../assets/catalog/arrow.svg";
import double from '../../assets/games/logo/double.png'
import piratehunt from '../../assets/games/logo/piratehunt.png'
import blackjack1 from '../../assets/games/logo/blackjack1.png'
import twentyone from '../../assets/games/logo/21.png'
import slotMachineZoo from '../../assets/games/logo/zoo.png'
import slotMachine from '../../assets/games/logo/777.png'
import dice from '../../assets/games/logo/dice.png'
import trade from '../../assets/games/logo/trade.png'
import colorRoulette from '../../assets/games/logo/colorRoulette.png'
import alternative from '../../assets/games/logo/alternative.png'
import miningRoulette from '../../assets/games/logo/miningRoulette.png'


import {
    Container,
    Tittle,
    ContainerButton,
    Description,
    ContainerTitle,
    ContainerText,
    ContainerDetails,
    ContainerImg, ContainerDetailsMobile,
} from "./styles";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {IProductPLP} from "../../types";
import {GetAllProducts} from "../../hooks/useProducts";
import {useTranslation} from "react-i18next";


export function Games() {
    const {t} = useTranslation();
    const [products, setProducts] = useState<IProductPLP[]>([]);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        FindProducts();
        checkMobile()
        window.addEventListener('resize', checkMobile)
        return () => {
            window.removeEventListener('resize', checkMobile)
        }
    }, []);

    async function FindProducts() {
        setProducts(await GetAllProducts());
    }

    const checkMobile = () => {
        const isMobileSize = window.innerWidth <= 850
        setIsMobile(isMobileSize)
    }


    return (
        <Container>
            <Tittle>{t("discoveryOurGames")}</Tittle>
            {products!.map((product, index) => (
                isMobile ? (
                    <ContainerDetailsMobile>
                        <ContainerTitle> {product.name}</ContainerTitle>
                        <ContainerImg
                            src={
                                product.name == "Double Joker" ? double :
                                    product.name == "Pirate Hunt" ? piratehunt :
                                        product.name == "Blackjack Vegas" ? blackjack1 :
                                            product.name == "Slot Machine 777" ? slotMachine :
                                                product.name == "Slot Machine Zoo" ? slotMachineZoo :
                                                    product.name == "Twenty One" ? twentyone :
                                                        product.name == "Crash Trade" ? trade :
                                                            product.name == "Dice Percentage" ? dice :
                                                                product.name == "Mining Roulette" ? miningRoulette :
                                                                    product.name == "Color Roulette" ? colorRoulette : alternative


                            } alt={product.name}/>
                            <ContainerText>
                                {t(product.description)}
                            </ContainerText>
                            <Link to={"/details/" + product.id}>
                                <ContainerButton>
                                    {t("findOutMoreDetails")}
                                    <img src={arrow} alt={arrow}/>
                                </ContainerButton>
                            </Link>
                    </ContainerDetailsMobile>
                ) : (
                    <ContainerDetails
                        style={{flexDirection: (index / 2).toString().indexOf(".") == -1 ? "row" : "row-reverse"}}>
                        <ContainerImg
                            src={
                                product.name == "Double Joker" ? double :
                                    product.name == "Pirate Hunt" ? piratehunt :
                                        product.name == "Blackjack Vegas" ? blackjack1 :
                                            product.name == "Slot Machine 777" ? slotMachine :
                                                product.name == "Slot Machine Zoo" ? slotMachineZoo :
                                                    product.name == "Twenty One" ? twentyone :
                                                        product.name == "Crash Trade" ? trade :
                                                            product.name == "Dice Percentage" ? dice :
                                                                product.name == "Mining Roulette" ? miningRoulette :
                                                                    product.name == "Color Roulette" ? colorRoulette : alternative


                            } alt={product.name}/>
                        <Description>
                            <ContainerTitle> {product.name}</ContainerTitle>
                            <ContainerText>
                                {t(product.description)}
                            </ContainerText>
                            <Link to={"/details/" + product.id}>
                                <ContainerButton>
                                    {t("findOutMoreDetails")}
                                    <img src={arrow} alt={arrow}/>
                                </ContainerButton>
                            </Link>
                        </Description>
                    </ContainerDetails>
                )
            ))}
        </Container>
    );
}

import {Outlet} from 'react-router-dom'
import {Header} from '../../components/Header'
import {ContentContainer, LayoutContainer} from './styles'
import {MenuMobile} from '../../components/MenuMobile'
import {useState, useEffect} from 'react'
import {Footer} from '../../components/Footer'

export function DefaultLayout() {
    const [menuIsVisible, setMenuIsVisible] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        checkMobile()
        window.addEventListener('resize', checkMobile)
        return () => {
            window.removeEventListener('resize', checkMobile)
        }
    }, [])

    const checkMobile = () => {
        const isMobileSize = window.innerWidth <= 768
        setIsMobile(isMobileSize)
    }

    return (
        <LayoutContainer>
            {isMobile && (
                <MenuMobile
                    menuIsVisible={menuIsVisible}
                    setMenuIsVisible={setMenuIsVisible}
                />
            )}
            <Header setMenuIsVisible={setMenuIsVisible}/>
            <ContentContainer>
                <Outlet/>
            </ContentContainer>
            <Footer/>
        </LayoutContainer>
    )
}
import styled from 'styled-components'

export const HelpContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 4rem;
    align-items: center;

    @media (max-width: 750px) {
        gap: 3rem;
    }

    @media (max-width: 550px) {
        gap: 2rem;
    }
`

export const Questions = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;


    @media (max-width: 550px) {
        padding-top: 6rem;
        gap: 1rem;
    }
`
import {Button, Container} from './styles'
import {useTranslation} from "react-i18next";
import br from "../../assets/br.svg"
import en from "../../assets/en.svg"
import es from "../../assets/es.svg"
import fr from "../../assets/fr.svg"
import i18n from "i18next";

const languageOptions = [
    {
        name: "Português",
        value: "br",
        flag: br
    },
    {
        name: "English",
        value: "en",
        flag: en
    },
    {
        name: "Español",
        value: "es",
        flag: es
    },
    {
        name: "Français",
        value: "fr",
        flag: fr
    }
]

export function LanguageSwitcher() {
    const {t} = useTranslation();

    return (
        <Container>
            {languageOptions.map(languageOption => (
                <Button key={languageOption.value} onClick={() => i18n.changeLanguage(languageOption.value)}>
                    <img src={languageOption.flag} alt={languageOption.name}/>
                </Button>
            ))}
        </Container>
    )
}
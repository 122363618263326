import styled from 'styled-components'

export const ContainerHome = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;

    @media (max-width: 750px) {
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
`
export const Tittle = styled.div`
    color: ${(props) => props.theme.COLORS.darkBlue};
    text-align: center;
    font-family: Roboto Mono;
    font-size: 34px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin-top: 1rem;

    @media (max-width: 750px) {
        margin-top: 10rem;
        font-size: 28px;
    }

    @media (max-width: 600px) {
        font-size: 25px;
    }
`


export const StartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CarroselContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    height: 35rem;
    flex-direction: column;

    @media (max-width: 750px) {
        height: 15rem;
    }
`

export const ButtonContainer = styled.button`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.COLORS.white};
    border-radius: 30px;
    color: ${(props) => props.theme.COLORS.white};
    background: ${(props) => props.theme.COLORS.darkBlue};
    width: 22rem;
    height: 69px;
    font-family: Poppins;
    font-size: 20px;
    margin: 3rem;

    @media (max-width: 750px) {
        width: 18rem;
        height: 3rem;
        border-radius: 20px;
        font-size: 18px;
    }

    &:hover {
        filter: brightness(0.7);
    }

    &.active {
        text-decoration: underline;
    }
`

export const Questions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.COLORS.darkBlue};

    @media (max-width: 750px) {
        font-size: 24px;
    }

`

export const TittleTwo = styled.div`
    color: ${(props) => props.theme.COLORS.darkBlue};
    text-align: center;
    font-family: Roboto Mono;
    font-size: 34px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin-top: 1rem;

    @media (max-width: 750px) {
        margin-top: 5rem;
        font-size: 28px;
    }

    @media (max-width: 600px) {
        font-size: 25px;
    }
`